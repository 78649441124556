import { Context } from '../context/context';
import { _debug, CONST_AUTO_PORT } from '../utils/utils';
import { DeviceBase } from './devicebase';

export class DeviceOnPort extends DeviceBase {
    port: string | undefined;
    devicePyClass: string | undefined;

    constructor(context: Context, port: string, deviceClass?: string) {
        super(context);
        this.port = port;
        this.devicePyClass = deviceClass;
    }

    get portString(): string {
        return DeviceOnPort.portToString(this.port);
    }

    override get devicename(): string {
        return DeviceOnPort.devicenameTemplate(this.port, this.devicePyClass);
    }

    static portToString(port?: string): string {
        return `Port.${port}`;
    }

    override setupCode(): string[] {
        return this._setupCode_internal([]);
    }

    protected _setupCode_internal(args: string[] = []): string[] {
        const setup_code = super.setupCode();

        this.context.imports.use('pybricks.parameters', 'Port');
        this.context.imports.use(
            this.devicePyClass === 'PUPDevice'
                ? 'pybricks.iodevices'
                : 'pybricks.pupdevices',
            this.devicePyClass,
        );

        const deviceInitialization =
            this.port !== CONST_AUTO_PORT
                ? `${this.devicePyClass}(${[this.portString].concat(args).join(', ')})`
                : this.context.helpers
                      .use('get_pupdevices')
                      .call([this.devicePyClass].concat(args).join(', ')).raw;

        setup_code.push(`${this.devicename} = ${deviceInitialization}`);
        return setup_code;
    }

    getDeviceClass(): string {
        return this.devicePyClass ?? '';
    }

    static devicenameTemplate(port?: string, deviceClass?: string): string {
        return `${deviceClass?.toLowerCase() ?? 'device'}_${port?.toLowerCase()}`;
    }

    static devicenameFromPort(
        context: Context,
        port?: string,
        deviceClass?: string,
    ): string {
        if (!port) {
            return '';
        }
        const devicename = [...context.devicesRegistry.entries()].find(
            (item) => (item[1] as DeviceOnPort).port === port,
        )?.[0];
        return devicename ?? this.devicenameTemplate(port, deviceClass);
    }

    static instance(
        context: Context,
        port: string,
        deviceClass?: string,
    ): DeviceOnPort {
        const devname = this.devicenameFromPort(context, port, deviceClass);
        if (!devname) throw new Error(`Device on port ${port} not found`);

        let elem = context.devicesRegistry.get(devname) as DeviceOnPort | undefined;
        if (!elem) {
            elem = this.factory(context, port, deviceClass);
            context.devicesRegistry.set(devname, elem);
        } else {
            if (elem.devicePyClass !== deviceClass) {
                _debug(
                    `Device on port ${port} is already assigned (${devname}) and new deviceClass "${deviceClass}" is not matching "${elem.devicePyClass}"`,
                );
            }
        }
        return elem;
    }

    static factory(context: Context, port: string, deviceClass?: string): DeviceOnPort {
        return new DeviceOnPort(context, port, deviceClass);
    }
}
