import { SB3Block } from '../project.sb3/block';
import { BlockValue } from '../utils/blockvalue';
import {
    calc_comparator,
    EV3BUTTONEVENT_MAP,
    EV3BUTTON_MAP,
    calc_ev3comparator,
    EV3SENSORCOLOR_MAP,
    EV3BUTTON,
} from '../utils/converters';
import { DeviceOnPort } from '../device/deviceportbase';
import { DeviceType, OperatorPrecedence, ValueType } from '../utils/enums';
import { CodeBlock, CodeConverter } from '../utils/types';
import { _debug, CONST_AUTO_PORT } from '../utils/utils';
import { OperatorHandler } from './handlers';

function ev3sbTranslateSensorPort(port: string) {
    // 1 => A, 4 => D
    const parsedPort = parseInt(port) - 1;
    return ['A', 'B', 'C', 'D', 'E', 'F'][parsedPort];
}
function ev3gTranslateSensorPort(port: string) {
    // 1.1 => A, 1.4 => D
    const parsedPort = parseInt(port.split('.')[1]);
    return ['A', 'B', 'C', 'D', 'E', 'F'][parsedPort - 1];
}

export function convertSensorPort(this: CodeConverter, portName: string) {
    switch (this.context.deviceType) {
        case DeviceType.EV3CLASSROOM:
            return ev3sbTranslateSensorPort(portName);
        case DeviceType.SPIKE:
        case DeviceType.ROBOTINVENTOR:
            return portName ?? CONST_AUTO_PORT;
        case DeviceType.EV3G:
            return ev3gTranslateSensorPort(portName);
        default:
            return '';
    }
}

function getSensorPort(this: CodeConverter, block: CodeBlock): string {
    let portName = BlockValue.toString(block.get('PORT'));
    return convertSensorPort.call(this, portName);
}

function flippermoresensors_deviceType(this: CodeConverter, block: CodeBlock) {
    const port = getSensorPort.call(this, block);
    const portEnum = DeviceOnPort.portToString(port);
    return this.context.helpers.use('pupdevice_type').call(portEnum);
}

function flippersensors_orientation(this: CodeConverter, _block: CodeBlock) {
    return this.context.helpers.use('convert_hub_orientation_back')?.call(
        new BlockValue('hub.imu.up()', {
            is_dynamic: true,
            type: ValueType.NUMBER,
        }),
    );
}

function flippersensors_isorientation(this: CodeConverter, block: CodeBlock) {
    const orientation = block.get(['ORIENTATION', 'VALUE'])?.value;
    this.context.imports.use('pybricks.parameters', 'Side');
    return new BlockValue(
        `hub.imu.up() == ${
            this.context.helpers.use('convert_hub_orientation')?.call(orientation).value
        }`,
        {
            is_dynamic: true,
            type: ValueType.BOOLEAN,
            precedence: OperatorPrecedence.BINARY_COMPARISON,
        },
    );
}
function flippersensors_orientationAxis(this: CodeConverter, block: CodeBlock) {
    const orientation = block.get('AXIS')?.toString();

    // TODO: get back to -180 - 180 tange
    if (orientation === 'yaw') {
        return new BlockValue('hub.imu.heading()', {
            is_dynamic: true,
            type: ValueType.NUMBER,
        });
    } else if (orientation === 'pitch') {
        return new BlockValue('hub.imu.tilt()[0]', {
            is_dynamic: true,
            type: ValueType.NUMBER,
        });
    } else if (orientation === 'roll') {
        return new BlockValue('hub.imu.tilt()[1]', {
            is_dynamic: true,
            type: ValueType.NUMBER,
        });
    }
}

function ev3sensors_getEV3GyroSensorAngle(this: CodeConverter, block: CodeBlock) {
    // emulate through hub // const port = getSensorPort.call(this, block);
    return new BlockValue('hub.imu.heading()', {
        is_dynamic: true,
        type: ValueType.NUMBER,
    });
}

function flippersensors_resetYaw(this: CodeConverter, _block: CodeBlock) {
    return ['hub.imu.reset_heading(0)'];
}

function ev3sensors_isEV3GyroAngle(this: CodeConverter, block: CodeBlock) {
    const measurement = ev3sensors_getEV3GyroSensorAngle.call(this, block);
    const ev3comparator = calc_ev3comparator(block.get(['COMPARATOR', 'EVENT']));
    const value = block.get('VALUE');
    return new BlockValue(
        `${measurement.raw} ${ev3comparator} ${value.ensureNumber(this.context).raw}`,
        {
            is_dynamic: true,
            type: ValueType.BOOLEAN,
            precedence: OperatorPrecedence.BINARY_COMPARISON,
        },
    );
}

function ev3g_GyroAngleCompare(this: CodeConverter, block: CodeBlock) {
    const measurement = ev3sensors_getEV3GyroSensorAngle.call(this, block);
    const ev3comparator = calc_ev3comparator(block.get('COMPARISON')); //!! // CHECK
    const value = block.get('THRESHOLD');
    return new BlockValue(
        `${measurement.raw} ${ev3comparator} ${value.ensureNumber(this.context).raw}`,
        {
            is_dynamic: true,
            type: ValueType.BOOLEAN,
            precedence: OperatorPrecedence.BINARY_COMPARISON,
        },
    );
}

function flippersensors_color(this: CodeConverter, block: CodeBlock) {
    const port = getSensorPort.call(this, block);
    const device = DeviceOnPort.instance(this.context, port, 'ColorSensor');
    const d = device?.devicename;

    // convert_color_back is different for spike and ev3g color Ids
    return this.context.helpers
        .use(
            this.context.deviceTypeIsSB3
                ? 'convert_color_back'
                : 'convert_ev3gcolor_back',
        )
        ?.call(
            new BlockValue(`${this.context.awaitPrefix}${d}.color()`, {
                is_dynamic: true,
                type: ValueType.ENUM,
            }),
        );
}

function flippersensors_isColor(this: CodeConverter, block: CodeBlock) {
    const port = getSensorPort.call(this, block);
    let color = {};
    if (this.context.deviceTypeIsSPIKERI) {
        color = { numeric: block.get(['VALUE', 'OPTION']) };
    } else if (this.context.deviceType === DeviceType.EV3CLASSROOM) {
        const value = block.get(['VALUE', 'OPTION', 'EVENT']);
        color = { enum: EV3SENSORCOLOR_MAP.get(value?.toInt()) };
    }

    return _isColor.call(this, block, port, color);
}

function ev3g_ColorCompare(this: CodeConverter, block: CodeBlock) {
    const port = getSensorPort.call(this, block);
    const set_of_colors = block.get('SET_OF_COLORS');
    if (set_of_colors.options?.is_dynamic) {
        throw new Error(
            'ColorCompare - set_of_colors dynamic handling not implemented',
        );
    }
    const regex = /\[(\d+(?:,\d+)*)\]/;
    const values = regex
        .exec(set_of_colors?.toString() || '')?.[1]
        .split(',')
        .map(Number);
    let color = {
        enums: values
            ?.map((value) => EV3SENSORCOLOR_MAP.get(value))
            .filter((item) => !!item) as string[],
    };

    return _isColor.call(this, block, port, color);
}

function horizontalevents_whenColor(this: CodeConverter, block: CodeBlock) {
    const port = CONST_AUTO_PORT;
    const color = block.get('COLOR');

    return _isColor.call(this, block, port, { numeric: color });
}

function _isColor(
    this: CodeConverter,
    _: CodeBlock,
    port: string,
    color1: { numeric?: BlockValue; enum?: string; enums?: string[] },
) {
    const hasMultiple = !color1.numeric && !color1.enum;
    let colorStr;
    if (!hasMultiple) {
        if (color1.numeric)
            colorStr = this.context.helpers
                .use('convert_color')
                ?.call(color1.numeric).value;
        else if (color1.enum) colorStr = color1.enum;
    } else {
        if (color1.enums) colorStr = `[${color1.enums.join(', ')}]`;
    }

    const device = DeviceOnPort.instance(this.context, port, 'ColorSensor');
    const d = device?.devicename;
    return new BlockValue(
        !hasMultiple
            ? `${this.context.awaitPrefix}${d}.color() == ${colorStr}`
            : `${this.context.awaitPrefix}${d}.color() in ${colorStr}`,
        {
            is_dynamic: true,
            type: ValueType.BOOLEAN,
            precedence: OperatorPrecedence.BINARY_COMPARISON,
        },
    );
}

function flippersensors_reflectivity(this: CodeConverter, block: CodeBlock) {
    const port = getSensorPort.call(this, block);

    const device = DeviceOnPort.instance(this.context, port, 'ColorSensor');
    const d = device?.devicename;
    return new BlockValue(`${this.context.awaitPrefix}${d}.reflection()`, {
        is_dynamic: true,
        type: ValueType.NUMBER,
    });
}

function flippersensors_isReflectivity(this: CodeConverter, block: CodeBlock) {
    const port = getSensorPort.call(this, block);
    const value = block.get('VALUE');
    const comparator = (() => {
        if (this.context.deviceTypeIsSPIKERI) {
            // comparator works OK, as the range is 0-100
            return calc_comparator(block.get('COMPARATOR'));
        } else if (this.context.deviceType === DeviceType.EV3CLASSROOM) {
            return calc_ev3comparator(block.get('COMPARATOR'));
        } else {
            return undefined;
        }
    })();

    const device = DeviceOnPort.instance(this.context, port, 'ColorSensor');
    const d = device?.devicename;
    return new BlockValue(
        `${this.context.awaitPrefix}${d}.reflection() ${comparator} ${
            value.ensureNumber(this.context).raw
        }`,
        {
            is_dynamic: true,
            type: ValueType.BOOLEAN,
            precedence: OperatorPrecedence.BINARY_COMPARISON,
        },
    );
}

function sensors_isPressed(this: CodeConverter, block: CodeBlock, isFullMode: boolean) {
    const port = isFullMode ? getSensorPort.call(this, block) : CONST_AUTO_PORT;
    const option = block.get('OPTION'); // pressed, hardpressed, released, pressurechanged

    const device = DeviceOnPort.instance(this.context, port, 'ForceSensor');
    const d = device?.devicename;
    switch (option?.value) {
        case 'pressed':
            return new BlockValue(`${this.context.awaitPrefix}${d}.pressed()`, {
                is_dynamic: true,
                type: ValueType.BOOLEAN,
            });
        case 'released':
            return new BlockValue(`not ${this.context.awaitPrefix}${d}.pressed()`, {
                is_dynamic: true,
                type: ValueType.BOOLEAN,
            });
        case 'hardpressed':
            return new BlockValue(`${this.context.awaitPrefix}${d}.pressed(10)`, {
                is_dynamic: true,
                type: ValueType.BOOLEAN,
            });
        case 'pressurechanged':
            throw new Error('pressurechanged - Not implemented yet');
    }
}

function ev3g_TouchCompare(this: CodeConverter, block: CodeBlock, isFullMode: boolean) {
    const port = getSensorPort.call(this, block);
    const option = block.get('PRESSED_RELEASED_OR_BUMPED'); // pressed, released, bumped

    const device = DeviceOnPort.instance(this.context, port, 'ForceSensor');
    const d = device?.devicename;
    switch (option?.value) {
        case 1:
            return new BlockValue(`${this.context.awaitPrefix}${d}.pressed()`, {
                is_dynamic: true,
                type: ValueType.BOOLEAN,
            });
        case 2:
            return new BlockValue(`not ${this.context.awaitPrefix}${d}.pressed()`, {
                is_dynamic: true,
                type: ValueType.BOOLEAN,
            });
        // case 3:
        default:
            throw new Error('pressurechanged - Not implemented yet');
    }
}

function flippersensors_distance(this: CodeConverter, block: CodeBlock) {
    const port = getSensorPort.call(this, block);
    const unit = block.get('UNIT');

    const device = DeviceOnPort.instance(this.context, port, 'UltrasonicSensor');
    const d = device?.devicename;

    return this.context.helpers.use('convert_ussensor_distance_back')?.call(
        new BlockValue(`${this.context.awaitPrefix}${d}.distance()`, {
            is_dynamic: true,
            type: ValueType.NUMBER,
        }),
        unit,
    );
}

function flippersensors_isDistance(this: CodeConverter, block: CodeBlock) {
    const port = getSensorPort.call(this, block);
    const unit = block.get('UNIT');
    const value = block.get('VALUE');

    return _isDinstance.call(this, block, port, value, unit);
}

function horizontalevents_whenCloserThan(this: CodeConverter, block: CodeBlock) {
    const port = CONST_AUTO_PORT;
    const unit = new BlockValue('%', { type: ValueType.STRING });
    const value = block.get('DISTANCE');
    const comparator = new BlockValue('<');

    return _isDinstance.call(this, block, port, value, unit, comparator);
}

function ev3g_DistanceCMCompare(this: CodeConverter, block: CodeBlock) {
    const port = getSensorPort.call(this, block);
    const unit = new BlockValue('cm', { type: ValueType.STRING });
    const threshold = block.get('THRESHOLD');

    return _isDinstance.call(this, block, port, threshold, unit);
}

function _isDinstance(
    this: CodeConverter,
    block: CodeBlock,
    port: string,
    value: BlockValue,
    unit: BlockValue,
    comparatorOverride?: BlockValue,
) {
    // const comparator1 = calc_comparator(comparator) ?? '';
    const comparator1 = (() => {
        if (this.context.deviceTypeIsSPIKERI) {
            // comparator works OK, as the range is 0-100
            return calc_comparator(comparatorOverride ?? block.get('COMPARATOR'));
        } else if (this.context.deviceType === DeviceType.EV3CLASSROOM) {
            return calc_ev3comparator(block.get(['EVENT', 'COMPARATOR']));
        } else if (this.context.deviceType === DeviceType.EV3G) {
            return calc_ev3comparator(block.get('COMPARISON'));
        }
    })();

    const device = DeviceOnPort.instance(this.context, port, 'UltrasonicSensor');
    const d = device?.devicename;

    // NOTE: if comparator is ==, we should use range, instead of simple comparison (e.g. 1% means x>0% or y<2%)
    const distance_adjusted = this.context.helpers
        .use('convert_ussensor_distance_back')
        .call(
            new BlockValue(`${this.context.awaitPrefix}${d}.distance()`, {
                is_dynamic: true,
                type: ValueType.NUMBER,
            }),
            unit,
        );

    return new BlockValue(
        `${distance_adjusted} ${comparator1} ${value.ensureNumber(this.context).raw}`,
        {
            is_dynamic: true,
            type: ValueType.BOOLEAN,
            precedence: OperatorPrecedence.BINARY_COMPARISON,
        },
    );
}

function ev3sensors_getEV3BrickButton(this: CodeConverter, block: CodeBlock) {
    return this.context.helpers.use('convert_ev3button_back')?.call(
        new BlockValue('hub.buttons.pressed()', {
            is_dynamic: true,
        }),
    );
}

function sensors_buttonIsPressed(this: CodeConverter, block: CodeBlock) {
    let button_enum: string | undefined;
    let event: string | undefined;

    if (this.context.deviceTypeIsSPIKERI) {
        // Flipper Brick
        button_enum = 'Button.' + block.get('BUTTON')?.value?.toString().toUpperCase();
        event = block.get('EVENT')?.value?.toString().toLowerCase();
    } else if (this.context.deviceType === DeviceType.EV3CLASSROOM) {
        // EV3 Brick
        button_enum = EV3BUTTON_MAP.get(block.get('BUTTON')?.toInt());
        event = EV3BUTTONEVENT_MAP.get(block.get('EVENT')?.toInt())?.toLowerCase();
    }

    const value = `${button_enum} in hub.buttons.pressed()`;
    return new BlockValue(event === 'released' ? `not ${value}` : value, {
        is_dynamic: true,
        type: ValueType.BOOLEAN,
        precedence: OperatorPrecedence.BINARY_COMPARISON,
    });
}

function ev3g_ButtonCompare(this: CodeConverter, block: CodeBlock) {
    const buttons = JSON.parse(block.get('BUTTONS').toString()) as Number[];
    const buttons_enum = buttons.map((item) => EV3BUTTON_MAP.get(item as EV3BUTTON));
    const triggerAtPressed = block.get('ACTION')?.toInt() === 1; // pressed=1, released=0

    //TODO: check
    const value = `bool(set([${buttons_enum.join(',')}]) & set(hub.buttons.pressed()))`;
    return new BlockValue((triggerAtPressed ? '' : 'not ') + value, {
        is_dynamic: true,
        type: ValueType.BOOLEAN,
        precedence: OperatorPrecedence.BINARY_COMPARISON,
    });
}

function ev3sensors_waitFunction(
    this: CodeConverter,
    block: CodeBlock,
    handler: OperatorHandler,
) {
    const condition_value = handler.call(this, block);
    const sub_code = this.process_stack(null, true); // empty substack -> results in pass

    return [`while not (${condition_value?.raw}):`, ...sub_code];
}

function ev3sensors_isEV3TouchSensorPressed(this: CodeConverter, block: CodeBlock) {
    const port = getSensorPort.call(this, block);
    const pressed = block.get('EVENT')?.toInt() === 1; // pressed=1, released=0

    const device = DeviceOnPort.instance(this.context, port, 'ForceSensor');
    const d = device?.devicename;

    return new BlockValue(
        `${!pressed ? 'not ' : ''}${this.context.awaitPrefix}${d}.pressed()`,
        {
            is_dynamic: true,
            type: ValueType.BOOLEAN,
        },
    );
}

function handleBlock(
    this: CodeConverter,
    block: CodeBlock,
    opcodeOverride?: string,
): string[] | undefined {
    switch (opcodeOverride ?? block.opcode) {
        case 'ev3sensors_waitEV3BrickButton':
            return ev3sensors_waitFunction.call(this, block, sensors_buttonIsPressed);
        case 'ev3sensors_waitEV3ColorSensorColor':
            return ev3sensors_waitFunction.call(this, block, flippersensors_isColor);
        case 'ev3sensors_waitEV3TouchSensorTouch':
            return ev3sensors_waitFunction.call(
                this,
                block,
                ev3sensors_isEV3TouchSensorPressed,
            );
        case 'ev3sensors_waitEV3UltrasonicSensorDistance':
            return ev3sensors_waitFunction.call(this, block, flippersensors_isDistance);
        case 'ev3sensors_waitEV3GyroSensorAngle':
            return ev3sensors_waitFunction.call(this, block, ev3sensors_isEV3GyroAngle);
        case 'ev3sensors_resetEV3GyroSensorAngle':
        case 'flippersensors_resetYaw':
        case 'GyroReset':
            return flippersensors_resetYaw.call(this, block);
    }
}

function handleOperator(
    this: CodeConverter,
    block: CodeBlock,
    opcodeOverride?: string,
): BlockValue | undefined {
    switch (opcodeOverride ?? block.opcode) {
        case 'flippermoresensors_deviceType':
            return flippermoresensors_deviceType.call(this, block);
        case 'flippersensors_orientationAxis':
            return flippersensors_orientationAxis.call(this, block);
        case 'flippersensors_orientation':
            return flippersensors_orientation.call(this, block);
        case 'flippersensors_isorientation':
        case 'flipperevents_whenOrientation':
            return flippersensors_isorientation.call(this, block);
        case 'flippersensors_color':
        case 'ev3sensors_getEV3ColorSensorColor':
        case 'ColorValue':
            return flippersensors_color.call(this, block);
        case 'flippersensors_isColor':
        case 'flipperevents_whenColor':
        case 'ev3sensors_isEV3ColorSensorColor':
        case 'ev3events_whenEV3ColorSensorColor':
            return flippersensors_isColor.call(this, block);
        case 'ColorCompare':
            return ev3g_ColorCompare.call(this, block);
        case 'horizontalevents_whenColor':
            return horizontalevents_whenColor.call(this, block);
        case 'flippersensors_reflectivity':
        case 'ev3sensors_getEV3ColorSensorReflected':
        case 'ColorReflectedIntensity':
            return flippersensors_reflectivity.call(this, block);
        case 'flippersensors_isReflectivity':
        case 'flippersensors_whenReflectivity':
        case 'ev3sensors_isEV3ColorSensorReflected':
            // no ev3 reflectivity hat
            return flippersensors_isReflectivity.call(this, block);
        case 'flippersensors_isPressed':
        case 'flipperevents_whenPressed':
            return sensors_isPressed.call(this, block, true);
        case 'horizontalevents_whenPressed':
            return sensors_isPressed.call(this, block, false);
        case 'TouchCompare':
            return ev3g_TouchCompare.call(this, block, false);
        case 'flippersensors_distance':
        case 'ev3sensors_getEV3UltrasonicSensorDistance':
            return flippersensors_distance.call(this, block);
        case 'flippersensors_isDistance':
        case 'flipperevents_whenDistance':
        case 'ev3sensors_isEV3UltrasonicSensorDistance':
        case 'ev3events_whenEV3UltrasonicSensorDistance':
            return flippersensors_isDistance.call(this, block);
        case 'DistanceCMCompare':
            return ev3g_DistanceCMCompare.call(this, block);
        case 'horizontalevents_whenCloserThan':
            return horizontalevents_whenCloserThan.call(this, block);
        case 'ev3sensors_getEV3BrickButton':
        case 'ButtonValue':
            return ev3sensors_getEV3BrickButton.call(this, block);
        case 'flippersensors_buttonIsPressed':
        case 'flipperevents_whenButton':
        case 'ev3sensors_isEV3BrickButtonPressed':
        case 'ev3events_whenEV3BrickButtonPressed':
            return sensors_buttonIsPressed.call(this, block);
        case 'ButtonCompare':
            return ev3g_ButtonCompare.call(this, block);
        case 'ev3sensors_isEV3TouchSensorPressed':
        case 'ev3events_whenEV3TouchSensorPressed':
            return ev3sensors_isEV3TouchSensorPressed.call(this, block);
        case 'ev3sensors_getEV3GyroSensorAngle':
        case 'GyroDegrees':
            return ev3sensors_getEV3GyroSensorAngle.call(this, block);
        case 'ev3sensors_isEV3GyroAngle':
        case 'ev3events_whenEV3GyroSensorAngle':
            return ev3sensors_isEV3GyroAngle.call(this, block);
        case 'GyroAngleCompare':
            return ev3g_GyroAngleCompare.call(this, block);
    }
}

const handlers = {
    block: handleBlock,
    operator: handleOperator,
};
export default handlers;
