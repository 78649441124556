import { EV3GRaw } from './xml-types';
import { BlockValue } from '../utils/blockvalue';
import { ValueType } from '../utils/enums';
import { CodeBlock } from '../utils/types';
import {
    getArgValue,
    sanitizeArgName,
    sanitizeCommentValue as sanitizeAttributeValue,
    sanitizeBlockName,
    sanitizeMyBlockArgName,
    sanitizeWire,
} from './utils';
import { _debug } from '../utils/utils';

/// <reference path="ev3g-projectconverter.ts" />
export class EV3GProject {
    description?: string;
    files?: EV3GFile[];
}

export class EV3GFile {
    stack?: EV3GBlock;
    filename?: string;
}

export interface TunnelDefinition {
    in: Map<string, string>;
    out: Map<string, string>;
}

export class WireDefinition {
    constructor(
        public name: string,
        public type: ValueType,
        public tunnels: TunnelDefinition | undefined,
        public alias: string | undefined,
    ) {}
}

export class EV3GBlock extends CodeBlock {
    children: EV3GBlock[] = [];
    commentBoxes: string[] = [];
    condition?: EV3GBlock;
    forkchildren?: EV3GBlock[];
    enforceChildren = false;
    opcode_ = '';
    inputs = new Map<string, BlockValue>();
    outputs = new Map<string, BlockValue>();
    wires = new Map<string, WireDefinition>();
    hasWhileLoop = false;
    hasWaitFor = false;
    hasSwitch = false;
    hasSwitchCase = false;

    generationHelpers = {
        switchConditionVariable: undefined as string | undefined,
        switchCaseCounter: undefined as number | undefined,
        switchPairedParent: undefined as EV3GBlock | undefined,
        switchCaseIsDefault: undefined as boolean | undefined,
        loopIndexWire: undefined as string | undefined,
    };

    constructor(
        public _block: EV3GRaw.Block | undefined,
        public parent: EV3GBlock | undefined,
        public _rootblock: EV3GRaw.Block | undefined,
        public _parentblock: EV3GRaw.Block,
        public level: number,
        wires?: Map<string, WireDefinition>,
    ) {
        super();
        if (wires) this.wires = wires;
        this.preprocessInputs();
    }

    override get id(): string | undefined {
        const id =
            this.hasSwitch && this.generationHelpers.switchPairedParent
                ? this.generationHelpers.switchPairedParent?._block?.Id
                : this._block?.Id ?? '';
        let parentid =
            this.hasSwitchCase && this.generationHelpers.switchPairedParent
                ? this.generationHelpers.switchPairedParent?.id
                : this.parent?.id ?? '';
        if (parentid) parentid += '.';
        return `${parentid}${id}`;
    }

    override get opcode() {
        return this.opcode_;
    }

    override toString() {
        // const wiresStr = [...this.wires.entries()]
        //     .map(
        //         ([key, value]) =>
        //             `${key}` +
        //             (value.tunnels
        //                 ? `->[in]${[...value.tunnels?.in.entries()]
        //                       .map(([key, value]) => `${key}=${value}`)
        //                       .join(',')}`
        //                 : '') +
        //             (value.tunnels
        //                 ? `->[out]${[...value.tunnels?.out.entries()]
        //                       .map(([key, value]) => `${key}=${value}`)
        //                       .join(',')}`
        //                 : ''),
        //     )
        //     .join('|');
        return [
            (this.hasWaitFor ? '[WaitFor]' : '') +
                (this.hasSwitch ? '[Switch]' : '') +
                (this.hasSwitchCase ? '[SwitchCase]' : '') +
                (this.hasWhileLoop ? '[Loop]' : ''),
            this.opcode,
            this._block?.['@Tag'],
            '@',
            this.id,
            `(parent: ${this.parent?.id}/${this.root?.id})`,
        ]
            .join(' ')
            .trim();
    }

    dump() {
        const lines = [this.toString()];
        for (const child of this.children) {
            lines.push(...child.dump().map((line) => ' '.repeat(4) + line));
        }
        return lines;
    }

    /*
        Loop: has only children (no substack here)
        Switch: has "case" children, their children is the substack
        Case: has children to be handled
        WaitFor: has children
    */

    override getSubStack(index: number): CodeBlock[] {
        return this.children;
    }

    protected override _get(
        name: string | string[],
        isPythonMode = true,
        processChildBlock = true,
    ): BlockValue | CodeBlock | undefined {
        const ev3gblock = this as EV3GBlock;
        if (typeof name === 'string') {
            if (processChildBlock) {
                // following the sb3 logic treat all fields in uppercase
                return this._getInput(name.toUpperCase(), isPythonMode);
            } else {
                if (name === 'CONDITION') {
                    /* WaitFor, Switch */
                    if (this.hasWaitFor || this.hasSwitch) {
                        return ev3gblock;
                    } else {
                        /* ConfigurableWhileLoop */
                        return ev3gblock.condition;
                    }
                } else if (name.startsWith('FORKITEM')) {
                    const idx = parseInt(name.replace('FORKITEM', ''));
                    return ev3gblock.forkchildren && ev3gblock.forkchildren.length > idx
                        ? ev3gblock.forkchildren[idx]
                        : undefined;
                }
            }
        } else {
            for (const i of name) {
                /* following the sb3 logic treat all fields in uppercase */
                const value = this._get(i.toUpperCase(), processChildBlock);
                if (value) return value;
            }
        }
    }

    private _getInput(name: string, isPythonMode = true): BlockValue | undefined {
        // TODO: check if subnodes exists...
        return this.inputs.get(name);
    }

    public get root(): EV3GBlock | undefined {
        let retval: EV3GBlock | undefined = this;
        while (retval.parent) {
            retval = retval.parent;
        }
        return retval;
    }

    override getDescription(isPythonMode = true) {
        if (!this._block) return '';

        const argmap = this.getBlockParams(isPythonMode);

        //TODO: implement pseudocode-isPythonMode switch
        let result = `${argmap ? argmap?.join(', ') : ''}`;
        result = `${this.opcode}(${result})`;
        if (this.hasSwitch) result = `Switch(${result})`;
        if (this.hasWaitFor) result = `WaitFor(${result})`;

        if (this.outputs.size > 0) {
            const outmap = [...this.outputs.entries()]
                .map(([key, value]) => `${value}_${key}`)
                .join(', ');
            result = `${outmap} = ${result}`;
        }

        return result;
    }

    private preprocessInputs() {
        const includedAttrs: string[] = [];
        switch (this._block?.['@Tag']) {
            case 'ConfigurableWhileLoop':
                includedAttrs.push('InterruptName');
                break;
            case 'ConfigurableCaseStructure.Case':
            case 'ConfigurableFlatCaseStructure.Case':
                includedAttrs.push('Pattern');
                break;
            case 'ConfigurableMethodCall': {
                const target = sanitizeBlockName(this._block['Target']);
                if (target === 'CommentBlock') {
                    includedAttrs.push('Comment');
                }
                break;
            }
        }

        if (this._rootblock?.ConfigurableMegaAccessor) {
            const megaArgs = this._rootblock?.ConfigurableMegaAccessor;

            // set mega inputs
            megaArgs
                .filter((item) => item.AccessorType === 'Input')
                .forEach((item) => {
                    item.ConfigurableMethodTerminal?.forEach((confmterminal) => {
                        const terminal = confmterminal.Terminal[0];
                        const argname = sanitizeMyBlockArgName(terminal['Id']);
                        const value = sanitizeWire(this, terminal.Wire);
                        const atype = inferValueType(terminal['DataType']);
                        const wireName = sanitizeWire(this, terminal.Wire);
                        const bvalue = new BlockValue(value, {
                            type: atype,
                            is_variable: true,
                            is_dynamic: true,
                        });
                        this.inputs.set(argname, bvalue);

                        if (wireName) {
                            this.wires.set(
                                wireName,
                                new WireDefinition(wireName, atype, undefined, argname),
                            );
                        }
                    });
                });

            // set mega outputs
            megaArgs
                .filter((item) => item.AccessorType === 'Output')
                .forEach((item) => {
                    if (!item.ConfigurableMethodTerminal) return;

                    const cterminal = item.ConfigurableMethodTerminal[0];
                    const terminal = cterminal.Terminal[0];
                    const name = sanitizeMyBlockArgName(terminal['Id']);
                    const atype = inferValueType(terminal['DataType']);
                    const wireName = sanitizeWire(this, terminal.Wire);
                    const bvalue = wireName
                        ? new BlockValue(wireName, {
                              type: atype,
                              is_variable: true,
                              is_dynamic: true,
                          })
                        : new BlockValue(cterminal.ConfiguredValue, {
                              type: atype,
                          });
                    this.outputs.set(name, bvalue);

                    if (wireName) {
                        this.wires.set(
                            wireName,
                            new WireDefinition(wireName, atype, undefined, name),
                        );
                    }
                });
        }

        const argterms = this._block?.ConfigurableMethodTerminal || [];
        argterms.forEach((arg: unknown) => {
            const terminal = (arg as any).Terminal[0];
            const argname = sanitizeArgName(terminal['Id']);
            const SKIP_ATTRIBUTES = [
                'LoopIterationCount',
                'InterruptsToListenFor_16B03592_CD76_4D58_8DC3_E3C3091E327A',
            ].map((s) => s.toUpperCase());
            if (!argname || SKIP_ATTRIBUTES.includes(argname)) return;

            const wire = terminal['Wire'];
            const wireName = sanitizeWire(this.parent, terminal.Wire);
            const isInput = terminal['Direction'] === 'Input';

            if (isInput) {
                let atype: ValueType = inferValueType(terminal['DataType']);

                // Direction="Input"
                if (!wire) {
                    const value = getArgValue(argname, (arg as any)['ConfiguredValue']);
                    this.inputs.set(argname, new BlockValue(value, { type: atype }));
                } else {
                    // get source wire
                    const source_wire = this.root?.wires.get(wireName);
                    //TODO: check if aliased anywhere as output
                    if (source_wire) atype = source_wire.type;

                    // handle myblock parameter names
                    const myblockParam =
                        [...(this.root?.inputs?.entries() ?? [])]
                            .find((item) => item[1].toString() === wireName)?.[0]
                            ?.toString() ?? undefined;
                    const value = myblockParam ?? wireName;

                    // TODO: wire type comes from the originating data_type
                    this.inputs.set(
                        argname,
                        new BlockValue(value, {
                            type: atype,
                            is_variable: true,
                            is_dynamic: true,
                        }),
                    );
                }
            } else {
                // Direction="Output"
                if (terminal) {
                    const hasWire = !!terminal['Wire'];
                    if (!hasWire) return;
                    // TODO: enable all returns - dn2w38, color = [colorsensor_c.color() in [Color.RED], colorsensor_c.color()]
                    const wireName = hasWire
                        ? sanitizeWire(this.parent, terminal.Wire)
                        : terminal.Id.toLowerCase();
                    const atype = inferValueType(terminal['DataType']);
                    this.outputs.set(
                        argname,
                        new BlockValue(wireName, {
                            type: atype,
                            is_variable: hasWire,
                            is_dynamic: true,
                        }),
                    );
                }
            }
        });

        if (includedAttrs.length > 0) {
            includedAttrs.forEach((attr) => {
                const argname = sanitizeArgName(attr);
                const value = sanitizeAttributeValue((this._block as any)[attr]);
                this.inputs.set(
                    argname,
                    new BlockValue(value, { type: ValueType.STRING }),
                );
            });
        }
    }

    private getBlockParams(isPythonMode: boolean) {
        const argmap = [...this.inputs.entries()].map(([key, value]) => {
            return `${key}: ${value.toString()}`;
        });
        if (this.condition) {
            argmap.push(this.condition.getDescription(isPythonMode));
            //!! endless loop ??, but will skip any dependant blocks (like maths)
        }
        return argmap;
    }

    preprocessWires(blocks: EV3GRaw.Block[] | undefined, rawparent: EV3GRaw.Block) {
        const indent = ' '.repeat((this.level - 1) * 4);
        const rawwires = rawparent.Wire;

        /* process all wire connections */
        //   <ConfigurableMethodTerminal>
        //       <Terminal Id="valueOut" Direction="Output" Wire="w7" DataType="Single" Hotspot="0.5 1" Bounds="54 56 30 27" />
        //   </ConfigurableMethodTerminal>
        // const blocks = getBlocks(block);
        blocks?.forEach((block2: EV3GRaw.Block) => {
            block2.ConfigurableMethodTerminal?.forEach((cterminal) => {
                const terminal = cterminal.Terminal?.[0];
                if (!terminal || !terminal.Wire || terminal.Direction === 'Input')
                    return;
                const wirename = sanitizeWire(this, terminal.Wire);
                const datatype = inferValueType(terminal.DataType);
                // console.log('>>!', wirename, block2.Target, start.root?.wires);
                // TODO: we also cover ConfigurableMegaAccessor here...
                if (this.root !== undefined && !this.root.wires.has(wirename)) {
                    this.root.wires.set(
                        wirename,
                        new WireDefinition(wirename, datatype, undefined, undefined),
                    );
                }
            });
        });

        /* add tunnels */
        this._block?.['ConfigurableWhileLoop.ConfigurableLoopTunnel']?.forEach(
            (tunnel: EV3GRaw.ConfigurableWhileLoopConfigurableLoopTunnel) => {
                //Terminals: 'n2=w7, d0=w16';
                this.processTunnel(tunnel, this, rawwires);
            },
        );

        this._block?.['ConfigurableCaseStructure.ConfigurableCaseTunnel']?.forEach(
            (tunnel: EV3GRaw.ConfigurableCaseStructureConfigurableCaseTunnel) => {
                if (!this.parent) return;
                this.processTunnel(tunnel, this, rawwires);
            },
        );
    }

    processTunnel(
        tunnel:
            | EV3GRaw.ConfigurableWhileLoopConfigurableLoopTunnel
            | EV3GRaw.ConfigurableCaseStructureConfigurableCaseTunnel,
        start: EV3GBlock,
        rawwires: EV3GRaw.Wire[] | undefined,
    ) {
        const wiresMatch = tunnel.Terminals.match(/(\w+)=([\w\d]+)/g);
        if (!wiresMatch) return;
        const match0 = wiresMatch[0].split('=');
        const source_tunnel = sanitizeWire(start.parent, match0[1]);
        const aliasName = this.getRootOutputForWire(start, source_tunnel);
        wiresMatch.shift();

        const tunnelmaps = new Map<string, string>(
            wiresMatch.map((wireMatch: string) => {
                const match1 = wireMatch.split('=');
                const destination_block = sanitizeArgName(start.id, false) + match1[0];
                const destination_tunnel =
                    start.hasSwitch || start.hasSwitchCase
                        ? 'd' + destination_block + match1[1] //!! need to add the tunnel id e.g. D10 //!!
                        : sanitizeWire(start, match1[1]);
                return [destination_block, destination_tunnel];
            }),
        );

        let datatype = ValueType.UNKNOWN;
        const parentWire = start.root?.wires.get(source_tunnel);
        let isInbound: boolean;

        const rawwireMatching = rawwires?.find(
            (elem) => sanitizeWire(start.parent, elem.Id) === source_tunnel,
        );
        const joint = rawwireMatching?.Joints;
        const match = joint?.match(/^N\([^():]+\:([^():]+)\)/);
        if (!match || match.length <= 1) {
            throw new Error(`Problem with matching wire joint ${joint}, ${start.id}`);
        }
        isInbound = match[1] !== start._block?.Id;

        if (parentWire) {
            datatype = parentWire.type;
        }

        const wiredef =
            start.root?.wires.get(source_tunnel) ??
            new WireDefinition(source_tunnel, datatype, undefined, aliasName);
        if (!wiredef.tunnels) wiredef.tunnels = {} as TunnelDefinition;
        if (isInbound) wiredef.tunnels.in = tunnelmaps;
        else wiredef.tunnels.out = tunnelmaps;

        start.root?.wires.set(source_tunnel, wiredef);
    }

    getRootOutputForWire(block: EV3GBlock | undefined, wireName: string) {
        return [...(block?.root?.outputs?.entries() ?? [])].find(
            (item) => item[1].toString() === wireName,
        )?.[0];
    }
}

export function inferValueType(datatype: string) {
    switch (datatype) {
        case 'String':
            return ValueType.STRING;
        case 'Single':
            return ValueType.NUMBER;
        case 'Boolean':
            return ValueType.BOOLEAN;
        case 'Single[]':
            return ValueType.NUMBERARRAY;
        case 'Boolean[]':
            return ValueType.BOOLEANARRAY;
        default:
            return ValueType.UNKNOWN;
    }
}
