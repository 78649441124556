import { BlockValue, ElemValueType } from '../utils/blockvalue';
import { CONST_CM, CONST_INCHES, _debug } from '../utils/utils';
import {
    EV3BUTTON_MAP,
    EV3SENSORCOLOR_MAP,
    FLIPPERCOLORS_MAP,
    FLIPPERDISPLAYORIENTATION_MAP,
    round2,
} from '../utils/converters';
import { OperatorPrecedence, ValueType } from '../utils/enums';
import {
    RegistryManager,
    RegistryPayloadWithId,
    RegistryPayloadWithParent,
} from './registrymanager';

import { Context } from './context';

interface HelperFunctionDefintion {
    py_fn?: () => string;
    py_dependencies?: string[];
    imports?: [string, string][];
    local_fn?: (...args: ElemValueType[]) => ElemValueType;
    local_dynamic_fn?: (...args: ElemValueType[]) => ElemValueType;
    local_fn_condition?: (args: ElemValueType[]) => ElemValueType;
    type?: ValueType;
}

export class HelperEnabledRegistryPayload
    implements
        RegistryPayloadWithId,
        RegistryPayloadWithParent<HelperEnabledRegistryPayload>
{
    id!: string;
    private isPyFnEnabled = false;
    parent!: HelperRegistryManager;

    call(...args: ElemValueType[]): BlockValue {
        const fn_item = this.parent.helperFunctionsMap.get(this.id);

        if (fn_item) {
            fn_item.imports?.forEach(([module, name]) =>
                this.parent.context.imports.use(module, name),
            );

            // check if static local conversion is available
            if (fn_item.local_fn) {
                const allow_local =
                    args.every((elem) => !BlockValue.is_dynamic(elem)) &&
                    (!fn_item.local_fn_condition ||
                        fn_item.local_fn_condition(
                            args.map((arg) => BlockValue.value(arg)),
                        ));

                if (allow_local) {
                    const expr = fn_item.local_fn(
                        ...args.map((arg) => BlockValue.value(arg)), //? .raw
                    );
                    return BlockValue.is(expr)
                        ? expr
                        : new BlockValue(expr, {
                              precedence: OperatorPrecedence.SIMPLE,
                              type:
                                  fn_item.type ??
                                  (typeof expr === 'string'
                                      ? ValueType.STRING
                                      : ValueType.NUMBER),
                          }); //!!
                } else if (fn_item.local_dynamic_fn) {
                    const expr = fn_item.local_fn(...args);
                    return BlockValue.is(expr)
                        ? expr
                        : new BlockValue(expr, {
                              is_dynamic: true,
                              type:
                                  fn_item.type ??
                                  (typeof expr === 'string'
                                      ? ValueType.STRING
                                      : ValueType.NUMBER),
                          });
                }
            }

            if (fn_item.py_fn) {
                this.isPyFnEnabled = true;
                fn_item.py_dependencies?.forEach(
                    (fn_name2) => (this.parent.use(fn_name2).isPyFnEnabled = true),
                );
            }
        } else {
            _debug(`WARN: missing helper function called "${this.id}"`);
        }

        return new BlockValue(
            `${this.id}(${args.map((arg) => BlockValue.raw(arg)).join(', ')})`,
            {
                is_dynamic: true,
                type: fn_item?.type,
                precedence: OperatorPrecedence.SIMPLE,
            },
        );
    }

    static to_global_code(registry: HelperRegistryManager): string[] {
        const codes = Array.from(registry.entries())
            .filter(([, value]) => value.payload.isPyFnEnabled)
            .map(([key]) => {
                const fn_item = registry.helperFunctionsMap.get(key);
                if (fn_item?.py_fn) {
                    const value = fn_item.py_fn();
                    return value?.trim().split('\r\n');
                } else {
                    return [];
                }
            })
            .flat();

        return codes;
    }

    static createRegistry(context: Context) {
        return new HelperRegistryManager(
            context,
            () => new HelperEnabledRegistryPayload(),
        );
    }
}

export class HelperRegistryManager extends RegistryManager<HelperEnabledRegistryPayload> {
    helperFunctionsMap: Map<string, HelperFunctionDefintion>;
    constructor(
        context: Context,
        factory?: (...args: unknown[]) => HelperEnabledRegistryPayload,
    ) {
        super(context, factory);
        this.helperFunctionsMap =
            HelperRegistryManager.createHelperFunctionsMap(context);
    }

    static createHelperFunctionsMap(context: Context) {
        return new Map<string, HelperFunctionDefintion>(
            [
                // const
                [
                    'convert_time',
                    {
                        py_fn: () => `
def convert_time(sec):
    return float_safe(sec) * 1000`,
                        py_dependencies: ['float_safe'],
                        local_fn: (sec) => {
                            return BlockValue.toFloat(sec) * 1000;
                        },
                        type: ValueType.NUMBER,
                    },
                ],
                [
                    'convert_time_back',
                    {
                        py_fn: () => `
def convert_time_back(msec):
    return float_safe(msec) / 1000`,
                        py_dependencies: ['float_safe'],
                        local_fn: (msec) => {
                            return BlockValue.toFloat(msec) / 1000;
                        },
                        type: ValueType.NUMBER,
                    },
                ],
                [
                    'convert_speed',
                    {
                        py_fn: () => `
def convert_speed(pct):
    return float_safe(pct) * 10`,
                        local_fn: (speed_pct) => {
                            return BlockValue.toFloat(speed_pct) * 10;
                        },
                        py_dependencies: ['float_safe'],
                        // 100 % = 1080 deg/s for the medium motor
                        // 100 % = 970 deg/s for the large motor.
                        type: ValueType.NUMBER,
                    },
                ],

                [
                    'convert_speed_back',
                    {
                        py_fn: () => `
def convert_speed_back(deg_s):
    return float_safe(deg_s) / 10`,
                        py_dependencies: ['float_safe'],
                        local_fn: (speed_deg_s) => BlockValue.toFloat(speed_deg_s) / 10,
                        type: ValueType.NUMBER,
                    },
                ],
                [
                    'hub_speaker_flipper_play',
                    {
                        //           py_fn: () => `
                        // ${context.asyncPrefix}def hub_speaker_flipper_play(note, duration):
                        //     NOTES = ["C","C#","D","Eb","E","F","F#","G","G#","A","Bb","B"]
                        //     note_abc = NOTES[note%12]
                        //     octave = str(int(note/12))
                        //     bpm = int(60000 / duration * 4)
                        //     ${context.awaitPrefix}hub.speaker.play_notes([f"{note_abc}{octave}/1"], bpm)`,
                        py_fn: () => `
${context.asyncPrefix}def hub_speaker_flipper_play(note, duration):
    NOTE_FREQS = [16.35, 17.32, 18.35, 19.45, 20.60, 21.83, 23.12, 24.50, 25.96, 27.50, 29.14, 30.87]
    freq = NOTE_FREQS[note%12] * (2 ** (note//12))
    ${context.awaitPrefix}hub.speaker.beep(freq, duration)`,
                    },
                ],
                [
                    'hub_speaker_iconblocks_play',
                    {
                        // 12 bmp with /4 note is 0.5 sec
                        py_fn: () => `
${context.asyncPrefix}def hub_speaker_iconblocks_play(note):
    NOTES = [261.63, 293.66, 329.63, 349.23, 392.00, 440.00, 493.88, 523.25]
    freq = NOTES[((int(note) if note != "?" else randint(1, 8))%8)-1]
    ${context.awaitPrefix}hub.speaker.beep(freq, 500)`,
                    },
                ],
                [
                    'round',
                    {
                        // py_fn: //none needed
                        local_fn: (value) => round2(BlockValue.toFloat(value)),
                        type: ValueType.NUMBER,
                    },
                ],
                [
                    'float_safe',
                    {
                        py_fn: () => `
def float_safe(value, default=0):
    try: return float(value)
    except: return default
`,
                        local_fn_condition: (args) =>
                            args.every(
                                (arg) =>
                                    typeof arg === 'number' ||
                                    (typeof arg === 'string' &&
                                        String(parseFloat(arg)) === arg),
                            ),
                        local_fn: (value) => BlockValue.toFloat(value),
                        type: ValueType.NUMBER,
                    },
                ],
                [
                    'str',
                    {
                        // py_fn: //none needed
                        local_fn: (value) => String(value),
                        type: ValueType.STRING,
                    },
                ],
                [
                    'int_safe',
                    {
                        py_fn: () => `
def int_safe(value, default=0, base=10):
    try: return int(value, base)
    except: return default
`,
                        local_fn_condition: (args) =>
                            args.every(
                                (arg) =>
                                    typeof arg === 'number' ||
                                    (typeof arg === 'string' &&
                                        String(parseFloat(arg)) === arg),
                            ),
                        local_fn: (value, _default = 0, base = 10) =>
                            parseInt(
                                BlockValue.toString(value),
                                BlockValue.toInt(base),
                            ),
                        type: ValueType.NUMBER, //TODO //!! add int
                    },
                ],
                [
                    'event_task',
                    {
                        py_fn: () => `
async def event_task(condition_fn, stack_fn):
    while True:
        while not await condition_fn(): yield
        await stack_fn()
        while await condition_fn(): yield`,
                    },
                ],
                [
                    'class_Message',
                    {
                        py_fn: () => `
class Message:
    def __init__(self, stack_fns):
        self.running = False
        self.signalled = False
        self.cancelling = False
        self.stack_fns = stack_fns
    async def main_fn(self):
        while True:
            while not self.signalled: yield
            self.signalled = False
            await self.action_fn()
    async def action_fn(self):
        await self.guard_single()
        if self.running:
            self.cancelling = True
            while self.running: yield
            self.cancelling = False
        try:
            self.running = True
            await multitask(self.guard_fn(), multitask(*[stack_fn() for stack_fn in self.stack_fns]), race=True)
        finally:
            self.running = False
    def guard_single(self):
        if self.running:
            self.cancelling = True
            while self.running: yield
        self.cancelling = False
    def guard_fn(self):
        while self.running and not self.cancelling: yield
    async def broadcast_exec(self, wait):
        if wait:
            await self.action_fn()
        else:
            await self.guard_single()
            self.signalled = True`,
                    },
                ],
                [
                    'convert_distance',
                    {
                        local_fn: (value, unit) => {
                            switch (unit) {
                                case CONST_CM:
                                    return round2(BlockValue?.toFloat(value) * 10, 2); // cm->mm
                                case CONST_INCHES:
                                    return round2(BlockValue?.toFloat(value) * 25.4, 2); // in->mm
                                default:
                                    return value;
                            }
                        },
                        py_fn: () => `
def convert_distance(value, unit):
    if unit == "cm": return value * 10
    elif unit == "inches": return value * 25.4
    else: return value`,
                        type: ValueType.NUMBER,
                    },
                ],
                [
                    'convert_color',
                    {
                        local_fn: (value) => {
                            const colors = Array.from(FLIPPERCOLORS_MAP.values());
                            const value1 = BlockValue.toInt(value);
                            const color_value =
                                value1 in colors ? colors[value1] : 'Color.NONE';
                            return color_value;
                        },
                        py_fn: () => `
def convert_color(value):
    color_list = [${Array.from(FLIPPERCOLORS_MAP.values())
        .map((key, _value) => `${key}`)
        .join(', ')}]
    return color_list[int_safe(value)]`,
                        py_dependencies: ['int_safe'],
                        type: ValueType.ENUM,
                        imports: [['pybricks.parameters', 'Color']],
                    },
                ],
                [
                    'convert_color_back',
                    {
                        py_fn: () => `
def convert_color_back(value):
    color_list = [${Array.from(FLIPPERCOLORS_MAP.values())
        .map((key, _value) => `${key}`)
        .join(', ')}]
    return color_list.index(value) if value in color_list else None`,
                        type: ValueType.NUMBER,
                    },
                ],
                [
                    'convert_ev3gcolor_back',
                    {
                        py_fn: () => `
def convert_ev3gcolor_back(value):
    color_list = [${Array.from(EV3SENSORCOLOR_MAP.values())
        .map((key, _value) => `${key}`)
        .join(', ')}]
    return color_list.index(value) if value in color_list else None`,
                        type: ValueType.NUMBER,
                    },
                ],
                [
                    'convert_color_matrix',
                    {
                        local_fn: (colorHexChars) => {
                            const colors = colorHexChars
                                ?.toString()
                                .split('')
                                .map((colorHexChar: string) =>
                                    context.helpers
                                        .use('convert_color')
                                        .call(parseInt(colorHexChar, 16)),
                                );

                            if (
                                colors?.every((color) =>
                                    BlockValue.isEqual(color, colors[0]),
                                )
                            ) {
                                // If a single Color is given, then all 9 lights are set to that color.
                                return colors[0];
                            } else {
                                return `[${colors?.join(', ')}]`;
                            }
                        },
                        py_fn: () => `
def convert_color_matrix(colors: Color[]):
    return [convert_color(int_safe(color,0,16) if color!='?' else randint(0, 10)) for color in colors]`,
                        py_dependencies: ['convert_color', 'int_safe'],
                        type: ValueType.ENUMARRAY,
                    },
                ],

                [
                    'convert_brightness',
                    {
                        local_fn: (value) => {
                            // 0-9 -> 0-100
                            return round2(
                                (Math.min(9, Math.max(0, BlockValue.toFloat(value))) /
                                    9) *
                                    100,
                                3,
                            );
                        },
                        py_fn: () => `
def convert_brightness(value):
    return round(min(9, max(0, value)) / 9 * 100)`,
                        type: ValueType.NUMBER,
                    },
                ],
                [
                    'convert_icon_matrix',
                    {
                        py_fn: () => `
def convert_icon_matrix(value, pixel_brightness=100):
    return [[round((int(char) if '0' <= char <= '9' else
             9 if char == 'x' else
             randint(1, 9)) / 9 * pixel_brightness)
             for char in value[i:i+5]] for i in range(0, len(value), 5)]`,
                        // return [[(round((int(char) if '0' <= char <= '9' else 9 if char=='x' else randint(1, 9))/9*brightness)) for char in value[i:i+5]] for i in range(0, len(value), 5)]`,
                        type: ValueType.NUMBERARRAY,
                    },
                ],

                [
                    'convert_ussensor_distance',
                    {
                        local_fn: (value, unit) => {
                            switch (BlockValue.raw(unit)) {
                                case CONST_CM:
                                    return BlockValue.toFloat(value) * 10; // cm->mm
                                case CONST_INCHES:
                                    return BlockValue.toFloat(value) * 25.4; // in->mm
                                case '%':
                                    return (2000 * BlockValue.toFloat(value)) / 100; // 100% = 2000mm
                                default:
                                    return value;
                            }
                        },
                        py_fn: () => `
def convert_ussensor_distance(value, unit):
    if unit == "cm": return value * 10
    elif unit == "inches": return value * 25.4
    elif unit == "%": return 2000 * value / 100
    else: return value`,
                        type: ValueType.NUMBER,
                    },
                ],
                [
                    'convert_ussensor_distance_back',
                    {
                        local_fn: (value, unit) => {
                            switch (BlockValue.raw(unit)) {
                                case CONST_CM:
                                    return BlockValue.toFloat(value) / 10; // cm->mm
                                case CONST_INCHES:
                                    return BlockValue.toFloat(value) / 25.4; // in->mm
                                case '%':
                                    return (BlockValue.toFloat(value) * 100) / 2000; // 100% = 2000mm
                                default:
                                    return value;
                            }
                        },
                        py_fn: () => `
def convert_ussensor_distance_back(value, unit):
    if unit == "cm": return value / 10
    elif unit == "inches": return value / 25.4
    elif unit == "%": return value * 100 / 2000
    else: return value`,
                        type: ValueType.NUMBER,
                    },
                ],
                [
                    'convert_hub_orientation',
                    {
                        local_fn: (value) =>
                            'Side.' +
                            BlockValue.value(value)
                                ?.toString()
                                .replace('side', '')
                                .toUpperCase(),
                        py_fn: () => `
def convert_hub_orientation(value: str):
    return 'Side.' + value.replace('side', '').upper()`,
                        type: ValueType.ENUM,
                    },
                ],
                [
                    'convert_hub_orientation_back',
                    {
                        // NOOP - local_fn:
                        py_fn: () => `
def convert_hub_orientation_back(value: Side):
    value = str(value).replace('Side.', '').lower()
    return value + 'side' if value in ['left','right'] else value`,
                        type: ValueType.STRING,
                    },
                ],
                [
                    'convert_display_orientation',
                    {
                        // value is 1..4
                        local_fn: (value) => {
                            const orientation = Array.from(
                                FLIPPERDISPLAYORIENTATION_MAP.entries(),
                            ).find(([k, _v]) => k === BlockValue.toInt(value));
                            const retvalue = orientation
                                ? orientation[1]
                                : 'Side.ERROR';
                            return retvalue;
                        },
                        py_fn: () => `
def convert_display_orientation(value):
    return [Side.TOP, Side.LEFT, Side.RIGHT, Side.BOTTOM][value-1]`,
                        imports: [['pybricks.parameters', 'Side']],
                    },
                ],
                [
                    'get_pupdevices',
                    {
                        py_fn: () => `
def get_pupdevices(class_type, *args):
    for port in [Port.A,Port.B,Port.C,Port.D,Port.E,Port.F]:
        try: return class_type(port, *args)
        except: pass`,
                    },
                    //TODO: wip, this returns the first one - LEGO should use ALL
                ],
                [
                    'pupdevice_type',
                    {
                        py_fn: () => `
def pupdevice_type(port):
    try: return PUPDevice(port).info()['id']
    except: return None`,
                    },
                ],
                [
                    'play_animation',
                    {
                        py_fn: () => `
${context.asyncPrefix}def play_animation(anim):
    while True:
        for frame in anim["frames"]:
            hub.display.icon(frame)
            ${context.awaitPrefix}wait(1000/anim["fps"])
        if not anim["loop"]: break
`,
                    },
                ],
                [
                    'relative_position',
                    {
                        py_fn: () => `
def relative_position(motor):
    angle_mod = motor.angle() % 360
    return angle_mod if angle_mod <= 180 else angle_mod - 360`,
                        type: ValueType.NUMBER,
                    },
                ],
                [
                    'motorpair_move',
                    {
                        py_fn: () => `
def motorpair_move(motor_left, motor_right, steer, value):
    secondary_value = (50 - abs(steer)) * 2 / 100 * value
    motor_left.run(value if steer>=0 else secondary_value)
    motor_right.run(value if steer<=0 else secondary_value)                
              `,
                    },
                ],
                [
                    'motorpair_move_dc',
                    {
                        py_fn: () => `
def motorpair_move_dc(motor_left, motor_right, steer, value):
    secondary_value = (50 - abs(steer)) * 2 / 100 * value
    motor_left.dc(value if steer>=0 else secondary_value)
    motor_right.dc(value if steer<=0 else secondary_value)                
              `,
                    },
                ],
                [
                    'convert_ev3button_back',
                    {
                        py_fn: () => `
def convert_ev3button_back(value):
    value_list = [${Array.from(EV3BUTTON_MAP.values())
        .map((key, _value) => `${key}`)
        .join(', ')}]
    return value_list.index(value) if value in value_list else None`,
                        type: ValueType.NUMBER,
                    },
                ],
            ],

            // num_eval: {
            //   local_fn: num_eval,
            //   local_dynamic_fn: num_eval,
            // },
        );
    }
}
