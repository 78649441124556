import { CodeBlock, CodeConverter } from '../utils/types';
import { EV3GBlock, EV3GFile, EV3GProject, WireDefinition } from './blocks';
import PyConverterOptions, { PyProjectResult } from '../pyconverteroptions';
import { X2jOptions, XMLParser } from 'fast-xml-parser';
import { _debug, enforceNonEmpty, getFileExtension, indent_code } from '../utils/utils';
import { buildPyCode, generatePyFunctionHead } from '../projectconverter';
import {
    getBlocks,
    sanitizeArgName,
    sanitizeBlockName,
    sanitizeFileValue,
    sanitizeWire,
} from './utils';

import { Context } from '../context/context';
import { DeviceType } from '../utils/enums';
import { EV3GRaw } from './xml-types';
import JSZip from 'jszip';
import { handleBlocks } from '../handlers/handlers';
import { initMotorPairMovementPair } from '../handlers/motorpair';
import { processOperation } from '../handlers/operator';

interface Bounds {
    x1: number;
    y1: number;
    x2: number;
    y2: number;
}

/* LEGO EV3G LabView and LabView mobile and compiled format */
export const EV3G_LABVIEW_EXTENSIONS: string[] = ['.ev3', '.ev3m', '.rbf'] as const;

export class EV3GProjectToPythonConverter implements CodeConverter {
    constructor(
        private _options: PyConverterOptions,
        readonly context = new Context(),
    ) {
        this.context.deviceType = DeviceType.EV3G;
    }

    public static supportsExtension(ext: string): boolean {
        return EV3G_LABVIEW_EXTENSIONS.includes(ext);
    }

    async convert(filedata: ArrayBuffer | Buffer): Promise<PyProjectResult> {
        const retval: PyProjectResult = {};
        const result = new EV3GProject();

        const zip = new JSZip();
        const zipContent = await zip.loadAsync(filedata);
        if (!zipContent) {
            throw 'Error loading the zip file.';
        }

        const lastModifiedDate = Object.keys(zipContent.files).reduce(
            (latest, fileName) => {
                const file = zipContent.files[fileName];
                return !latest || latest < file.date ? file.date : latest;
            },
            undefined as Date | undefined,
        );

        /* Read description from ___ProjectDescription */
        result.description = await this.extractProjectDescription(zipContent);

        /* Read .ev3p files */
        const efiles = await this.extractEV3PBlocks(zipContent);

        /* python code */
        {
            // NOTE: will not consilidate different pairs of movement pairs
            efiles.forEach((efile) => efile.stack && this.preprocessStack(efile.stack));
            // console.log(efiles[0].stack?.dump().join('\n'));

            /* comments */
            efiles.forEach((efile) => {
                if (efile.stack) this.processFreeStandingCommentBox(efile.stack);
            });

            const retval1 = efiles.map((efile) => this.fileToCode(efile, true));

            const programCode = retval1.reduce(
                (acc, arr) => acc.concat(acc.length ? [''] : [], arr),
                [] as string[],
            );
            const mainProgramCode: string[] = [];

            retval.pycode = buildPyCode.call(
                this,
                this._options,
                programCode,
                mainProgramCode,
            );

            /* project comment */
            const projectComment = !this._options?.debug?.skipHeader
                ? this.extractHeadComment(
                      this._options.filename
                          ?.replace(/^.*[\\\/]/, '')
                          .replace(/\.[^/.]+$/, ''),
                      lastModifiedDate,
                  )
                : null;
            if (projectComment) {
                retval.pycode = [projectComment, retval.pycode].join('\n');
            }
        }

        /* pseudo code */
        {
            const retval1 = efiles.map((efile) => this.fileToCode(efile, false));
            retval.plaincode = retval1.flat().join('\r\n\r\n');
        }

        retval.deviceType = this.context.deviceType;
        return retval;
    }

    convertBlockToCode(block: EV3GBlock, isPythonMode = true): string[] {
        if (isPythonMode) {
            try {
                const retval = [] as string[];
                // tunnel: while loop input tunnels go before the while loop
                if (block.hasWhileLoop) {
                    const tunnelCode = this.generateTunnelTranslations(
                        block,
                        true,
                        sanitizeArgName(block.id, false),
                    );
                    retval.push(...tunnelCode);
                    //TODO: handle outpbounds as well
                }
                //TODO: check, this is for whiles
                //TODO: handle outbound tunnels

                const code1 = handleBlocks.call(this, block);
                if (code1) {
                    // main code
                    retval.push(...code1);
                    const returnPrefix = this.generateReturnResult(block, ' = ', false);
                    // console.log('>>>', block.wires);
                    if (returnPrefix) {
                        if (
                            block.hasSwitch ||
                            block.hasSwitchCase ||
                            block.hasWaitFor ||
                            block.hasWhileLoop
                        ) {
                            // special block
                            // TODO: handle this later separately
                            // e.g. ColorCompare -> needs ColorValue
                            if (returnPrefix) {
                                // convert EV3G comparator to value - ColorCompare -> ColorValue
                                const retvalue = processOperation.call(
                                    this,
                                    block.getBlock('CONDITION'),
                                    'None',
                                    block.opcode.replace('Compare', 'Value'),
                                );
                                const line = `${returnPrefix}${retvalue.raw} # return value from the loop`;
                                retval.push(line);
                            }
                        } else {
                            // normal block
                            if (
                                retval.filter(
                                    (line) => !line.startsWith('#') && line.length > 0,
                                ).length > 1
                            ) {
                                _debug(
                                    'TODO: not clear where to append return prefix - NOW: add it to the last line',
                                );
                            }
                            retval[retval.length - 1] = `${returnPrefix}${
                                retval[retval.length - 1]
                            }`;
                        }
                    }

                    // add comments
                    if (block.commentBoxes.length) {
                        retval.unshift(...block.commentBoxes.map(this.formatComments));
                    }

                    // add explaining comments
                    if (this._options.debug?.showExplainingComments) {
                        const pseudoLine =
                            block.getDescription(false) +
                            (this._options.debug?.showBlockIds ? ` @ ${block.id}` : '');
                        retval.unshift(...['', `# ${pseudoLine}`]);
                    }

                    return retval;
                }
            } catch (e) {
                console.trace(e);
                return [`# error with: ${block.getDescription()} @ ${block.id} - ${e}`];
            }

            // _debug('unknown block', block.get_block_description());
            return [
                ...(this._options.debug?.showExplainingComments ? [''] : []),
                `# Unknown: ${block.getDescription()} @ ${block.id}`,
                'pass',
            ];
        } else {
            return [block.getDescription(isPythonMode)];
        }
    }

    generateTunnelTranslations(
        block?: EV3GBlock,
        isInbound?: boolean,
        filterId?: string,
    ): string[] {
        if (!block) return [];

        const retval = [] as string[];
        let wire1: string, wiredef: WireDefinition;
        for ([wire1, wiredef] of block.wires.entries()) {
            const tunnels = isInbound ? wiredef.tunnels?.in : wiredef.tunnels?.out;
            if (!tunnels) continue;
            const thisBlockName = sanitizeArgName(block.id, false);
            // console.log('>>12', thisBlockName, block.opcode);
            //!! filter based on this

            // if (wire1.startsWith())
            let wire1t = wire1;
            const wire2t = filterId ? tunnels.get(filterId) : [...tunnels.values()][0];
            if (wiredef?.alias) wire1t = wiredef.alias;
            // console.log(wire1, wire1t, wiredef?.alias, root1?._block?.['@Tag']);

            if (wire2t) {
                // isInbound = wiredef.tunnels?.inbound;
                const wireLeft = isInbound ? wire2t : wire1t;
                const wireRight = isInbound ? wire1t : wire2t;
                retval.push(
                    `${wireLeft} = ${wireRight} # ${
                        isInbound ? 'inbound tunnel' : 'outbound tunnel'
                    }`,
                );
            }
            //TODO: trance, select filter by Input/Output
        }
        return retval;
    }

    generateReturnResult(
        block: EV3GBlock | undefined,
        postfix: string,
        filterWiredOnly: boolean = false,
    ): string | undefined {
        if (!block) return undefined;

        const wiredOutputs = [...block.outputs.values()].filter(
            (item) => item.options?.is_variable,
        );

        if (wiredOutputs.length > 0) {
            // TODO: root - should be the muyblock root, not the loop/switch
            // check if it can be translated to mega block outputs
            const sourceList = filterWiredOnly
                ? wiredOutputs
                : [...block.outputs.values()];
            const outmap = sourceList
                .map((item) => {
                    const wireName = item.toString();

                    if (item.options?.is_variable) {
                        const wire = block.wires.get(item.toString());
                        if (wire) {
                            return wire.alias ?? wire.name;
                        }
                    }

                    return wireName;
                })
                .join(', ');
            return `${outmap}${postfix}`;
        } else {
            return undefined;
        }
    }

    process_stack(stack: CodeBlock[] | null, isPythonMode = true): string[] {
        // NOTE: in EV3G stack already has children, so we only pass and process the first
        return indent_code(
            enforceNonEmpty(
                this.processStackExtended(stack?.[0] as EV3GBlock, isPythonMode),
            ),
        );
    }

    // getEmptyBlock(isPythonMode: boolean): string {
    //     if (isPythonMode) {
    //         return this.context.isAsyncNeeded ? 'yield' : 'pass';
    //     } else {
    //         return '(None)';
    //     }
    // }

    processStackExtended(
        block: EV3GBlock | null,
        isPythonMode = true,
        headCodeOverride?: string[],
    ): string[] {
        if (!block) return [];

        const ev3gstack = block as EV3GBlock;
        const headCode =
            headCodeOverride ?? this.convertBlockToCode(ev3gstack, isPythonMode);

        //TODO:  is this needed for python blocks where children/substack is processed anyhow (e.g. loop/if)
        let stackCode =
            block
                .getSubStack(0)
                .map((block1) =>
                    this.processStackExtended(block1 as EV3GBlock, isPythonMode),
                )
                .flat() ?? [];

        // tunnel: switch case handling
        if (block.hasSwitchCase) {
            // tunnel: case inbound tunnels go inside the case if statement - before the code
            const tunnelCodeInbound = this.generateTunnelTranslations(
                block?.parent, //!!
                true,
                sanitizeArgName(block.id, false), //!!
            );
            stackCode.unshift(...tunnelCodeInbound);

            // tunnel: case outbound tunnels go inside the case if statement - before the code
            const tunnelCodeOutbound = this.generateTunnelTranslations(
                block?.parent,
                false,
                sanitizeArgName(block.id, false), //!!
            );
            stackCode.push(...tunnelCodeOutbound);
        }

        // we need to explitely add empty stacks
        if (
            ev3gstack.enforceChildren &&
            (ev3gstack.hasWhileLoop || ev3gstack.hasSwitchCase)
        ) {
            //stackCode = [this.getEmptyBlock(isPythonMode)];
            stackCode = enforceNonEmpty(stackCode, isPythonMode);
        }

        return [...headCode, ...indent_code(stackCode)];
    }

    fileToCode(efile: EV3GFile, isPythonMode = true): string {
        const fnbase = sanitizeFileValue(efile.filename);

        // translation of inputs to function arguments / mega accessors dw12 = cm
        const funcArgs = efile.stack?.inputs ? [...efile.stack.inputs.keys()] : [];

        const funcSignature = `${fnbase}(${funcArgs.join(', ')})`;

        const headCode = generatePyFunctionHead.call(
            this,
            funcSignature,
            efile.stack?.children ?? [],
        );
        if (efile.stack?.commentBoxes) {
            const headComment = efile.stack.commentBoxes.map(this.formatComments);
            headCode.unshift(...headComment);
        }

        const stackCode = this.processStackExtended(
            efile.stack ?? null,
            isPythonMode,
            headCode,
        );

        // change naming to output mega accesors
        const outmap = this.generateReturnResult(efile.stack, '', true);
        if (outmap) {
            stackCode.push(...indent_code(`return ${outmap}`));
        }

        return stackCode.join('\r\n');
    }

    formatComments(input: string) {
        return input
            .split(/[\r\n|\r]/)
            .map((line) => `# ${line}`)
            .join('\r\n');
    }

    async extractEV3PBlocks(zipContent: JSZip): Promise<EV3GFile[]> {
        let filesEV3P = [...Object.values(zipContent.files)]
            .filter((item) => getFileExtension(item.name) === '.ev3p')
            .sort((a, b) => a.name.localeCompare(b.name));

        // DEBUG: restrict to single program
        if (this._options.debug?.showThisStackOnly) {
            filesEV3P = filesEV3P.filter(
                (item) =>
                    sanitizeFileValue(item.name) ===
                    this._options.debug?.showThisStackOnly,
            );
        }

        const retval1 = await Promise.all(
            filesEV3P.map(async (file): Promise<EV3GFile> => {
                const content = await file?.async('string');
                if (this._options?.debug?.callback)
                    this._options.debug.callback(`${file.name}.xml`, content);

                const xmlopts = {
                    attributeNamePrefix: '',
                    ignoreAttributes: false, // Include attributes in the output
                    parseNodeValue: true, // Convert node values to text
                    parseAttributeValue: true, // Parse attribute values as well

                    allowBooleanAttributes: true,
                    ignoreDeclaration: true,
                    isArray: (
                        name: string,
                        jpath: string,
                        isLeafNode: boolean,
                        isAttribute: boolean,
                    ) => {
                        // if (alwaysArray.indexOf(jpath) !== -1) return true;
                        return !isAttribute;
                    },
                    updateTag(tagName, jPath, attrs) {
                        if (EV3GRaw.BLOCK_NAMES.includes(tagName)) {
                            attrs['@Tag'] = tagName;
                        }
                        return tagName;
                    },
                    trimValues: true,
                } as X2jOptions;
                const parser = new XMLParser(xmlopts);
                const json = parser.parse(content ?? '', true);
                const root =
                    json.SourceFile[0].Namespace[0].VirtualInstrument[0]
                        .BlockDiagram[0];

                const projectStack = this.extractBlockFromFile(root, file.name);
                const ev3gfile = new EV3GFile();
                ev3gfile.filename = file.name;
                ev3gfile.stack = projectStack;

                return ev3gfile;
            }),
        );

        return retval1;
    }

    extractBlockFromFile(
        rawroot: EV3GRaw.Block,
        filename: string,
    ): EV3GBlock | undefined {
        // process all/multiple StartBlocks
        const hasMultiHats = rawroot.StartBlock.length > 1;
        let topBlock: EV3GBlock | undefined = undefined;
        const wires = new Map<string, WireDefinition>();
        if (hasMultiHats) {
            // RunParallel, and should set children to RunParallelItem
            const level = 1;
            topBlock = new EV3GBlock(
                { Id: 'n0', '@Tag': '_StartRoot', Target: '' } as EV3GRaw.Block,
                undefined,
                rawroot,
                rawroot,
                level,
            ); // StartBlock parent
            topBlock.enforceChildren = true;
            topBlock.wires = wires;
        }

        rawroot.StartBlock.forEach((start: EV3GRaw.Block) => {
            const blocks = getBlocks(rawroot);
            const stack = this.traverse(
                start,
                blocks,
                hasMultiHats ? topBlock : undefined,
                rawroot,
                {
                    rawroot,
                    level: hasMultiHats ? 2 : 1,
                    wires: hasMultiHats ? undefined : wires,
                },
            );
            if (!stack) return;

            if (!hasMultiHats) {
                topBlock = stack;
            } else {
                topBlock?.children.push(stack);
            }
        });

        return topBlock;
    }

    async extractProjectDescription(zipContent: JSZip) {
        const file = zipContent.file('___ProjectDescription');
        let contents = await file?.async('string');
        contents = contents?.replace(/(\r\n|\r|\n)/g, '\r\n');
        return contents;
    }

    findSubBlock(
        block: EV3GRaw.Block,
        childType: string,
        childValue?: string,
        selector?: [string, string],
    ) {
        return (block as any)?.[childType]?.find(
            (elem: unknown) =>
                (childValue === undefined || (elem as any)['Id'] === childValue) &&
                (selector === undefined || (elem as any)[selector[0]] === selector[1]),
        );
    }

    findTerminal(
        block: EV3GRaw.Block,
        terminalId?: string,
        selector?: [string, string],
    ) {
        return this.findSubBlock(block, 'Terminal', terminalId, selector);
    }

    findBlocksWithTerminal(
        blocks: EV3GRaw.Block[],
        terminalId?: string,
        selector?: [string, string],
    ) {
        let retval: EV3GRaw.Block[] | undefined;
        for (const block of blocks) {
            const terminal = this.findTerminal(block, terminalId, selector) as any;
            if (terminal) {
                if (!retval) retval = [];
                retval.push(block);
                // Return the found block
            }
        }
        return retval;
    }

    traverse(
        start: EV3GRaw.Block,
        blocks: EV3GRaw.Block[] | undefined,
        parent: EV3GBlock | undefined,
        rawparent: EV3GRaw.Block,
        options: {
            rawroot?: EV3GRaw.Block;
            estart?: EV3GBlock;
            firstIsPeer?: boolean;
            singleblockOnly?: boolean;
            level: number;
            wires?: Map<string, WireDefinition>;
        },
    ): EV3GBlock | undefined {
        // returns 'start' as EV3GBlock and add the rest as children EV3GBlock[]
        let estart: EV3GBlock | undefined = options.estart;

        // const code = [];
        let current: EV3GRaw.Block | null | undefined = start;
        while (current) {
            const ethis = new EV3GBlock(
                current,
                parent,
                options.rawroot,
                rawparent,
                estart ? estart?.level + 1 : options.level,
                options.wires ?? parent?.wires,
            );

            if (!estart) {
                estart = ethis;
                parent = estart;

                if (!options.singleblockOnly) {
                    options.rawroot = undefined; // rawroot from this
                    estart.preprocessWires(blocks, rawparent);
                }
            }

            if (this.processBlock(ethis, estart, blocks, rawparent)) {
                if (estart !== ethis) estart.children.push(ethis);
            }

            if (options.singleblockOnly) return estart;

            // get next item
            const nexts = this.findNextPeerRawBlocks(current, blocks);
            if (nexts && nexts.length > 1) {
                // TODO: sequence wire name would be nicer
                const forkparent = new EV3GBlock(
                    {
                        Id: `fp${sanitizeArgName(ethis.id, false)}`,
                        '@Tag': '',
                        Target: '',
                    } as EV3GRaw.Block,
                    undefined,
                    undefined,
                    current,
                    ethis.level + 1,
                ); // ForkParent virtual node
                forkparent.opcode_ = '_ForkParent';
                forkparent.enforceChildren = true;
                forkparent.wires = ethis.wires;
                forkparent.forkchildren = [];
                estart.children.push(forkparent);

                for (const [index, next] of nexts.entries()) {
                    const forkitem = new EV3GBlock(
                        { Id: `f${index}`, '@Tag': '', Target: '' } as EV3GRaw.Block,
                        undefined,
                        undefined,
                        current,
                        forkparent.level + 1,
                    ); // ForkItem virtual node
                    forkitem.opcode_ = '_ForkItem';
                    forkitem.enforceChildren = true;
                    forkitem.wires = ethis.wires;

                    this.traverse(next, blocks, forkitem, rawparent, {
                        estart: forkitem,
                        wires: ethis.wires,
                        level: forkitem.level + 1,
                    });
                    forkitem.preprocessWires(blocks, rawparent);

                    forkparent.forkchildren.push(forkitem);
                }
                current = undefined;
            } else {
                current = nexts?.[0];
            }
        }
        // console.log('>>', estart?.dump());

        return estart;
    }

    private processBlock(
        ethis: EV3GBlock,
        estart: EV3GBlock,
        blocks: EV3GRaw.Block[] | undefined,
        rawparent: EV3GRaw.Block,
    ) {
        try {
            ethis.opcode_ = ethis._block?.['@Tag'] ?? '';
            switch (ethis._block?.['@Tag']) {
                case 'StartBlock':
                    {
                        ethis.enforceChildren = true;
                    }
                    break;
                case 'ConfigurableWaitFor':
                    {
                        /*
                         * ConfigurableWaitFor -> ConfigurableMethodCall[Target]
                         */

                        ethis.opcode_ = sanitizeBlockName(ethis._block['Target']);
                        ethis.hasWaitFor = true;
                    }
                    break;
                case 'ConfigurableWhileLoop':
                    {
                        /*
                         * ConfigurableWhileLoop -> ConfigurableWhileLoop.BuiltInMethod[CallType=StopCondition] #1 -> ConfigurableMethodCall[Target]
                         * next elements under
                         *   ConfigurableWhileLoop.BuiltInMethod[CallType=LoopIndex] #0
                         */
                        ethis.hasWhileLoop = true;
                        ethis.enforceChildren = true;
                        const current1 = ethis._block as EV3GRaw.ConfigurableWhileLoop;
                        const blocks1 = getBlocks(current1);
                        const builtins =
                            current1['ConfigurableWhileLoop.BuiltInMethod'];

                        /* loopindex - this will be the starting node for the internal blocks */
                        {
                            const blockLoopIndex =
                                builtins[0].ConfigurableMethodCall[0]; //!! CallType = 'LoopIndex'
                            // loopindex is not needed as a block
                            const nexts = this.findNextPeerRawBlocks(
                                blockLoopIndex,
                                blocks1,
                            );
                            if (nexts && nexts.length > 1) {
                                _debug(
                                    'multiple sequence wires, forking not yet implemented',
                                );
                            }
                            const blockLoopIndexNext = nexts?.[0];

                            //<Terminal Id="Loop\ Index" Direction="Output" Wire="w14" DataType="Single"
                            const loopIndexTerminal =
                                blockLoopIndex.ConfigurableMethodTerminal?.[1]
                                    ?.Terminal?.[0]; //!! Id="Loop\ Index"
                            // const t1 = this.findBlockWithTerminal(blocks1, terminalId)
                            if (loopIndexTerminal?.Wire) {
                                ethis.generationHelpers.loopIndexWire = sanitizeWire(
                                    ethis,
                                    loopIndexTerminal.Wire,
                                );
                            }

                            ethis.preprocessWires(blocks1, rawparent);
                            if (blockLoopIndexNext) {
                                this.traverse(
                                    blockLoopIndexNext,
                                    blocks1,
                                    ethis,
                                    rawparent,
                                    {
                                        level: ethis.level + 1,
                                        estart: ethis,
                                    },
                                );
                            }
                        }

                        /* stopcondition */
                        {
                            const blockStopCondition =
                                builtins[1].ConfigurableMethodCall[0]; //!! CallType = 'StopCondition'
                            const stopCode = this.traverse(
                                blockStopCondition,
                                undefined,
                                ethis,
                                rawparent,
                                {
                                    level: ethis.level + 1,
                                    //estart: ethis,
                                    singleblockOnly: true,
                                },
                            );

                            if (stopCode) {
                                // we process the stopCode instead of the while loop
                                // thus we use the generationHelpers from the stopCode
                                stopCode.generationHelpers.loopIndexWire =
                                    ethis.generationHelpers.loopIndexWire;
                                ethis.condition = stopCode;
                            }
                        }
                    }
                    break;
                case 'PairedConfigurableMethodCall':
                    {
                        /*
                         * PairedConfigurableMethodCall[Target] -> ConfigurableCaseStructure -> ConfigurableCaseStructure.Case
                         * PairedConfigurableMethodCall[Target] -> ConfigurableFlatCaseStructure -> ConfigurableFlatCaseStructure.Case
                         */

                        ethis.opcode_ = sanitizeBlockName(ethis._block['Target']);
                        ethis.hasSwitch = true;
                        // will fill this.condition later

                        // ethis.needChildren = true;
                        // ethis.condition = ethis; //!! causes circular loop
                    }
                    break;
                case 'ConfigurableCaseStructure':
                case 'ConfigurableFlatCaseStructure':
                    {
                        ethis.hasSwitch = true;
                        const current1 = ethis._block as any;
                        const blocks1 = getBlocks(current1);
                        const cases = [
                            ...(current1['ConfigurableCaseStructure.Case'] ?? []),
                            ...(current1['ConfigurableFlatCaseStructure.Case'] ?? []),
                        ];
                        const defaultBlockId = ethis._block.Default ?? undefined;
                        const pairedcaseBlock =
                            estart.children[estart.children.length - 1];
                        ethis.generationHelpers.switchPairedParent = pairedcaseBlock;

                        const caseblocks: EV3GBlock[] = cases
                            .map((case1) => {
                                const blocks1 = getBlocks(case1);
                                const casecode = this.traverse(
                                    case1,
                                    blocks1,
                                    ethis,
                                    rawparent,
                                    {
                                        level: ethis.level + 1,
                                    },
                                );
                                return casecode;
                            })
                            .filter((item) => item !== undefined)
                            .toSorted((a, b) => {
                                // default goes to the end
                                if (a.id === defaultBlockId) return +1;
                                if (b.id === defaultBlockId) return -1;

                                // otherwise sort by Pattern
                                return a._block?.Pattern?.toString().localeCompare(
                                    b._block?.Pattern?.toString(),
                                );
                            });
                        pairedcaseBlock.children.push(...caseblocks);

                        caseblocks.forEach((item, index) => {
                            item.generationHelpers.switchPairedParent = pairedcaseBlock;
                            item.generationHelpers.switchCaseCounter = index;
                            item.generationHelpers.switchCaseIsDefault =
                                defaultBlockId === item.id;
                        });

                        // process case tunnels
                        ethis.preprocessWires(blocks1, rawparent);

                        // adding only the paired case block, and not this, also no need to find next
                        return false;
                    }
                    break;
                case 'ConfigurableCaseStructure.Case':
                case 'ConfigurableFlatCaseStructure.Case':
                    {
                        // ethis.opcode_ = 'Case';
                        ethis.hasSwitchCase = true;
                        ethis.enforceChildren = true;

                        const wireOut: string =
                            this.findBlocksWithTerminal(
                                ethis._block.SequenceNode,
                                undefined,
                                ['Direction', 'Output'],
                            )?.[0].Terminal?.[0]['Wire'] ?? '';
                        const substart = blocks
                            ? this.findBlocksWithTerminal(blocks, 'SequenceIn', [
                                  'Wire',
                                  wireOut,
                              ])?.[0]
                            : undefined;
                        // TODO: case sequenceout can connect to multiple sequencein wires

                        // all children should be pushed under ethis.children
                        if (substart)
                            this.traverse(substart, blocks, ethis, rawparent, {
                                estart: ethis,
                                level: ethis.level + 1,
                            });
                    }
                    break;
                case 'ConfigurableMethodCall':
                    {
                        const target = sanitizeBlockName(ethis._block['Target']);
                        ethis.opcode_ = target;
                    }
                    break;
            }
            return true;
        } catch (err) {
            console.error(`::ERROR:: @${ethis?.id}`, err);
        }
    }

    private findNextPeerRawBlocks(
        current?: EV3GRaw.Block,
        blocks?: EV3GRaw.Block[],
    ): EV3GRaw.Block[] | undefined {
        if (!current) return undefined;

        let nexts: EV3GRaw.Block[] | undefined;
        switch (current['@Tag']) {
            case 'PairedConfigurableMethodCall':
                {
                    /* navigate from PairedConfigurableMethodCall -> ConfigurableCaseStructure */
                    const paired = (current as any)['PairedStructure'] as string;
                    const next = blocks?.find(
                        (item) =>
                            [
                                'ConfigurableCaseStructure',
                                'ConfigurableFlatCaseStructure',
                            ].includes(item?.['@Tag']) && item?.['Id'] === paired,
                    );
                    if (next) {
                        nexts = [next];
                    }
                }
                break;
            default:
                {
                    const wireOut: string =
                        this.findTerminal(current, 'SequenceOut')?.['Wire'] ?? '';
                    nexts = blocks
                        ? this.findBlocksWithTerminal(blocks, 'SequenceIn', [
                              'Wire',
                              wireOut,
                          ])
                        : undefined;
                    // TODO: sequenceout can connect to multiple sequencein wires
                }
                break;
        }
        return nexts;
    }

    preprocessStack(estack: EV3GBlock) {
        let motorpairFound = false;
        const _preprocessStackFn = (estack: EV3GBlock) => {
            for (const eblock of estack.children) {
                const op = eblock.opcode;
                if (op.match(/^Move.*/) && !motorpairFound) {
                    initMotorPairMovementPair
                        .call(this, eblock, undefined, true)
                        .ensureDependencies();
                    motorpairFound = true;
                    // only one/first setMovementPair is taken into account
                }
                // if (op === 'flippercontrol_fork') {
                //     this.context.isAsyncNeeded = true;
                // }
                if (eblock.children) _preprocessStackFn(eblock);
            }
        };
        _preprocessStackFn(estack);

        // only if no setMovementPair is used
        if (!motorpairFound) {
            initMotorPairMovementPair
                .call(this, undefined, ['A', 'B'], false)
                .ensureDependencies();
        }
    }

    extractHeadComment(
        projectname: string | undefined,
        lastModifiedDate: Date | undefined,
    ) {
        return `
"""
Project:    ${projectname}
Last saved: ${lastModifiedDate?.toISOString()}

"""`.trim();
    }

    processFreeStandingCommentBox(root: EV3GBlock) {
        // <Comment Bounds="13 -65 113 43" SizeMode="User" AttachStyle="Free">
        //     <Content>Comment1</Content>
        // </Comment>
        const allBlocks = new Map<EV3GBlock, Bounds>();
        const allComments = new Map<EV3GRaw.Comment, Bounds>();

        // cache all childs
        _getChildBoundsMap(root);

        // assign comments
        for (let [k1, v1] of allComments.entries()) {
            const [refx, refy]: [number, number] = [v1.x1, v1.y2];
            let closestItem: EV3GBlock | undefined = undefined;
            let minDist: number | undefined = undefined;
            for (let [k2, v2] of [...allBlocks.entries()]) {
                // Calculate the Euclidean distance between p1 and p2
                const dist = Math.sqrt(
                    Math.pow(refx - v2.x1, 2) + Math.pow(refy - v2.y1, 2),
                );

                // Check if this is the smallest distance found
                if (minDist === undefined || dist < minDist) {
                    minDist = dist;
                    closestItem = k2;
                }
            }
            closestItem?.commentBoxes.push(k1.Content[0]);
            // console.log(k1.Content, closestItem?.id);
        }

        function _extractBounds(rawblock?: EV3GRaw.Block) {
            const match = rawblock?.Bounds?.split(' ').map(Number);
            if (match?.length === 4) {
                const bounds: Bounds = {
                    x1: match?.[0],
                    y1: match?.[1],
                    x2: match?.[0] + match?.[2],
                    y2: match?.[1] + match?.[3],
                };
                return bounds;
            }
        }
        function _getChildBoundsMap(block: EV3GBlock) {
            const bounds = _extractBounds(block._block);
            if (bounds) allBlocks.set(block, bounds);

            block?.children?.forEach(_getChildBoundsMap);

            const comments = [
                ...(Array.isArray(block._rootblock?.Comment)
                    ? (block._rootblock?.Comment as EV3GRaw.Comment[])
                    : []),
                ...(Array.isArray(block._block?.Comment)
                    ? (block._block?.Comment as EV3GRaw.Comment[])
                    : []),
            ];
            comments?.forEach((rawcomment) => {
                // console.log(rawcomment);
                const bounds = _extractBounds(rawcomment);
                if (bounds) allComments.set(rawcomment, bounds);
            });
        }
    }
}
