import { EV3GBlock } from './blocks';
import { EV3GRaw } from './xml-types';

export function getBlocks(root: EV3GRaw.Block): EV3GRaw.Block[] {
    return EV3GRaw.BLOCK_NAMES.reduce((aggr, tag) => {
        const elems = (root as any)[tag];
        return elems?.length ? aggr.concat(elems) : aggr;
    }, [] as EV3GRaw.Block[]);
}

export function sanitizeBlockName(name: string): string;
export function sanitizeBlockName(name?: string): string | undefined;
export function sanitizeBlockName(name?: string): string | undefined {
    return name
        ?.replaceAll(/\\(.)/g, '$1')
        .replaceAll('X3.Lib:', '')
        .replaceAll('.vix', '');
}

export function sanitizeArgName(name: string, isSimple?: boolean): string;
export function sanitizeArgName(name?: string, isSimple?: boolean): string | undefined;
export function sanitizeArgName(
    name?: string,
    isSimple: boolean = true,
): string | undefined {
    name = name
        ?.replaceAll(/\\(.)/g, '$1')
        .replaceAll(' ', '_')
        .replaceAll(/[,.]/g, '');
    if (isSimple) name = name?.toUpperCase();
    return name;
}

export function sanitizeMyBlockArgName(name: string): string;
export function sanitizeMyBlockArgName(name?: string): string | undefined;
export function sanitizeMyBlockArgName(name?: string): string | undefined {
    return sanitizeArgName(name, false);
}

export function sanitizeFileValue(name: string): string;
export function sanitizeFileValue(name?: string): string | undefined;
export function sanitizeFileValue(name?: string): string | undefined {
    return name?.replace(/\.ev3p$/, '').replaceAll(' ', '_');
}

export function sanitizeCommentValue(input: any): string {
    // Example: BLACK=1&#xD;was detected previously &#xA;and new line.
    if (typeof input === 'string') {
        return input.replaceAll(/&#x([0-9A-Fa-f]+);/g, ' ');
    } else {
        return input;
    }
}

export function getArgValue(argname: string, value: string) {
    switch (argname) {
        case 'Port': // 1.1 => 1
        case 'MotorPort': // 1.A => A
        case 'Ports': // 1.B+C => B+C
            return value.toString().replace(/^\d+.(.+)/, '$1');
        default:
            return value;
    }
}

export function sanitizeWire(parent: EV3GBlock | undefined, name: string): string;
export function sanitizeWire(
    parent: EV3GBlock | undefined,
    name?: string,
): string | undefined;
export function sanitizeWire(
    parent: EV3GBlock | undefined,
    name?: string,
): string | undefined {
    return `d${sanitizeArgName(parent?.id, false)}${name}`;
}
