import { CodeBlock, CodeConverter } from '../utils/types';
import { DeviceType, OperatorPrecedence, ValueType } from '../utils/enums';
import { _debug, enforceNonEmpty, indent_code } from '../utils/utils';

import { BlockValue } from '../utils/blockvalue';
import { EV3GBlock } from '../project.ev3g/blocks';
import { getCustomTimerPy } from './misc';
import { handleOperators } from './handlers';
import { processOperation } from './operator';
import { sanitizeArgName } from '../project.ev3g/utils';

function control_forever(this: CodeConverter, block: CodeBlock) {
    const sub_code = this.process_stack(block.getSubStack(0), true);
    return ['while True:', ...sub_code];
}

function control_repeat(this: CodeConverter, block: CodeBlock) {
    // sb3: TIMES, ev3g: ITERATIONS_TO_RUN
    const times = block.get(['TIMES', 'ITERATIONS_TO_RUN']);

    // sb3 needs to process subcode, while ev3g does this processing though "child" blocks
    const sub_code = this.context?.deviceTypeIsSB3
        ? this.process_stack(block.getSubStack(0), true)
        : [];

    let loopVariable = '_';
    if (this.context?.deviceType === DeviceType.EV3G) {
        const ev3gblock = block as EV3GBlock;
        if (ev3gblock.generationHelpers?.loopIndexWire) {
            loopVariable = ev3gblock.generationHelpers.loopIndexWire;
        }
    }

    return [
        `for ${loopVariable} in range(${times.ensureNumber(this.context, true).raw}):`,
        ...sub_code,
    ];
}

function control_repeat_until(this: CodeConverter, block: CodeBlock) {
    const condition_block = block.getBlock('CONDITION');
    // wait until block has a logical flaw, needs to have the return value from falsy to truthy
    const condition_value = processOperation.call(this, condition_block, 'True');

    // sb3 needs to process subcode, while ev3g does this processing though "child" blocks
    // const sub_code = this.process_stack(block.getSubStack(0), true);
    const sub_code = this.context?.deviceTypeIsSB3
        ? this.process_stack(block.getSubStack(0), true)
        : [];
    //!!! TODO: check

    return [`while not (${condition_value.raw}):`, ...sub_code];
}

function control_wait(this: CodeConverter, block: CodeBlock) {
    const duration = this.context.helpers
        .use('convert_time')
        // spike,ev3sb: DURATION, ev3g: HOW_LONG
        .call(block.get(['DURATION', 'HOW_LONG']).ensureNumber(this.context, false));

    this.context.imports.use('pybricks.tools', 'wait');
    return [`${this.context.awaitPrefix}wait(${duration.raw})`];
}

function control_if_and_if_else(this: CodeConverter, block: CodeBlock) {
    const retval = [];
    const condition_block = block.getBlock('CONDITION');
    const condition_value = processOperation.call(this, condition_block);

    retval.push(`if ${condition_value.raw}:`);
    if (block.getSubStack(0)) {
        const sub_code = enforceNonEmpty(
            this.process_stack(block.getSubStack(0), true),
        );
        retval.push(...sub_code);
    } else {
        retval.push(...enforceNonEmpty([]));
    }

    if (block.opcode === 'control_if_else') {
        retval.push('else:');
        const sub_code = enforceNonEmpty(
            this.process_stack(block.getSubStack(1), true),
        );
        retval.push(...sub_code);
    }
    return retval;
}

function control_wait_until(this: CodeConverter, block: CodeBlock) {
    const condition_block = block.getBlock('CONDITION');
    /* 'wait until' block has a flaw, needs to have the return value from falsy to truthy */
    const condition_value = processOperation.call(this, condition_block, 'True');

    const sub_code = this.process_stack(null, true); // empty substack -> results in pass

    return [`while not (${condition_value.raw}):`, ...sub_code];
}

function ev3g_ConfigurableWhileLoop(this: CodeConverter, block: CodeBlock) {
    const ev3gblock = block as EV3GBlock;
    const condition_block = block.getBlock('CONDITION');
    switch (condition_block.opcode) {
        case 'StopAfterNumberIterations':
            /* 
               condition shall be unboxed here
               stopcode node contains the condition
               while  block contains the items...
            */
            return control_repeat.call(this, condition_block);
        case 'TimeCompareLoop':
            return [
                `${getCustomTimerPy.call(this, ev3gblock)} = StopWatch()`,
                ...control_repeat_until.call(this, block),
            ];
        default:
            return control_repeat_until.call(this, block);
    }
}

function ev3g_WaitFor(this: CodeConverter, block: CodeBlock) {
    switch (block.opcode) {
        case 'TimeCompare':
            return control_wait.call(this, block);
        default:
            return control_wait_until.call(this, block);
    }
}

function ev3g_PairedConfigurableMethodCall(this: CodeConverter, block: CodeBlock) {
    /* PairedConfigurableMethodCall | children=ConfigurableFlatCaseStructure.Case[] */
    const ev3gblock = block as EV3GBlock;
    const conditionOp = handleOperators
        .call(this, ev3gblock.getBlock('CONDITION'))
        ?.toString();
    const conditionVariable = `condition_${sanitizeArgName(ev3gblock.id, false)}`;
    ev3gblock.generationHelpers.switchConditionVariable = conditionVariable;
    return [
        `if True: # placeholder for switch cases below`,
        indent_code(`${conditionVariable} = ${conditionOp}`)[0],
    ];
}

function ev3g_ConfigurableCaseStructureCase(this: CodeConverter, block: CodeBlock) {
    /* ConfigurableFlatCaseStructure.Case
       gets condition from paired, yet value from Pattern... */
    const ev3gblock = block as EV3GBlock;
    const pattern = ev3gblock.get('PATTERN');
    const pairParent = ev3gblock.generationHelpers.switchPairedParent;
    if (pairParent) {
        const conditionVariable = pairParent.generationHelpers.switchConditionVariable;
        const isFirst = ev3gblock.generationHelpers.switchCaseCounter === 0;
        const isDefault = ev3gblock.generationHelpers.switchCaseIsDefault;
        return [
            !isDefault
                ? `${isFirst ? 'if' : 'elif'} ${conditionVariable} == ${pattern}:`
                : 'else:',
        ];
    }
}

function ev3g_StopNever(this: CodeConverter, block: CodeBlock): BlockValue | undefined {
    return new BlockValue('False', { type: ValueType.BOOLEAN });
}

function ev3g_TimeCompare(this: CodeConverter, block: CodeBlock) {
    const duration = this.context.helpers
        .use('convert_time')
        .call(block.get('HOW_LONG').ensureNumber(this.context, false));

    /* INFO: t = StopWatch() were added at handleBlock */
    return new BlockValue(
        `${getCustomTimerPy.call(this, (block as EV3GBlock).parent)}.time() < ${
            duration.raw
        }`,
        {
            is_dynamic: true,
            type: ValueType.BOOLEAN,
            precedence: OperatorPrecedence.BINARY_COMPARISON,
        },
    );
}

function handleBlock(
    this: CodeConverter,
    block: CodeBlock,
    opcodeOverride?: string,
): string[] | undefined {
    if (this.context.deviceType === DeviceType.EV3G) {
        if ((block as EV3GBlock).hasWaitFor) {
            return ev3g_WaitFor.call(this, block);
        } else if ((block as EV3GBlock).hasSwitch) {
            return ev3g_PairedConfigurableMethodCall.call(this, block);
        }
    }

    switch (opcodeOverride || block.opcode) {
        case 'control_forever':
            return control_forever.call(this, block);
        case 'control_repeat':
            return control_repeat.call(this, block);
        case 'control_repeat_until':
            return control_repeat_until.call(this, block);
        case 'control_wait':
            return control_wait.call(this, block);
        case 'control_if':
        case 'control_if_else':
            return control_if_and_if_else.call(this, block);
        case 'control_wait_until':
            return control_wait_until.call(this, block);
        case 'ConfigurableWhileLoop':
            return ev3g_ConfigurableWhileLoop.call(this, block);
        case 'ConfigurableCaseStructure.Case':
        case 'ConfigurableFlatCaseStructure.Case':
            return ev3g_ConfigurableCaseStructureCase.call(this, block);
    }
}

function handleOperator(this: CodeConverter, block: CodeBlock): BlockValue | undefined {
    switch (block.opcode) {
        case 'TimeCompare' /* Wait.Timer */:
        case 'TimeCompareLoop' /* LoopCondition.Time */:
            return ev3g_TimeCompare.call(this, block);
        case 'StopNever':
            return ev3g_StopNever.call(this, block);
        // case 'StopAfterNumberIterations': // not needed here, handling without an condition/operation block
    }
    return undefined;
}

const handlers = {
    block: handleBlock,
    operator: handleOperator,
};
export default handlers;
