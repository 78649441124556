import { Context } from '../context/context';

export class DeviceBase {
    constructor(protected readonly context: Context) {}

    get devicename(): string | undefined {
        return undefined;
    }

    setupCode(): string[] {
        return [];
    }

    get dependencies(): DeviceBase[] {
        return [];
    }

    ensureDependencies(): void {
        // NOOP
    }

    static createRegistry(context: Context): Map<string, DeviceBase> {
        return new Map<string, DeviceBase>();
    }
}
