import { BlockValue } from '../utils/blockvalue';
import control from './control';
import display from './display';
import misc from './misc';
import motor from './motor';
import motorpair from './motorpair';
import operations from './operator';
import sensor from './sensor';
import sound from './sound';
import variables from './variable';
import { CodeBlock, CodeConverter } from '../utils/types';
import { DeviceType } from '../utils/enums';

export type BlockHandler = (
    block: CodeBlock,
    opcodeOverride?: string,
) => string[] | undefined;
export type OperatorHandler = (
    block: CodeBlock,
    opcodeOverride?: string,
) => BlockValue | undefined;
export interface ModuleHandlersType {
    block: BlockHandler | undefined;
    operator: OperatorHandler | undefined;
}

let _handlers: ModuleHandlersType[];
function _ensureHandlersInitialized() {
    if (!_handlers) {
        _handlers = [
            control,
            display,
            operations,
            variables,
            motor,
            motorpair,
            sensor,
            sound,
            misc,
        ];
    }
    return _handlers;
}

export function handleBlocks(
    this: CodeConverter,
    block: CodeBlock,
    opcodeOverride?: string,
) {
    _ensureHandlersInitialized();
    for (const handler of _handlers) {
        const retval = handler.block?.call(this, block, opcodeOverride);
        if (retval) {
            return retval;
        }
    }

    // in EV3G mode all operators might appear as individual blocks
    if (this.context.deviceType === DeviceType.EV3G) {
        const value = handleOperators.call(this, block, opcodeOverride);
        if (value) {
            return [value.toString()];
        }
    }
}

export function handleOperators(
    this: CodeConverter,
    block: CodeBlock,
    opcodeOverride?: string,
) {
    _ensureHandlersInitialized();
    for (const handler of _handlers) {
        const retval = handler.operator?.call(this, block, opcodeOverride);
        if (retval) {
            return retval;
        }
    }
}
