import { Context } from './context';
import { RegistryManager } from './registrymanager';
import { sanitize } from '../utils/utils';

export class BroadcastRegistryPayload {
    name: string;

    constructor(name: string) {
        this.name = name;
    }

    // Generates the code for the broadcast registry payload
    get_code(functions: string[]): string {
        const sanitizedFunctions = functions.map((func) => sanitize(func));
        return `${this.get_pyname()} = Message([${sanitizedFunctions.join(', ')}])`;
    }

    // Returns the sanitized Python name for the message
    get_pyname(): string {
        return `message_${sanitize(this.name)}`;
    }

    // Creates a new registry manager for the broadcast registry payload
    static createRegistry(context: Context): RegistryManager<BroadcastRegistryPayload> {
        return new RegistryManager(
            context,
            (...args: unknown[]) => new BroadcastRegistryPayload(args[0] as string),
        );
    }
}
