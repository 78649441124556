import { EV3GBlock } from '../project.ev3g/blocks';
import { EV3GProjectToPythonConverter } from '../project.ev3g/ev3g-projectconverter';
import { SB3Block } from '../project.sb3/block';
import { BlockValue, convertValue, num_eval } from '../utils/blockvalue';
import { ValueType } from '../utils/enums';
import { CodeBlock, CodeConverter } from '../utils/types';

function _readParameters(
    this: CodeConverter,
    block: CodeBlock,
    options: {
        variable?: boolean;
        list?: boolean;
        item?: boolean;
        index?: boolean;
        value?: boolean;
    } = {},
) {
    const item = options.item ? block.get('ITEM') : undefined;
    const variable = options.variable
        ? this.context.variables.get([block.get('VARIABLE')?.toString(), false])
        : undefined;
    const list = options.list
        ? this.context.variables.get([block.get('LIST')?.toString(), true])
        : undefined;
    const index = options.index
        ? num_eval(this.context, [(block.get('INDEX'), '-', 1)], true)
        : undefined;
    const value = options.value ? block.get('VALUE') : undefined;

    return { variable, list, index, item, value };
}

function data_setvariableto(this: CodeConverter, block: CodeBlock) {
    const { variable, value } = _readParameters.call(this, block, {
        variable: true,
        value: true,
    });

    if (!variable?.py_setValue) {
        return;
    }
    // here variable value can be string or number, keep it as is
    return [variable.py_setValue(block, BlockValue.raw(value)?.toString() ?? '')];
}

function data_changevariableby(this: CodeConverter, block: CodeBlock) {
    const { variable, value } = _readParameters.call(this, block, {
        variable: true,
        value: true,
    });

    const value2 = value?.options?.is_variable ? num_eval(this.context, value) : value;
    const valuestr = `${
        new BlockValue(variable?.py, {
            is_dynamic: true,
            is_variable: true,
            type: ValueType.STRING, //!! //TODO: based on variable type
        }).ensureNumber(this.context).raw
    } + ${BlockValue.raw(value2)}`;

    if (!variable?.py_setValue) {
        return;
    }
    return [variable?.py_setValue(block, valuestr)];
}

function data_addtolist(this: CodeConverter, block: CodeBlock) {
    const { list, item } = _readParameters.call(this, block, {
        list: true,
        item: true,
    });

    return [`${list?.py}.append(${item?.raw})`];
}

function data_deleteoflist(this: CodeConverter, block: CodeBlock) {
    const { list, index } = _readParameters.call(this, block, {
        list: true,
        index: true,
    });

    return [`del ${list?.py}[${BlockValue.raw(index)}]`];
}

function data_deletealloflist(this: CodeConverter, block: CodeBlock) {
    const { list } = _readParameters.call(this, block, {
        list: true,
    });

    return [`${list?.py}.clear()`];
}

function data_insertatlist(this: CodeConverter, block: CodeBlock) {
    const { list, index, item } = _readParameters.call(this, block, {
        list: true,
        index: true,
        item: true,
    });

    return [`${list?.py}.insert(${BlockValue.raw(index)}, ${item?.raw})`];
}

function data_replaceitemoflist(this: CodeConverter, block: CodeBlock) {
    const { list, index, item } = _readParameters.call(this, block, {
        list: true,
        index: true,
        item: true,
    });

    return [`${list?.py}[${BlockValue.raw(index)}] = ${item?.raw}`];
}

function data_itemoflist(this: CodeConverter, block: CodeBlock) {
    const { list, index } = _readParameters.call(this, block, {
        list: true,
        index: true,
    });

    return new BlockValue(`${list?.py}[${BlockValue.raw(index)}]`, {
        is_dynamic: true,
        type: ValueType.STRING,
    });
}

function data_itemnumoflist(this: CodeConverter, block: CodeBlock) {
    const { list, item } = _readParameters.call(this, block, {
        list: true,
        item: true,
    });

    // TODO: add safe error handling
    return new BlockValue(`${list?.py}.index(${item?.raw}) + 1`, {
        is_dynamic: true,
        type: ValueType.NUMBER,
    });
}

function data_lengthoflist(this: CodeConverter, block: CodeBlock) {
    const { list } = _readParameters.call(this, block, {
        list: true,
    });

    return new BlockValue(`len(${list?.py})`, {
        is_dynamic: true,
        type: ValueType.NUMBER,
    });
}

function data_listcontainsitem(this: CodeConverter, block: CodeBlock) {
    const { list, item } = _readParameters.call(this, block, {
        list: true,
        item: true,
    });

    // TODO: add safe error handling
    return new BlockValue(`(${list?.py}.index(${item?.raw}) != None)`, {
        is_dynamic: true,
        type: ValueType.BOOLEAN,
    });
}

function ev3g_GlobalConst(this: CodeConverter, block: CodeBlock, valuetype: ValueType) {
    // variable assignment output will be added on the level of EV3GProjectToPythonConverter
    const valuein = block.get('VALUEIN');

    // manually override, should we do any conversion?
    if (valuein?.options) valuein.options.type = valuetype;
    return [`${valuein?.raw}`];
}

function ev3g_GlobalGet(this: CodeConverter, block: CodeBlock, valuetype: ValueType) {
    // variable assignment output will be added on the level of EV3GProjectToPythonConverter
    const name = block.get('NAME')?.toString();
    const isarray =
        valuetype === ValueType.NUMBERARRAY || valuetype === ValueType.BOOLEANARRAY;
    const variable = this.context.variables.use([name, isarray]);
    // if (valuein?.options) valuein.options.type = valuetype;

    return [variable.py ?? ''];
}

function ev3g_GlobalSet(this: CodeConverter, block: CodeBlock, valuetype: ValueType) {
    const name = block.get('NAME')?.toString();
    if (!name.toString().length) {
        return ['# skipping assignment due to missing variable name'];
    }

    const targetType = valuetype;
    const isarray =
        targetType === ValueType.NUMBERARRAY || targetType === ValueType.BOOLEANARRAY;
    const variable = this.context.variables.use([name, isarray]);
    let valuein = block.get('VALUEIN');

    //TODO: hoist to the top subfunction, also taking into consideration multiple StartBlocks

    // variable conversion
    valuein = convertValue(this.context, valuein, targetType);

    // here variable value can be string or number, keep it as is
    const root1 = (block as EV3GBlock)?.root;
    if (root1 != undefined) return [variable.py_setValue(root1, valuein?.raw)];
}

function handleBlock(this: CodeConverter, block: CodeBlock): string[] | undefined {
    switch (block.opcode) {
        case 'data_setvariableto':
            return data_setvariableto.call(this, block);
        case 'data_changevariableby':
            return data_changevariableby.call(this, block);
        case 'data_addtolist':
            return data_addtolist.call(this, block);
        case 'data_deleteoflist':
            return data_deleteoflist.call(this, block);
        case 'data_deletealloflist':
            return data_deletealloflist.call(this, block);
        case 'data_insertatlist':
            return data_insertatlist.call(this, block);
        case 'data_replaceitemoflist':
            return data_replaceitemoflist.call(this, block);
        case 'GlobalConstSingle':
            return ev3g_GlobalConst.call(this, block, ValueType.NUMBER);
        case 'GlobalConstString':
            return ev3g_GlobalConst.call(this, block, ValueType.STRING);
        case 'GlobalConstBoolean':
            return ev3g_GlobalConst.call(this, block, ValueType.BOOLEAN);
        case 'GlobalConstNumericArray':
            return ev3g_GlobalConst.call(this, block, ValueType.NUMBERARRAY);
        case 'GlobalConstBooleanArray':
            return ev3g_GlobalConst.call(this, block, ValueType.BOOLEANARRAY);
        case 'GlobalGetSingle':
            return ev3g_GlobalGet.call(this, block, ValueType.NUMBER);
        case 'GlobalGetString':
            return ev3g_GlobalGet.call(this, block, ValueType.STRING);
        case 'GlobalGetBoolean':
            return ev3g_GlobalGet.call(this, block, ValueType.BOOLEAN);
        case 'GlobalGetNumericArray':
            return ev3g_GlobalGet.call(this, block, ValueType.NUMBERARRAY);
        case 'GlobalGetBooleanArray':
            return ev3g_GlobalGet.call(this, block, ValueType.BOOLEANARRAY);
        case 'GlobalSetSingle':
            return ev3g_GlobalSet.call(this, block, ValueType.NUMBER);
        case 'GlobalSetString':
            return ev3g_GlobalSet.call(this, block, ValueType.STRING);
        case 'GlobalSetBoolean':
            return ev3g_GlobalSet.call(this, block, ValueType.BOOLEAN);
        case 'GlobalSetNumericArray':
            return ev3g_GlobalSet.call(this, block, ValueType.NUMBERARRAY);
        case 'GlobalSetBooleanArray':
            return ev3g_GlobalSet.call(this, block, ValueType.BOOLEANARRAY);
    }
}

function handleOperator(this: CodeConverter, block: CodeBlock): BlockValue | undefined {
    switch (block.opcode) {
        case 'data_itemoflist':
            return data_itemoflist.call(this, block);
        case 'data_itemnumoflist':
            return data_itemnumoflist.call(this, block);
        case 'data_lengthoflist':
            return data_lengthoflist.call(this, block);
        case 'data_listcontainsitem':
            return data_listcontainsitem.call(this, block);
    }
}

const handlers = {
    block: handleBlock,
    operator: handleOperator,
};
export default handlers;
