import { BlockValue, num_eval } from '../utils/blockvalue';
import { calc_stop, EV3STOP_MAP } from '../utils/converters';
import { DeviceMotor } from '../device/motor';
import { DeviceType, OperatorPrecedence, ValueType } from '../utils/enums';
import { CodeBlock, CodeConverter } from '../utils/types';
import {
    CONST_AUTO_PORT,
    CONST_DEGREES,
    CONST_ROTATIONS,
    CONST_SECONDS,
    parseBool,
} from '../utils/utils';

function ev3sbTranslateMotorPort(port: string) {
    // 1 => A, 2 => B, ...
    return ['A', 'B', 'C', 'D', 'E', 'F'][parseInt(port) - 1];
}
function ev3gTranslateMotorPort(port: string) {
    // 1.A => A, 1.D => D for motors, and 1.B+C => B+C for motorpairs
    const parsedPort = port.split('.')[1];
    return parsedPort;
}
export function guardPort(blockvalue: BlockValue) {
    if (blockvalue?.options?.is_variable)
        throw 'Cannot handle dynamic port setting yet.';
    return blockvalue;
}
function getMotorPorts(this: CodeConverter, block: CodeBlock): string[] {
    const port = guardPort(block.get(['PORT', 'MOTORPORT']));
    const portName = BlockValue.toString(port);
    const portNames = convertMotorPorts.call(this, portName);
    return portNames;
}
export function convertMotorPorts(this: CodeConverter, portName: string) {
    switch (this.context.deviceType) {
        case DeviceType.EV3CLASSROOM:
            return [ev3sbTranslateMotorPort(portName)];
        case DeviceType.SPIKE:
        case DeviceType.ROBOTINVENTOR:
            return portName.split('') ?? [CONST_AUTO_PORT];
        case DeviceType.EV3G:
            return ev3gTranslateMotorPort(portName).split('+');
        default:
            return [];
    }
}

function motor_motorSetSpeed(
    this: CodeConverter,
    block: CodeBlock,
    isFullMode: boolean,
) {
    const ports = isFullMode ? getMotorPorts.call(this, block) : [CONST_AUTO_PORT];
    const speed = block.get('SPEED');
    const value = this.context.helpers.use('convert_speed')?.call(speed);

    return ports.map((port) => {
        const device = DeviceMotor.instance(this.context, port);
        return `${device.default_speed_variable} = ${value.raw}`;
    });
}

function flippermoremotor_motorSetStopMethod(this: CodeConverter, block: CodeBlock) {
    const ports = getMotorPorts.call(this, block);
    const stop_then = (() => {
        if (this.context.deviceTypeIsSPIKERI) {
            const stopValue = parseInt(block.get('STOP').toString()); // hold=2, break=1, coast=0
            return calc_stop(this.context, stopValue);
        } else if (this.context.deviceType === DeviceType.EV3CLASSROOM) {
            const option = block.get('OPTION')?.toInt();
            return EV3STOP_MAP.get(option);
        }
    })();

    return ports.map((port) => {
        const device = DeviceMotor.instance(this.context, port);
        const d = device.devicename;
        device.default_then = stop_then;
        return `# setting ${d} stop at end to ${stop_then}`;
    });
}

function horizontalmotor_motorTurnRotations(
    this: CodeConverter,
    block: CodeBlock,
    direction_mul: 1 | -1,
) {
    const rotations = block.get('ROTATIONS');

    return _flippermotor_motorTurn.call(
        this,
        block,
        CONST_AUTO_PORT,
        rotations,
        direction_mul,
        new BlockValue(CONST_ROTATIONS, { type: ValueType.STRING }),
    );
}

function flippermotor_motorTurnForDirection(this: CodeConverter, block: CodeBlock) {
    const ports = getMotorPorts.call(this, block);

    //TODO handle multiple motor mode
    const direction_mul = block.get('DIRECTION').value === 'clockwise' ? +1 : -1;
    const value = block.get('VALUE');
    const unit = block.get('UNIT'); // rotations, degrees, seconds

    return ports
        .map((port) =>
            _flippermotor_motorTurn.call(this, block, port, value, direction_mul, unit),
        )
        .flat();
}

function ev3motor_motorTurnForSpeed(this: CodeConverter, block: CodeBlock) {
    const ports = getMotorPorts.call(this, block);

    const speed = block.get('SPEED');
    const value = block.get('VALUE');
    const unit = block.get('UNIT');

    return ports
        .map((port) =>
            _flippermotor_motorTurn.call(this, block, port, value, +1, unit, speed),
        )
        .flat();
}

function ev3g_MediumMotorRun(
    this: CodeConverter,
    block: CodeBlock,
    valueField: string,
    unitStr: string,
) {
    const port = getMotorPorts.call(this, block).toString();

    const speed = block.get('SPEED');
    const value = block.get(valueField);
    const then = parseBool(block.get('BRAKE_AT_END')?.toString())
        ? 'Stop.HOLD'
        : undefined;
    const unit = new BlockValue(unitStr, { type: ValueType.STRING });

    return _flippermotor_motorTurn.call(this, block, port, value, 1, unit, speed, then);
}

function _flippermotor_motorTurn(
    this: CodeConverter,
    _: CodeBlock,
    port: string,
    value: BlockValue,
    direction_multiplier: 1 | -1,
    unit: BlockValue,
    speed?: BlockValue,
    then?: string,
): string[] {
    value = value.ensureNumber(this.context);

    const device = DeviceMotor.instance(this.context, port);
    const d = device.devicename;
    const postfix_then =
        then ?? device.get_then() ? `, ${then ?? device.get_then()}` : '';
    const speedRaw = speed
        ? this.context.helpers.use('convert_speed')?.call(speed).raw
        : device.default_speed_variable;
    if (unit.value === CONST_ROTATIONS || unit.value === CONST_DEGREES) {
        const value2 = num_eval(this.context, [
            [direction_multiplier * (unit.value === CONST_ROTATIONS ? 360 : 1)],
            '*',
            value.ensureNumber(this.context),
        ]);
        return [
            `${this.context.awaitPrefix}${d}.run_angle(${speedRaw}, ${value2?.raw}${postfix_then})`,
        ];
    } else if (unit.value === CONST_SECONDS) {
        const value_adjusted = this.context.helpers.use('convert_time')?.call(value);
        return [
            `${this.context.awaitPrefix}${d}.run_time(${
                direction_multiplier > 0 ? '' : '-'
            }${speedRaw}, ${value_adjusted.raw}${postfix_then})`,
        ];
    } else {
        return [];
    }
}

function flippermotor_motorGoDirectionToPosition(
    this: CodeConverter,
    block: CodeBlock,
) {
    const ports = getMotorPorts.call(this, block);
    const position = block.get('POSITION');
    const direction = block.get('DIRECTION').value; // clockwise, counterclockwise, shortest

    return ports
        .map((port) => {
            const retval: string[] = [];
            const device = DeviceMotor.instance(this.context, port);
            const d = device.devicename;
            if (direction === 'shortest') {
                // NOOP
            } else if (direction === 'clockwise') {
                const rotation_angle = `(${position.value} - ${d}.angle()) % 360`;
                retval.push(
                    `${this.context.awaitPrefix}${d}.run_angle(${device.default_speed_variable}, ${rotation_angle}, Stop.NONE)`,
                );
            } else if (direction === 'counterclockwise') {
                const rotation_angle = `-(360 - (${position.value} - ${d}.angle() % 360))`;
                retval.push(
                    `${this.context.awaitPrefix}${d}.run_angle(${device.default_speed_variable}, ${rotation_angle}, Stop.NONE)`,
                );
            }
            const postfix_then = device.get_then() ? `, ${device.get_then()}` : '';
            const value = num_eval(this.context, [
                position.ensureNumber(this.context),
                '%',
                360,
            ]);
            retval.push(
                `${this.context.awaitPrefix}${d}.run_target(${device.default_speed_variable}, ${value?.raw}${postfix_then})`,
            );

            return retval;
        })
        .flat();
}

function motor_motorStop(this: CodeConverter, block: CodeBlock) {
    const ports = getMotorPorts.call(this, block);

    return ports.map((port) => {
        const device = DeviceMotor.instance(this.context, port);
        const d = device.devicename;
        const then: string | undefined =
            device.get_then() ??
            (parseBool(block.get('BRAKE_AT_END')?.toString())
                ? 'Stop.HOLD'
                : undefined);

        switch (then) {
            case 'Stop.HOLD':
                return `${d}.hold()`;
            case 'Stop.COAST':
                return `${d}.stop()`;
            case 'Stop.BRAKE':
            default:
                return `${d}.brake()`;
        }
    });
}

function flippermotor_motorStartDirection(this: CodeConverter, block: CodeBlock) {
    const ports = getMotorPorts.call(this, block);
    const direction = block.get('DIRECTION');
    const direction_sign = !direction || direction.value === 'clockwise' ? '' : '-';

    return ports.map((port) => {
        const device = DeviceMotor.instance(this.context, port);
        const d = device.devicename;
        const speed = block.get('SPEED')?.raw ?? device.default_speed_variable;
        return `${d}.run(${direction_sign}${speed})`;
    });
}

function flippermoremotor_motorStartPower(this: CodeConverter, block: CodeBlock) {
    const ports = getMotorPorts.call(this, block);
    const power = block.get('POWER');

    return ports.map((port) => {
        const device = DeviceMotor.instance(this.context, port);
        const d = device.devicename;
        return `${d}.dc(${power.raw})`;
    });
}

function flippermoremotor_motorSetDegreeCounted(this: CodeConverter, block: CodeBlock) {
    const ports = getMotorPorts.call(this, block);
    const value = block.get('VALUE');

    // NOTE: this sets/reset both absolute and relative position, that is not the intended outcome
    // after this absolute_position will be invalid, but still we go on with this comporomse now

    return ports.map((port) => {
        const device = DeviceMotor.instance(this.context, port);
        const d = device.devicename;
        return `${d}.reset_angle(${value ? value.raw : 0})`;
    });
}

function _getMotorPosition(this: CodeConverter, block: CodeBlock) {
    const ports = getMotorPorts.call(this, block);

    const port = ports[0];
    const device = DeviceMotor.instance(this.context, port);
    const d = device.devicename;
    return this.context.helpers.use('relative_position').call(d);
}

function flippermoremotor_position(this: CodeConverter, block: CodeBlock) {
    return _getMotorPosition.call(this, block);
}

function ev3g_RotationValueRotations(this: CodeConverter, block: CodeBlock) {
    return num_eval(this.context, [_getMotorPosition.call(this, block), '/', 360]);
}

function flippermotor_absolutePosition(this: CodeConverter, block: CodeBlock) {
    const ports = getMotorPorts.call(this, block);
    const port = ports[0];

    const device = DeviceMotor.instance(this.context, port);
    const d = device.devicename;
    return new BlockValue(`${d}.angle()`, {
        is_dynamic: true,
        type: ValueType.NUMBER,
        precedence: OperatorPrecedence.SIMPLE,
    });
}

function flippermotor_speed(this: CodeConverter, block: CodeBlock) {
    const ports = getMotorPorts.call(this, block);
    const port = ports[0];

    const device = DeviceMotor.instance(this.context, port);
    const d = device.devicename;

    return this.context.helpers.use('convert_speed_back')?.call(
        new BlockValue(`${d}.speed()`, {
            is_dynamic: true,
            type: ValueType.NUMBER,
            precedence: OperatorPrecedence.SIMPLE,
        }),
    );
}

function flippermoremotor_power(this: CodeConverter, block: CodeBlock) {
    const ports = getMotorPorts.call(this, block);
    const port = ports[0];

    const device = DeviceMotor.instance(this.context, port);
    const d = device.devicename;
    return new BlockValue(`${d}.load()`, {
        is_dynamic: true,
        type: ValueType.NUMBER,
        precedence: OperatorPrecedence.SIMPLE,
    });
}

function handleBlock(this: CodeConverter, block: CodeBlock): string[] | undefined {
    switch (block.opcode) {
        case 'flippermotor_motorSetSpeed':
        case 'ev3motor_motorSetSpeed':
            return motor_motorSetSpeed.call(this, block, true);
        case 'horizontalmotor_motorSetSpeed':
            return motor_motorSetSpeed.call(this, block, false);
        case 'flippermotor_motorStartDirection':
        case 'ev3motor_motorStart':
        case 'MotorUnlimited':
        case 'MediumMotorUnlimited':
            return flippermotor_motorStartDirection.call(this, block);
        case 'flippermotor_motorStop':
        case 'horizontalmotor_motorStop':
        case 'ev3motor_motorStop':
        case 'MotorStop':
        case 'MediumMotorStop':
            return motor_motorStop.call(this, block);
        case 'flippermotor_motorGoDirectionToPosition':
            return flippermotor_motorGoDirectionToPosition.call(this, block);
        case 'flippermotor_motorTurnForDirection':
        case 'ev3motor_motorTurnFor':
            return flippermotor_motorTurnForDirection.call(this, block);
        case 'MotorDistance':
        case 'MediumMotorDistance':
            return ev3g_MediumMotorRun.call(this, block, 'DEGREES', CONST_DEGREES);
        case 'MotorTime':
        case 'MediumMotorTime':
            return ev3g_MediumMotorRun.call(this, block, 'SECONDS', CONST_SECONDS);
        case 'MotorDistanceRotations':
        case 'MediumMotorDistanceRotations':
            return ev3g_MediumMotorRun.call(this, block, 'ROTATIONS', CONST_ROTATIONS);
        case 'ev3motor_motorTurnForSpeed':
            return ev3motor_motorTurnForSpeed.call(this, block);
        case 'horizontalmotor_motorTurnClockwiseRotations':
            return horizontalmotor_motorTurnRotations.call(this, block, +1);
        case 'horizontalmotor_motorTurnCounterClockwiseRotations':
            return horizontalmotor_motorTurnRotations.call(this, block, -1);
        case 'flippermoremotor_motorSetStopMethod':
        case 'ev3motor_motorSetStopAction':
            return flippermoremotor_motorSetStopMethod.call(this, block);
        case 'flippermoremotor_motorStartPower':
        case 'ev3motor_motorStartPower':
            return flippermoremotor_motorStartPower.call(this, block);
        case 'flippermoremotor_motorSetDegreeCounted':
        case 'ev3motor_motorReset':
            return flippermoremotor_motorSetDegreeCounted.call(this, block);
    }
}

function handleOperator(this: CodeConverter, block: CodeBlock): BlockValue | undefined {
    switch (block.opcode) {
        case 'flippermoremotor_position':
        case 'ev3motor_position':
        case 'RotationValue':
            return flippermoremotor_position.call(this, block);
        case 'RotationValueRotations':
            return ev3g_RotationValueRotations.call(this, block);
        case 'flippermotor_absolutePosition':
            return flippermotor_absolutePosition.call(this, block);
        case 'flippermotor_speed':
        case 'ev3motor_speed':
            return flippermotor_speed.call(this, block);
        case 'flippermoremotor_power':
            return flippermoremotor_power.call(this, block);
    }
}

const handlers = {
    block: handleBlock,
    operator: handleOperator,
};
export default handlers;
