import { CodeBlock, CodeConverter } from './utils/types';
import PyConverterOptions, { PyProjectResult } from './pyconverteroptions';
import { getFileExtension, get_divider, indent_code } from './utils/utils';

import { DeviceOnPort } from './device/deviceportbase';
import { EV3GProjectToPythonConverter } from './project.ev3g/ev3g-projectconverter';
import { HelperEnabledRegistryPayload } from './context/helpers';
import { ImportRegistryPayload } from './context/imports';
import { SB3ProjectToPythonConverter } from './project.sb3/sb3-projectconverter';
import { VariableRegistryPayload } from './context/variables';

export async function convertProjectToPython(
    filedata: ArrayBuffer | Buffer,
    options: PyConverterOptions,
): Promise<PyProjectResult> {
    const extension = getFileExtension(options.filename);

    if (SB3ProjectToPythonConverter.supportsExtension(extension)) {
        const converter = new SB3ProjectToPythonConverter(options);
        return await converter.convert(filedata, options);
    } else if (EV3GProjectToPythonConverter.supportsExtension(extension)) {
        const converter = new EV3GProjectToPythonConverter(options);
        return await converter.convert(filedata);
    }

    throw new Error('Unsupported file type');
}

export function buildPyCode(
    this: CodeConverter,
    options: PyConverterOptions,
    programCode: string[],
    mainProgramCode: string[],
) {
    let pycode: string;

    const setupCode = createSetupCodes.call(this);
    const globalVariablesCode = VariableRegistryPayload.to_global_code(
        this.context.variables,
    );
    const helperCode = HelperEnabledRegistryPayload.to_global_code(
        this.context.helpers,
    );
    // import needs to be done after everything else as e.g.setup brings new import dependencies
    const importsCode = ImportRegistryPayload.to_global_code(this.context.imports);

    const codeSections: { name: string; code?: string[]; skip?: boolean }[] = [
        {
            name: 'imports',
            code: importsCode,
            skip: options?.debug?.skipImports,
        },
        {
            name: 'helper functions',
            code: helperCode,
            skip: options?.debug?.skipHelpers,
        },
        {
            name: 'setup',
            code: setupCode,
            skip: options?.debug?.skipSetup,
        },
        {
            name: 'global variables',
            code: globalVariablesCode,
            skip: options?.debug?.skipVariableDeclarations,
        },
        { name: 'program code', code: programCode },
        { name: 'main code', code: mainProgramCode },
    ];

    const retval2 = codeSections
        .filter((curr) => !curr.skip)
        .map((curr) =>
            curr.code?.length
                ? [
                      get_divider(`SECTION: ${curr.name.toUpperCase()}`, 'region', '='),
                      ...curr.code,
                      '#endregion',
                  ].join('\r\n')
                : null,
        );

    pycode = retval2.filter((e) => e).join('\r\n\r\n');
    return pycode;
}

function createSetupCodes(this: CodeConverter) {
    const setupCodes: string[] = [];
    setupCodes.push('hub = PrimeHub()');
    this.context.imports.use('pybricks.hubs', 'PrimeHub');

    // ensure dependencies
    for (const elem of this.context.devicesRegistry.values()) {
        elem.ensureDependencies();
    }

    // process and add all devices
    const remainingItems = [...this.context.devicesRegistry.values()];

    // anything that is connected to a port
    Array.from(
        remainingItems.filter(
            (elem): elem is DeviceOnPort => elem instanceof DeviceOnPort,
        ),
    )
        .sort((a: DeviceOnPort, b: DeviceOnPort) =>
            a.portString.localeCompare(b.portString),
        )
        .forEach((elem) => {
            const code = elem.setupCode();
            if (code) {
                setupCodes.push(...code);
            }

            const idx = remainingItems.indexOf(elem);
            remainingItems.splice(idx, 1);
        });

    // any remaining items
    remainingItems
        .sort((a, b) => a.devicename?.localeCompare(b?.devicename ?? '') ?? 0)
        .forEach((elem) => {
            const code = elem.setupCode();
            if (code) {
                setupCodes.push(...code);
            }
        });

    // add default speeds
    if (this.context.deviceDefaultSpeeds.size) {
        const default_speeds = Array.from(
            this.context.deviceDefaultSpeeds.entries(),
        ).map(([devicename, speed]) => `${devicename}: ${speed}`);
        setupCodes.push(`default_speeds = {${default_speeds.join(', ')}}`);
    }

    return setupCodes;
}

export function generatePyFunctionHead(
    this: CodeConverter,
    funcSignature: string,
    currentStack: CodeBlock[],
) {
    const code = [] as string[];

    code.push(`${this.context.isAsyncNeeded ? 'async def' : 'def'} ${funcSignature}:`);

    // list global variables with write access
    const variablesForWriteAccess = currentStack.reduce((aggr, curr) => {
        curr.variablesForWriteAccess.forEach((elem) => aggr.add(elem));
        return aggr;
    }, new Set<VariableRegistryPayload>());

    if (variablesForWriteAccess.size) {
        code.push(
            ...indent_code(
                `global ${Array.from(variablesForWriteAccess)
                    .map((value) => value.py)
                    .join(', ')}`,
            ),
        );
    }
    return code;
}
