export namespace EV3GRaw {
    export interface SourceFile {
        Version: string;
        Namespace: Namespace;
    }

    export interface Namespace {
        Name: string;
        VirtualInstrument: VirtualInstrument;
    }

    export interface VirtualInstrument {
        IsTopLevel: boolean;
        IsReentrant: boolean;
        Version: string;
        OverridingModelDefinitionType: string;
        FrontPanel: FrontPanel;
        BlockDiagram: BlockDiagram;
    }

    export interface FrontPanel {
        Width: number;
        Height: number;
    }

    export interface BlockDiagram {
        Name: string;
        Children?: BlockDiagramChild[]; // Array of children
    }

    export type BlockDiagramChild =
        | Block
        | Wire
        | 'ConfigurableWhileLoop.BuiltInMethod';

    export type Block =
        | StartBlock
        | ConfigurableMethodCall
        | ConfigurableWhileLoop
        | ConfigurableWaitFor
        | ConfigurableCaseStructure
        | ConfigurableFlatCaseStructure
        | ConfigurableCaseStructureCase
        | ConfigurableFlatCaseStructureCase
        | PairedConfigurableMethodCall
        | Comment
        | ConfigurableMegaAccessor
        | ConfigurableWhileLoopConfigurableLoopTunnel
        | ConfigurableCaseStructureConfigurableCaseTunnel;

    export const BLOCK_NAMES = [
        'StartBlock',
        'ConfigurableMethodCall',
        'ConfigurableWhileLoop',
        'ConfigurableWaitFor',
        'ConfigurableCaseStructure',
        'ConfigurableFlatCaseStructure',
        'ConfigurableCaseStructure.Case',
        'ConfigurableFlatCaseStructure.Case',
        'PairedConfigurableMethodCall',
        'Comment',
        'ConfigurableMegaAccessor',
        'Wire',
        'ConfigurableWhileLoop.ConfigurableLoopTunnel',
        'ConfigurableCaseStructure.ConfigurableLoopTunnel',
        'ConfigurableCaseStructure.ConfigurableCaseTunnel',
    ];

    export interface BlockBase {
        Id: string;
        Bounds?: string;
        Target: string;
        '@Tag': string;
        Terminal?: Terminal[];
        ConfigurableMethodTerminal?: ConfigurableMethodTerminal[];
        ConfigurableMegaAccessor?: ConfigurableMegaAccessor[];
        Wire?: Wire[];
        [key: string]: any;
    }

    export interface StartBlock extends BlockBase {
        '@Tag': 'StartBlock';
    }

    export interface ConfigurableMethodCall extends BlockBase {
        '@Tag': 'ConfigurableMethodCall';
    }

    export interface ConfigurableMegaAccessor extends BlockBase {
        '@Tag': 'ConfigurableMegaAccessor';
        AccessorType: 'Input' | 'Output';
    }

    export interface ConfigurableWaitFor extends BlockBase {
        '@Tag': 'ConfigurableWaitFor';
    }

    export interface ConfigurableWhileLoop extends BlockBase {
        '@Tag': 'ConfigurableWhileLoop';
        InterruptName: string;
        'ConfigurableWhileLoop.BuiltInMethod': ConfigurableWhileLoopBuiltInMethod[];
    }

    export interface ConfigurableCaseStructure extends BlockBase {
        '@Tag': 'ConfigurableCaseStructure';
        'ConfigurableCaseStructure.Case': ConfigurableCaseStructureCase[];
        Default: string;
        PairedConfigurableMethodCall: PairedConfigurableMethodCall[];
    }

    export interface ConfigurableFlatCaseStructure extends BlockBase {
        '@Tag': 'ConfigurableFlatCaseStructure';
        'ConfigurableCaseStructure.Case': ConfigurableFlatCaseStructureCase[];
        Default: string;
        PairedConfigurableMethodCall: PairedConfigurableMethodCall[];
    }

    export interface Wire {
        Id: string;
        Joints: string;
    }

    export interface ConfigurableWhileLoopBuiltInMethod {
        CallType: string;
        ConfigurableMethodCall: ConfigurableMethodCall[];
    }

    export interface ConfigurableCaseStructureCase extends BlockBase {
        '@Tag': 'ConfigurableCaseStructure.Case';
        Pattern: string;
        SequenceNode: SequenceNode[];
        ConfigurableMethodCall: ConfigurableMethodCall[];
    }

    export interface ConfigurableFlatCaseStructureCase extends BlockBase {
        '@Tag': 'ConfigurableFlatCaseStructure.Case';
        Pattern: string;
        SequenceNode: SequenceNode[];
        ConfigurableMethodCall: ConfigurableMethodCall[];
    }

    export interface PairedConfigurableMethodCall extends BlockBase {
        '@Tag': 'PairedConfigurableMethodCall';
        PairedStructure: string;
    }

    export interface ConfigurableWhileLoopConfigurableLoopTunnel extends BlockBase {
        '@Tag': 'ConfigurableWhileLoop.ConfigurableLoopTunnel';
        Terminals: string;
    }

    export interface ConfigurableCaseStructureConfigurableCaseTunnel extends BlockBase {
        '@Tag': 'ConfigurableCaseStructure.ConfigurableCaseTunnel';
        Terminals: string;
    }
    export interface ConfigurableMethodTerminal {
        ConfiguredValue: string;
        Terminal: Terminal[];
    }

    export interface Comment extends BlockBase {
        Bounds: string;
        Content: string[];
    }

    export interface SequenceNode {
        Id: string;
        Terminal: Terminal[];
    }

    export interface Terminal {
        Id: string;
        Direction: string;
        DataType: string;
        Hotspot: string;
        Bounds: string;
        Wire?: string; // Optional, as it may not be present in every Terminal
    }
}
