import { EV3GBlock } from '../project.ev3g/blocks';
import { sanitizeArgName, sanitizeBlockName } from '../project.ev3g/utils';
import { SB3Block } from '../project.sb3/block';
import { BlockValue } from '../utils/blockvalue';
import { DeviceType, OperatorPrecedence, ValueType } from '../utils/enums';
import { BlockMatchError, CodeBlock, CodeConverter } from '../utils/types';
import { processOperation } from './operator';
import { indent_code } from '../utils/utils';

export function getMainTimerPy(this: CodeConverter) {
    this.context.imports.use('pybricks.tools', 'StopWatch');
    this.context.variables.use('sw_main', 'StopWatch()');
    return this.context.variables.get('sw_main')?.py;
}

export function getCustomTimerPy(this: CodeConverter, block?: EV3GBlock) {
    this.context.imports.use('pybricks.tools', 'StopWatch');
    return `sw_${sanitizeArgName(block?.id, false)}`;
}

function flippersensors_resetTimer(this: CodeConverter, _: CodeBlock) {
    return [`${getMainTimerPy.call(this)}.reset()`];
}

function flipperevents_whenTimer(this: CodeConverter, block: CodeBlock) {
    const value = this.context.helpers.use('convert_time')?.call(block.get('VALUE'));

    return new BlockValue(`${getMainTimerPy.call(this)}.time() > ${value.raw}`, {
        is_dynamic: true,
        precedence: OperatorPrecedence.BINARY_COMPARISON,
        type: ValueType.BOOLEAN,
    });
}

function flippersensors_timer(this: CodeConverter, _block: CodeBlock) {
    const value = new BlockValue(`${getMainTimerPy.call(this)}.time()`, {
        is_dynamic: true,
        type: ValueType.NUMBER,
    });

    return this.context.helpers.use('convert_time_back')?.call(value);
}

function flippercontrol_fork(this: CodeConverter, block: CodeBlock) {
    function create_substack_fn(
        this: CodeConverter,
        count: number,
        stack: CodeBlock[],
    ) {
        const sub_code = this.process_stack(stack, true);
        const substack_fn = `substack${count}_fn`;
        return [substack_fn, [`def ${substack_fn}():`, ...sub_code]];
    }

    const [sub_elem_fn1, sub_elem_code1] = create_substack_fn.call(
        this,
        1,
        block.getSubStack(0),
    );
    const [sub_elem_fn2, sub_elem_code2] = create_substack_fn.call(
        this,
        2,
        block.getSubStack(1),
    );

    return [
        ...sub_elem_code1,
        ...sub_elem_code2,
        `multitask(${sub_elem_fn1}, ${sub_elem_fn2})`,
    ];
}

function event_broadcast(this: CodeConverter, block: CodeBlock, do_wait: boolean) {
    if (!this.context?.deviceTypeIsSB3) {
        return undefined;
    }

    const message_id = (block as SB3Block).getInputAsShadowId('BROADCAST_INPUT');
    const message_pyname = this.context.broadcasts.get(message_id)?.get_pyname();

    return [
        `${this.context.awaitPrefix}${message_pyname}.broadcast_exec(${
            do_wait ? 'True' : 'False'
        })`,
    ];
}

function horizontalevents_broadcast(this: CodeConverter, block: CodeBlock) {
    const message_id = block.get('CHOICE')?.toString();
    const message_pyname = this.context.broadcasts
        .use(message_id, message_id)
        .get_pyname();

    return [`${this.context.awaitPrefix}${message_pyname}.broadcast_exec(False)`];
}

function procedures_call(this: CodeConverter, block: CodeBlock) {
    if (!this.context?.deviceTypeIsSB3) {
        return undefined;
    }

    const proccode = (block as SB3Block)._block?.mutation?.proccode;
    const procdef = this.context.procedures.get(proccode);

    function defaultValueForType(type: string) {
        if (type === 'string') {
            return new BlockValue('', { type: ValueType.STRING });
        } else if (type === 'boolean') {
            return new BlockValue('False', { type: ValueType.BOOLEAN });
        }
        throw new Error(`Unknown type ${type}`);
    }

    const args = procdef
        ? [...procdef.args.values()].map((aarg) => {
              try {
                  const item = block.get(aarg.id);
                  return item ?? defaultValueForType(aarg.type);
              } catch (e) {
                  if (e instanceof BlockMatchError) {
                      const block2 = block.getBlock(aarg.id);
                      return processOperation.call(this, block2);
                  } else {
                      throw e;
                  }
              }
          })
        : undefined;

    return [
        `${this.context.awaitPrefix}${procdef?.name}(${args
            ?.map(BlockValue.raw)
            .join(', ')})`,
    ];
}

// function radiobroadcast_broadcastRadioSignalWithValueCommand(this: ContextHolder, block: CodeBlock) {
//   const signal = block.get('SIGNAL');
//   const value = block.get('VALUE');

//   return [`hub.ble.broadcast(${signal.raw}+'|'+${value.raw})`];
// }

// function radiobroadcast_whenIReceiveRadioSignalHat(this: ContextHolder, block: CodeBlock) {
//   const signal = block.get('SIGNAL');
//   return new BlockValue(`hub.ble.observe(1) == ${signal.raw}`, {
//     is_dynamic: true,
//     type: ValueType.BOOLEAN,
//   });
// }

function flipperevents_condition(this: CodeConverter, block: CodeBlock) {
    return new BlockValue('True', {
        is_dynamic: true,
        type: ValueType.BOOLEAN,
    });
}

function flippermoresensors_angularVelocity(this: CodeConverter, block: CodeBlock) {
    const axis = block.get('AXIS')?.toString().toUpperCase();
    this.context.imports.use('pybricks.parameters', 'Axis');
    return new BlockValue(`hub.imu.angular_velocity(Axis.${axis})`, {
        is_dynamic: true,
        type: ValueType.NUMBER,
    });
}

function flippermoresensors_acceleration(this: CodeConverter, block: CodeBlock) {
    const axis = block.get('AXIS')?.toString().toUpperCase();
    this.context.imports.use('pybricks.parameters', 'Axis');
    return new BlockValue(`hub.imu.acceleration(Axis.${axis})`, {
        is_dynamic: true,
        type: ValueType.NUMBER,
    });
}

function ev3control_stop(this: CodeConverter, block: CodeBlock) {
    return ['raise SystemExit'];
}

function ev3g_CommentBlock(this: CodeConverter, block: CodeBlock) {
    const comment = block.get('COMMENT')?.toString();
    return [`# ${comment}`];
}

function ev3g_ToggleInterrupt(this: CodeConverter, block: CodeBlock) {
    // ToggleInterrupt(INTERRUPTVALUE: 0, INTERRUPTNAME: LoopName)
    const interrupt = block.get('INTERRUPTVALUE')?.toString();

    // TODO: check if closest loop is the correct one (ConfigurableWhileLoop.InterruptName)
    return [`break`];
}

function ev3g_ForkParent(this: CodeConverter, block: CodeBlock) {
    const forkparentfn = `_fork_parent_${sanitizeArgName(block.id, false)}`;
    const retval = [`async def ${forkparentfn}():`];
    const forkfns = [];
    let idx = 0;
    while (true) {
        const forkitem = block.getBlock(`FORKITEM${idx}`);
        if (!forkitem) break;
        idx += 1;

        const stack = forkitem.getSubStack(0);
        const code = stack.map((elem) => this.process_stack([elem], true)).flat();

        const forkfn = `_fork_item_${sanitizeArgName(stack[0]?.id, false)}`;
        forkfns.push(forkfn);
        retval.push(...indent_code([`async def ${forkfn}():`, ...code]));
    }
    retval.push(
        ...indent_code(
            'await multitask(' + forkfns.map((fn) => `${fn}()`).join(', ') + ')',
        ),
        `await ${forkparentfn}()`,
    );
    return retval;
}

function handleBlock(this: CodeConverter, block: CodeBlock): string[] | undefined {
    switch (block.opcode) {
        case 'flippersensors_resetTimer':
        case 'ev3sensors_resetTimer':
            return flippersensors_resetTimer.call(this, block);
        case 'flippercontrol_fork':
            return flippercontrol_fork.call(this, block);
        case 'event_broadcast':
            return event_broadcast.call(this, block, false);
        case 'event_broadcastandwait':
            return event_broadcast.call(this, block, true);
        case 'horizontalevents_broadcast':
            return horizontalevents_broadcast.call(this, block);
        case 'procedures_call':
            return procedures_call.call(this, block);
        // case 'radiobroadcast_broadcastRadioSignalWithValueCommand':
        //   return radiobroadcast_broadcastRadioSignalWithValueCommand(block);
        case 'ev3control_stop':
            return ev3control_stop.call(this, block);
        case 'CommentBlock':
            return ev3g_CommentBlock.call(this, block);
        case 'ToggleInterrupt':
            return ev3g_ToggleInterrupt.call(this, block);
        case '_ForkParent':
            return ev3g_ForkParent.call(this, block);
    }

    if (this.context.deviceType === DeviceType.EV3G) {
        // procedure call
        if (block.opcode.endsWith('.ev3p')) {
            const ev3gblock = block as EV3GBlock;
            const procName = block.opcode.replace('.ev3p', '');
            const args = [...ev3gblock.inputs.values()]
                .map((input) => input.toString())
                .join(', ');
            return [`${procName}(${args})`];
        }
    }
}

function handleOperator(this: CodeConverter, block: CodeBlock): BlockValue | undefined {
    switch (block.opcode) {
        case 'flipperevents_whenProgramStarts':
        case 'flipperhorizontalevents_when':
        case 'ev3events_whenProgramStarts':
            return flipperevents_condition.call(this, block);
        case 'flippermoresensors_angularVelocity':
            return flippermoresensors_angularVelocity.call(this, block);
        case 'flippermoresensors_acceleration':
            return flippermoresensors_acceleration.call(this, block);
        // case 'radiobroadcast_whenIReceiveRadioSignalHat':
        //   return radiobroadcast_whenIReceiveRadioSignalHat(block);
        case 'flippersensors_timer':
        case 'ev3sensors_timer':
            return flippersensors_timer.call(this, block);
        case 'flipperevents_whenTimer':
        case 'ev3events_whenTimer':
            return flipperevents_whenTimer.call(this, block);
    }
}

const handlers = {
    block: handleBlock,
    operator: handleOperator,
};
export default handlers;
