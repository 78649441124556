import { BlockValue } from './blockvalue';
import { Context } from '../context/context';

enum FLIPPERSTOP {
    COAST = 0,
    BRAKE = 1,
    HOLD = 2,
}

const FLIPPERSTOP_MAP = new Map([
    [FLIPPERSTOP.COAST, 'Stop.COAST'],
    [FLIPPERSTOP.BRAKE, 'Stop.BRAKE'],
    [FLIPPERSTOP.HOLD, 'Stop.HOLD'],
]);

export enum EV3STOP {
    COAST = 0,
    HOLD = 1,
}

export const EV3STOP_MAP = new Map([
    [EV3STOP.COAST, 'Stop.COAST'],
    [EV3STOP.HOLD, 'Stop.HOLD'],
]);

enum FLIPPERHUBORIENTATION {
    FRONT = 0,
    BACK = 1,
    TOP = 2,
    BOTTOM = 3,
    LEFT = 4,
    RIGHT = 5,
}

export const FLIPPERHUBORIENTATION_MAP = new Map([
    [FLIPPERHUBORIENTATION.FRONT, 'Side.FRONT'],
    [FLIPPERHUBORIENTATION.BACK, 'Side.BACK'],
    [FLIPPERHUBORIENTATION.TOP, 'Side.TOP'],
    [FLIPPERHUBORIENTATION.BOTTOM, 'Side.BOTTOM'],
    [FLIPPERHUBORIENTATION.LEFT, 'Side.LEFT'],
    [FLIPPERHUBORIENTATION.RIGHT, 'Side.RIGHT'],
]);

enum FLIPPERDISPLAYORIENTATION {
    TOP = 1,
    LEFT = 2,
    RIGHT = 3,
    BOTTOM = 4,
}

export const FLIPPERDISPLAYORIENTATION_MAP = new Map([
    [FLIPPERDISPLAYORIENTATION.TOP, 'Side.TOP'],
    [FLIPPERDISPLAYORIENTATION.LEFT, 'Side.LEFT'],
    [FLIPPERDISPLAYORIENTATION.RIGHT, 'Side.RIGHT'],
    [FLIPPERDISPLAYORIENTATION.BOTTOM, 'Side.BOTTOM'],
]);

enum FLIPPERCOLORS {
    BLACK = 0,
    MAGENTA = 1,
    VIOLET = 2,
    BLUE = 3,
    TURQUOISE = 4,
    GREEN = 5,
    YELLOWGREEN = 6,
    YELLOW = 7,
    ORANGE = 8,
    RED = 9,
    WHITE = 10,
}

export const FLIPPERCOLORS_MAP = new Map([
    [FLIPPERCOLORS.BLACK, 'Color.BLACK'],
    [FLIPPERCOLORS.MAGENTA, 'Color.MAGENTA'],
    [FLIPPERCOLORS.VIOLET, 'Color.VIOLET'],
    [FLIPPERCOLORS.BLUE, 'Color.BLUE'],
    [FLIPPERCOLORS.TURQUOISE, 'Color.CYAN'],
    [FLIPPERCOLORS.GREEN, 'Color.GREEN'],
    [FLIPPERCOLORS.YELLOWGREEN, 'Color.GREEN'],
    [FLIPPERCOLORS.YELLOW, 'Color.YELLOW'],
    [FLIPPERCOLORS.ORANGE, 'Color.ORANGE'],
    [FLIPPERCOLORS.RED, 'Color.RED'],
    [FLIPPERCOLORS.WHITE, 'Color.WHITE'],
]);

export enum EV3BUTTON {
    NONE = 0,
    LEFT = 1,
    CENTER = 2,
    RIGHT = 3,
    UP = 4,
    DOWN = 5,
}

export const EV3BUTTON_MAP = new Map([
    [EV3BUTTON.NONE, 'Button.NONE'],
    [EV3BUTTON.LEFT, 'Button.LEFT'],
    [EV3BUTTON.CENTER, 'Button.CENTER'],
    [EV3BUTTON.RIGHT, 'Button.RIGHT'],
    [EV3BUTTON.UP, 'Button.UP'],
    [EV3BUTTON.DOWN, 'Button.DOWN'],
]);

export enum EV3BUTTONEVENT {
    RELEASED = 0,
    PRESSED = 1,
}

export const EV3BUTTONEVENT_MAP = new Map([
    [EV3BUTTONEVENT.RELEASED, 'RELEASED'],
    [EV3BUTTONEVENT.PRESSED, 'PRESSED'],
]);

export enum EV3STATUSCOLOR {
    OFF = 0,
    GREEN = 1,
    RED = 2,
    ORANGE = 3,
    GREEN_PULSE = 4,
    RED_PULSE = 5,
    ORANGE_PULSE = 6,
}

export const EV3STATUSCOLOR_MAP = new Map<EV3STATUSCOLOR, string>([
    [EV3STATUSCOLOR.OFF, 'Color.NONE'],
    [EV3STATUSCOLOR.GREEN, 'Color.GREEN'],
    [EV3STATUSCOLOR.RED, 'Color.RED'],
    [EV3STATUSCOLOR.ORANGE, 'Color.ORANGE'],
    [EV3STATUSCOLOR.GREEN_PULSE, 'Color.GREEN_PULSE'],
    [EV3STATUSCOLOR.RED_PULSE, 'Color.RED_PULSE'],
    [EV3STATUSCOLOR.ORANGE_PULSE, 'Color.ORANGE_PULSE'],
]);

export enum EV3GSTATUSCOLOR {
    GREEN = 0,
    RED = 1,
    ORANGE = 2,
}

export const EV3GSTATUSCOLOR_MAP = new Map<EV3GSTATUSCOLOR, string>([
    [EV3GSTATUSCOLOR.GREEN, 'Color.GREEN'],
    [EV3GSTATUSCOLOR.RED, 'Color.RED'],
    [EV3GSTATUSCOLOR.ORANGE, 'Color.ORANGE'],
]);

export enum EV3SENSORCOLOR {
    NONE = 0,
    BLACK = 1,
    BLUE = 2,
    GREEN = 3,
    YELLOW = 4,
    RED = 5,
    WHITE = 6,
    BROWN = 7,
}

export const EV3SENSORCOLOR_MAP = new Map<EV3SENSORCOLOR, string>([
    [EV3SENSORCOLOR.NONE, 'Color.NONE'],
    [EV3SENSORCOLOR.BLACK, 'Color.BLACK'],
    [EV3SENSORCOLOR.BLUE, 'Color.BLUE'],
    [EV3SENSORCOLOR.GREEN, 'Color.GREEN'],
    [EV3SENSORCOLOR.YELLOW, 'Color.YELLOW'],
    [EV3SENSORCOLOR.RED, 'Color.RED'],
    [EV3SENSORCOLOR.WHITE, 'Color.WHITE'],
    [EV3SENSORCOLOR.BROWN, 'Color.BROWN'],
]);

export function calc_stop(context: Context, value = -1) {
    context.imports.use('pybricks.parameters', 'Stop');

    if (FLIPPERSTOP_MAP.has(value)) {
        return FLIPPERSTOP_MAP.get(value);
    }
}

export function round2(value: number, ndigits = 0) {
    const mul = 10 ** ndigits;
    return Math.round(value * mul) / mul;
}

/* no need to convert to a helper, no need for a dynamic handling, as it is a field */
export function calc_comparator(value: BlockValue): string | undefined {
    if (value.value === '=') {
        return '==';
    } else {
        return value.value?.toString();
    }
}

export function calc_ev3comparator(value: BlockValue): string | undefined {
    return ['==', '!=', '>', '>=', '<', '<='][value?.toInt()];
}
