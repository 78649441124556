import { SB3Block } from '../project.sb3/block';
import { BlockValue, ElemValueType, num_eval } from '../utils/blockvalue';
import { OperatorPrecedence, ValueType } from '../utils/enums';
import { CodeBlock, CodeConverter } from '../utils/types';
import { _debug, sanitize } from '../utils/utils';
import { handleOperators } from './handlers';

export function processOperation(
    this: CodeConverter,
    block: CodeBlock,
    returnOnEmpty = 'None',
    opcodeOverride?: string,
): BlockValue {
    if (block) {
        const retval = handleOperators.call(this, block, opcodeOverride);
        if (retval) {
            return retval;
        } else {
            _debug('unknown block', block.getDescription());
        }
    }
    return new BlockValue(returnOnEmpty, {
        is_dynamic: true,
        precedence: OperatorPrecedence.SIMPLE,
        type: ValueType.UNKNOWN,
    });
}

function operator_and_or(this: CodeConverter, block: CodeBlock) {
    const op = block?.opcode;
    const operand1 = block?.getBlock('OPERAND1');
    const operand2 = block?.getBlock('OPERAND2');
    const code_condition1 = processOperation.call(this, operand1);
    const code_condition2 = processOperation.call(this, operand2);
    return new BlockValue(
        `(${code_condition1.raw}) ${op.includes('and') ? 'and' : 'or'} (${
            code_condition2.raw
        })`,
        {
            is_dynamic: true,
            precedence: OperatorPrecedence.SIMPLE,
            type: ValueType.BOOLEAN,
        },
    );
}

function flipperevents_whenCondition(this: CodeConverter, block: CodeBlock) {
    const block2 = block.getBlock('CONDITION');
    return processOperation.call(this, block2);
}

function flipperoperator_isInBetween(this: CodeConverter, block: CodeBlock) {
    const value = block.get('VALUE').ensureNumber(this.context);
    const low = block.get('LOW').ensureNumber(this.context);
    const high = block.get('HIGH').ensureNumber(this.context);

    return new BlockValue(
        `(${low.raw} <= ${value.raw}) and (${value.raw} <= ${high.raw})`,
        {
            is_dynamic: true,
            precedence: OperatorPrecedence.SIMPLE,
            type: ValueType.BOOLEAN,
        },
    );
}

function operator_contains(this: CodeConverter, block: CodeBlock) {
    const string1 = block.get('STRING1').ensureString(this.context);
    const string2 = block.get('STRING2').ensureString(this.context);

    return new BlockValue(`${string1.raw}.lower().find(${string2.raw}.lower()) >= 0`, {
        is_dynamic: true,
        precedence: OperatorPrecedence.SIMPLE,
        type: ValueType.BOOLEAN,
    });
}

function operator_length(this: CodeConverter, block: CodeBlock) {
    const string = block.get('STRING').ensureString(this.context);

    return new BlockValue(`len(${string.raw})`, {
        is_dynamic: true,
        precedence: OperatorPrecedence.SIMPLE,
        type: ValueType.NUMBER,
    });
}

function operator_letter_of(this: CodeConverter, block: CodeBlock) {
    const string = block.get('STRING').ensureString(this.context);
    const letter = block.get('LETTER').ensureNumber(this.context, true);

    return new BlockValue(`${string.raw}[${letter.raw}]`, {
        is_dynamic: true,
        precedence: OperatorPrecedence.SIMPLE,
        type: ValueType.STRING,
    });
}

function operator_join(this: CodeConverter, block: CodeBlock) {
    const strings = [['STRING1', 'A'], ['STRING2', 'B'], ['C']]
        .map((fields) => block.get(fields))
        .filter((item) => item)
        .map((item) => item.ensureString(this.context).raw);

    const value = strings.join(', ');
    return new BlockValue(`"".join([${value}])`, {
        is_dynamic: true,
        precedence: OperatorPrecedence.SIMPLE,
        type: ValueType.STRING,
    });
}

function operator_random(this: CodeConverter, block: CodeBlock) {
    const from = block.get('FROM').ensureNumber(this.context);
    const to = block.get('TO').ensureNumber(this.context);

    this.context.imports.use('urandom', 'randint');
    return new BlockValue(`randint(${from.raw}, ${to.raw})`, {
        is_dynamic: true,
        precedence: OperatorPrecedence.SIMPLE,
        type: ValueType.NUMBER,
    });
}

function flipperoperator_mathFunc2Params(this: CodeConverter, block: CodeBlock) {
    const arg1 = block.get('ARG1').ensureNumber(this.context);
    const arg2 = block.get('ARG2').ensureNumber(this.context);
    const args = [arg1, arg2];
    const post_process_fn = (e: string) => e;
    let op2 = block.get('TYPE').value;

    switch (op2) {
        case 'pow':
        case 'atan2':
        case 'copysign':
            this.context.imports.use('umath', null);
            op2 = `umath.${op2}`;
            break;
        // NOTE: hypot missing
    }

    return new BlockValue(
        post_process_fn(`${op2}(${args.map(BlockValue.raw).join(', ')})`),
        {
            is_dynamic: true,
            precedence: OperatorPrecedence.SIMPLE,
            type: ValueType.NUMBER,
        },
    );
}

function operator_mathop(this: CodeConverter, block: CodeBlock) {
    let op2 = block.get(['TYPE', 'OPERATOR']).value;
    const num = block.get('NUM').ensureNumber(this.context);
    const args: ElemValueType[] = [num];
    let post_process_fn = (e: string) => e;

    this.context.imports.use('umath', null);
    switch (op2) {
        case 'ceiling':
            op2 = 'ceil';
            break;
        case 'ln':
            op2 = 'log';
            break;
        case 'log':
            op2 = 'log';
            post_process_fn = (e) => `${e}/umath.log(10)`;
            break;
        case 'e ^':
            op2 = 'pow';
            args.unshift('umath.e');
            break;
        case '10 ^':
            op2 = 'pow';
            args.unshift(10);
            break;
    }

    return new BlockValue(
        post_process_fn(`umath.${op2}(${args.map(BlockValue.raw).join(', ')})`),
        {
            is_dynamic: true,
            precedence: OperatorPrecedence.SIMPLE,
            type: ValueType.NUMBER,
        },
    );
}

function operator_round(this: CodeConverter, block: CodeBlock) {
    const num = block.get('NUM').ensureNumber(this.context);

    return new BlockValue(`round(${num.raw})`, {
        is_dynamic: true,
        precedence: OperatorPrecedence.SIMPLE,
        type: ValueType.NUMBER,
    });
}

function _operator_math_two_op(
    this: CodeConverter,
    block: CodeBlock,
    argnames: string[],
    operator: string,
) {
    const num1 = block.get(argnames[0]);
    const num2 = block.get(argnames[1]);
    return num_eval(this.context, [num1, operator, num2]);
}

function operator_math_two_op(this: CodeConverter, block: CodeBlock, operator: string) {
    return _operator_math_two_op.call(this, block, ['NUM1', 'NUM2'], operator);
}

function ev3g_Arithmetic(this: CodeConverter, block: CodeBlock, operator: string) {
    return _operator_math_two_op.call(this, block, ['X', 'Y'], operator);
}

function _comparison(
    this: CodeConverter,
    block: CodeBlock,
    op1Param: string,
    op2Param: string,
    comparator: string,
) {
    // this is coming as string, but helper will take care of it
    // NOTE: this can be two strings and "A" > "Apple" makes sense, yet we assume numeric comparison here...
    const operand1 = block.get(op1Param).ensureNumber(this.context);
    const operand2 = block.get(op2Param).ensureNumber(this.context);

    return new BlockValue(`${operand1.raw} ${comparator} ${operand2.raw}`, {
        is_dynamic: true,
        type: ValueType.BOOLEAN,
        precedence: OperatorPrecedence.BINARY_COMPARISON,
    });
}

function operator_lt_gt_eq(this: CodeConverter, block: CodeBlock, comparator: string) {
    return _comparison.call(this, block, 'OPERAND1', 'OPERAND2', comparator);
}

function ev3g_Comparison(this: CodeConverter, block: CodeBlock, comparator: string) {
    return _comparison.call(this, block, 'X', 'Y', comparator);
}

function operator_not(this: CodeConverter, block: CodeBlock) {
    const operand1 = block.getBlock('OPERAND');
    const code_condition = processOperation.call(this, operand1);
    return new BlockValue(`not (${code_condition.raw})`, {
        is_dynamic: true,
        precedence: OperatorPrecedence.SIMPLE,
        type: ValueType.BOOLEAN,
    });
}

function argument_reporter_string_number_boolean(
    this: CodeConverter,
    block: CodeBlock,
) {
    const value = sanitize(block.get('VALUE').toString());
    return new BlockValue(value, {
        is_dynamic: true,
        is_variable: true,
        precedence: OperatorPrecedence.SIMPLE,
        //type: ValueType.NUMBER,
    });
}

function ev3g_CaseSelector(
    this: CodeConverter,
    block: CodeBlock,
    selector: string,
    valuetype: ValueType,
) {
    const value = block.get(selector);
    return new BlockValue(`${value.raw}`, { type: valuetype });
}

function handleOperator(this: CodeConverter, block: CodeBlock): BlockValue | undefined {
    switch (block.opcode) {
        case 'operator_or':
        case 'operator_and':
            return operator_and_or.call(this, block);
        case 'operator_not':
            return operator_not.call(this, block);
        case 'operator_lt':
            return operator_lt_gt_eq.call(this, block, '<');
        case 'operator_gt':
            return operator_lt_gt_eq.call(this, block, '>');
        case 'operator_equals':
            return operator_lt_gt_eq.call(this, block, '==');
        case 'operator_add':
            return operator_math_two_op.call(this, block, '+');
        case 'operator_subtract':
            return operator_math_two_op.call(this, block, '-');
        case 'operator_multiply':
            return operator_math_two_op.call(this, block, '*');
        case 'operator_divide':
            return operator_math_two_op.call(this, block, '/');
        case 'operator_mod':
            return operator_math_two_op.call(this, block, '%');
        case 'operator_round':
            return operator_round.call(this, block);
        case 'operator_mathop':
            return operator_mathop.call(this, block);
        case 'flipperoperator_mathFunc2Params':
            return flipperoperator_mathFunc2Params.call(this, block);
        case 'operator_random':
            return operator_random.call(this, block);
        case 'operator_join':
        case 'ConcatenateStrings':
            return operator_join.call(this, block);
        case 'operator_letter_of':
            return operator_letter_of.call(this, block);
        case 'operator_length':
            return operator_length.call(this, block);
        case 'operator_contains':
            return operator_contains.call(this, block);
        case 'flipperoperator_isInBetween':
            return flipperoperator_isInBetween.call(this, block);
        case 'flipperevents_whenCondition':
        case 'ev3events_whenCondition':
            return flipperevents_whenCondition.call(this, block);
        case 'argument_reporter_string_number':
        case 'argument_reporter_boolean':
            return argument_reporter_string_number_boolean.call(this, block);
        case 'Arithmetic_Add':
            return ev3g_Arithmetic.call(this, block, '+');
        case 'Arithmetic_Subtract':
            return ev3g_Arithmetic.call(this, block, '-');
        case 'Arithmetic_Multiply':
            return ev3g_Arithmetic.call(this, block, '*');
        case 'Arithmetic_Divide':
            return ev3g_Arithmetic.call(this, block, '/');
        case 'Comparison_Less':
            return ev3g_Comparison.call(this, block, '<');
        case 'Comparison_Greater':
            return ev3g_Comparison.call(this, block, '>');
        case 'Comparison_LessEqual':
            return ev3g_Comparison.call(this, block, '<=');
        case 'Comparison_GreaterEqual':
            return ev3g_Comparison.call(this, block, '>=');
        case 'Comparison_Equal':
            return ev3g_Comparison.call(this, block, '==');
        case 'CaseSelector_Boolean':
            return ev3g_CaseSelector.call(this, block, 'BOOLEAN', ValueType.BOOLEAN);
        case 'CaseSelector_Numeric':
            return ev3g_CaseSelector.call(this, block, 'NUMBER', ValueType.NUMBER);
    }
}

// function handleBlock(this: CodeConverter, block: CodeBlock): string[] | undefined {
// }

const handlers = {
    // block: handleBlock,
    block: undefined,
    operator: handleOperator,
};
export default handlers;
