import { RegistryManager, RegistryPayloadWithUse } from './registrymanager';

import { Context } from './context';

export class ImportRegistryPayload implements RegistryPayloadWithUse {
    importedItems: Set<string> = new Set();
    // constructor(...importedItems: string[]) {
    //   this.use(...importedItems);
    // }

    use(...importedItems: string[]) {
        importedItems.forEach((elem) => {
            if (elem !== undefined && elem !== null && !this.importedItems.has(elem)) {
                this.importedItems.add(elem);
            }
        });
    }

    static to_global_code(registry: RegistryManager<ImportRegistryPayload>): string[] {
        return Array.from(
            registry
                .entries()
                .sort(
                    (
                        [akey, _aval]: [string, unknown],
                        [bkey, _bval]: [string, unknown],
                    ) => akey.localeCompare(bkey),
                ),
        ).map(([key, value]) =>
            value?.payload?.importedItems.size > 0
                ? `from ${key} import ` +
                  Array.from(value?.payload?.importedItems.keys()).join(', ')
                : `import ${key}`,
        );
    }

    static createRegistry(context: Context) {
        return new RegistryManager(context, () => new ImportRegistryPayload());
    }
}
