import { BlockValue } from './blockvalue';
import { Context } from '../context/context';
import { VariableRegistryPayload } from '../context/variables';

export class CodeBlock {
    variablesForWriteAccess = new Set<VariableRegistryPayload>();

    get opcode(): string {
        throw new Error('Not implemented');
    }

    get(name: string | string[], isPythonMode = true): BlockValue {
        return this._get(name, isPythonMode, true) as BlockValue;
    }

    getBlock(name: string | string[]): CodeBlock {
        return this._get(name, undefined, false) as CodeBlock;
    }

    protected _get(
        name: string | string[],
        isPythonMode = true,
        processChildBlock = true, // useBlockValue
    ): BlockValue | CodeBlock | undefined {
        throw new Error('Not implemented');
    }

    get id(): string | undefined {
        throw new Error('Not implemented');
    }

    getDescription(isPythonMode = true): string {
        throw new Error('Not implemented');
    }

    getSubStack(index: number): CodeBlock[] {
        throw new Error('Not implemented');
    }
}

export interface CodeConverter {
    context: Context;
    process_stack(blocks: CodeBlock[] | null, isPythonMode: boolean): string[];
}

export class BlockMatchError extends Error {
    constructor(message?: string) {
        super(message);
        /* LINK: https://github.com/microsoft/TypeScript/issues/13965 for ES5 */
        Object.setPrototypeOf(this, BlockMatchError.prototype);
    }
}
