import { HelperEnabledRegistryPayload, HelperRegistryManager } from './helpers';

import { BroadcastRegistryPayload } from './broadcasts';
import { DeviceBase } from '../device/devicebase';
import { DeviceType } from '../utils/enums';
import { ImportRegistryPayload } from './imports';
import { ProcedureRegistryPayload } from './procedures';
import { RegistryManager } from './registrymanager';
import { VariableRegistryPayload } from './variables';

export class Context {
    imports: RegistryManager<ImportRegistryPayload>;
    broadcasts: RegistryManager<BroadcastRegistryPayload>;
    variables: RegistryManager<VariableRegistryPayload>;
    procedures: RegistryManager<ProcedureRegistryPayload>;
    helpers: HelperRegistryManager;
    devicesRegistry: Map<string, DeviceBase>;
    deviceDefaultSpeeds: Map<string, string>;
    isAsyncNeeded = false;
    deviceType?: DeviceType;

    constructor() {
        this.imports = ImportRegistryPayload.createRegistry(this);
        this.broadcasts = BroadcastRegistryPayload.createRegistry(this);
        this.variables = VariableRegistryPayload.createRegistry(this);
        this.procedures = ProcedureRegistryPayload.createRegistry(this);
        this.helpers = HelperEnabledRegistryPayload.createRegistry(this);
        this.devicesRegistry = DeviceBase.createRegistry(this);
        this.deviceDefaultSpeeds = new Map<string, string>();
    }

    get awaitPrefix(): string {
        return this.isAsyncNeeded ? 'await ' : '';
    }

    get asyncPrefix(): string {
        return this.isAsyncNeeded ? 'async ' : '';
    }

    clear(): void {
        this.imports.clear();
        this.broadcasts.clear();
        this.variables.clear();
        this.procedures.clear();
        this.helpers.clear();
        this.devicesRegistry.clear();
        this.deviceDefaultSpeeds.clear();
        this.isAsyncNeeded = false;
        this.deviceType = undefined;
    }

    get deviceTypeIsSB3(): boolean {
        return (
            this.deviceType !== undefined &&
            [
                DeviceType.SPIKE,
                DeviceType.ROBOTINVENTOR,
                DeviceType.EV3CLASSROOM,
            ].includes(this.deviceType)
        );
    }

    get deviceTypeIsSPIKERI(): boolean {
        return (
            this.deviceType !== undefined &&
            [DeviceType.SPIKE, DeviceType.ROBOTINVENTOR].includes(this.deviceType)
        );
    }
}
