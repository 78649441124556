import { BlockValue, num_eval } from '../utils/blockvalue';
import { ValueType } from '../utils/enums';
import { CodeBlock, CodeConverter } from '../utils/types';

function flippersound_beep(this: CodeConverter, block: CodeBlock) {
    const note = block.get('NOTE').ensureNumber(this.context, true);
    // field 'note': 48 = C, 49 = C#, 50 = D ...
    const duration = this.context.helpers
        .use('convert_time')
        // NOTE: this does not work yet, so we use a workaround
        // "If the duration is less than 0, then the method returns immediately and the frequency play continues to play indefinitely."
        .call(block.get('DURATION') ?? -0.001);

    // this.context.imports.use('urandom', 'randint');
    return [
        this.context.awaitPrefix +
            this.context.helpers.use('hub_speaker_flipper_play').call(note, duration)
                .raw,
    ];
}

function ev3g_PlayNote(this: CodeConverter, block: CodeBlock) {
    // note: example A5 => this means 48+
    const note = block.get('NOTE');
    // tempo: 120 bpm, 0.5 sec for a beat (/4)
    // duration 1000 = 1s
    // tempo = 60000 / duration
    const duration = this.context.helpers
        .use('convert_time')
        .call(block.get('DURATION'));
    const tempo = num_eval(this.context, [60 * 1000, '/', duration]);

    // this.context.imports.use('urandom', 'randint');
    return [
        `${this.context.awaitPrefix}hub.speaker.play_notes(["${note.toString()}/4"], ${
            tempo?.raw
        }])`,
    ];
}

function ev3g_PlaySoundFile(this: CodeConverter, block: CodeBlock) {
    return ['hub.speaker.beep()'];
}

function hub_speaker_iconblocks_play(this: CodeConverter, block: CodeBlock) {
    const sound = block.get('SOUND');
    // field 'note': 48 = C, 49 = C#, 50 = D ...

    this.context.imports.use('urandom', 'randint');
    return [
        this.context.awaitPrefix +
            this.context.helpers.use('hub_speaker_iconblocks_play').call(sound).raw,
    ];
}

function flippersound_stopSound(this: CodeConverter, _block: CodeBlock) {
    return [`${this.context.awaitPrefix}hub.speaker.beep(0, 0)`];
}

function sound_setvolumeto(this: CodeConverter, block: CodeBlock) {
    const volume = block.get('VOLUME').ensureNumber(this.context, true);
    return [`hub.speaker.volume(${volume.raw})`];
}

function sound_changevolumeby(this: CodeConverter, block: CodeBlock) {
    const volume = block.get('VOLUME').ensureNumber(this.context, true);
    return [`hub.speaker.volume(hub.speaker.volume() + ${volume.raw})`];
}

function sound_volume(this: CodeConverter, _block: CodeBlock) {
    return new BlockValue('hub.speaker.volume()', {
        is_dynamic: true,
        type: ValueType.NUMBER,
    });
}

function handleBlock(this: CodeConverter, block: CodeBlock): string[] | undefined {
    switch (block.opcode) {
        case 'flippersound_beep':
        case 'flippersound_beepForTime':
        case 'ev3sound_beep':
        case 'ev3sound_beepForTime':
            return flippersound_beep.call(this, block);
        case 'PlayNote':
            return ev3g_PlayNote.call(this, block);
        case 'PlaySoundFile':
            return ev3g_PlaySoundFile.call(this, block);
        case 'horizontalsound_playMusicSoundUntilDone':
            return hub_speaker_iconblocks_play.call(this, block);
        case 'flippersound_stopSound':
        case 'ev3sound_stopSound':
            return flippersound_stopSound.call(this, block);
        case 'sound_setvolumeto':
        case 'ev3sound_setVolumeTo':
            return sound_setvolumeto.call(this, block);
        case 'sound_changevolumeby':
            return sound_changevolumeby.call(this, block);
    }
}

function handleOperator(this: CodeConverter, block: CodeBlock): BlockValue | undefined {
    switch (block.opcode) {
        case 'sound_volume':
            return sound_volume.call(this, block);
    }
}

const handlers = {
    block: handleBlock,
    operator: handleOperator,
};
export default handlers;
