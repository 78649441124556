// import getCurrentLine from 'get-current-line';

export const INDENT = '    ';
export const CONST_CM = 'cm';
export const CONST_INCHES = 'inches';
export const CONST_ROTATIONS = 'rotations';
export const CONST_DEGREES = 'degrees';
export const CONST_SECONDS = 'seconds';
export const CONST_AUTO_PORT = 'AUTO';

export function get_divider(text: string, prefix: string, fillchar = '-', width = 80) {
    /* ------------------------------ python example ------------------------------ */
    const len = width - text.length - 6 - prefix?.length;
    return `#${prefix} ${fillchar.repeat(
        Math.floor(len / 2),
    )} ${text} ${fillchar.repeat(Math.ceil(len / 2))} #`;
}

export function _debug(...args: unknown[]) {
    /* const prefix = getCurrentLine
        ? (() => {
              const line = getCurrentLine({ frames: +3 });
              return `at ${line?.method} (${line?.file}:${line?.line}:${line?.char})`;
          })()
        : ''; */
    const prefix = '';
    console.log('::DEBUG::', ...args, prefix);
}

export function indent_code(value: string | string[], levels = 1): string[] {
    let retval = [] as string[];
    if (value === undefined) {
        retval = [];
    } else if (Array.isArray(value)) {
        retval = value.reduce(
            (lines, line) => [...lines, ...indent_code(line, levels)],
            [] as string[],
        );
    } else if (value.includes('\n')) {
        retval = indent_code(value.split('\n'), levels);
    } else {
        retval = [INDENT.repeat(levels) + value];
    }
    return retval;
}

export function enforceNonEmpty(lines: string[], isPythonMode = true) {
    if (!lines.filter((line) => !line.match(/^\s*$|^\s*#.*$/)).length) {
        lines.push('pass'); //!!
    }
    return lines;
}

export function sanitize(key: string) {
    return key
        .trim()
        .toLowerCase()
        .replace(/[ .-]|^\s?\d+/gim, '_')
        .replace(
            /[áéíóöőúüű]/g,
            (c) =>
                ({
                    á: 'a',
                    é: 'e',
                    í: 'i',
                    ó: 'o',
                    ö: 'o',
                    ő: 'o',
                    ú: 'u',
                    ü: 'u',
                    ű: 'u',
                }[c] ?? c),
        )
        .replace(/[^a-zA-Z0-9_]/g, '');
}

export function isNumber(value: unknown) {
    return typeof value === 'number';
}

export function getFileExtension(fileName: string): string {
    const dotIndex = fileName.lastIndexOf('.');

    /* If there's no dot or it's the first character (hidden file), return an empty string */
    if (dotIndex === -1 || dotIndex === 0) {
        return '';
    }

    return fileName.substring(dotIndex); /* Extract extension with the dot */
}

export function parseBool(value?: string): boolean {
    const normalizedValue = value?.trim().toLowerCase();
    return normalizedValue === 'true' || normalizedValue === '1';
}
