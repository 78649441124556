import { Context } from '../context/context';
import { OperatorPrecedence, ValueType } from './enums';
import { parseBool } from './utils';

export type ElemValueBaseType = string | number | boolean | undefined;
export type ElemValueType = ElemValueBaseType | BlockValue;
export class BlockValue {
    public value: ElemValueBaseType;
    constructor(
        value_in?: ElemValueType,
        public options?: {
            is_dynamic?: boolean;
            is_variable?: boolean;
            // private is_string = false,
            type?: ValueType; // ValueType.UNKNOWN;
            precedence?: OperatorPrecedence;
        },
    ) {
        if (value_in && BlockValue.is(value_in)) {
            this.value = value_in.value;
        } else {
            this.value = value_in;
        }
    }
    get is_string() {
        return this.options?.type === ValueType.STRING;
    }
    get is_numeric() {
        return (
            this.options?.type === ValueType.NUMBER ||
            (!this.options?.is_dynamic &&
                !this.is_string &&
                typeof this.value === 'number')
        );
    }
    get raw(): ElemValueBaseType {
        return !this.is_string || this.options?.is_dynamic
            ? this.value
            : `"${this.value}"`;
        //const is_string = this.is_string || typeof(this.value) !== 'number'
        //return !this.is_numeric ? this.value : `"${this.value}"`;
    }
    toString() {
        return BlockValue.toString(this);
    }
    toInt(): number {
        return BlockValue.toInt(this);
    }
    toFloat(): number {
        return BlockValue.toFloat(this);
    }
    toBool(): boolean {
        return BlockValue.toBool(this);
    }
    ensureString(context: Context) {
        return convertValue(context, this, ValueType.STRING);
        // if (!this.is_string) {
        //     return context.helpers.use('str').call(this);
        // } else {
        //     return this;
        // }
    }
    ensureNumber(context: Context, isInteger = false) {
        // return convertValue(context, this, ValueType.NUMBER, isInteger);
        //TODO: isnumeric also accepts STRING + type = 'number'
        if (this.is_numeric) {
            if (this.options?.is_dynamic) {
                return this;
            } else {
                const value = isInteger ? this.toInt() : this.toFloat();
                return new BlockValue(value, { type: ValueType.NUMBER });
            }
        } else {
            return convertValue(context, this, ValueType.NUMBER, isInteger);
        }
    }
    static is(value: ElemValueType): value is BlockValue {
        return value instanceof BlockValue;
    }
    static is_dynamic(value: ElemValueType): value is BlockValue {
        return BlockValue.is(value) && !!value.options?.is_dynamic;
    }
    static raw(value: ElemValueType) {
        return BlockValue.is(value) ? value.raw : value;
    }
    static value(value: ElemValueType) {
        return BlockValue.is(value) ? value.value : value;
    }
    static toString(value: ElemValueType): string {
        return BlockValue.value(value)?.toString() ?? '';
    }
    static toInt(value: ElemValueType): number {
        const value1 = BlockValue.is(value) ? value.value : value;
        return parseInt(value1?.toString() ?? '');
    }
    static toFloat(value: ElemValueType): number {
        const value1 = BlockValue.is(value) ? value.value : value;
        return parseFloat(value1?.toString() ?? '');
    }
    static toBool(value: ElemValueType): boolean {
        const value1 = BlockValue.is(value) ? value.value : value;
        return parseBool(value1?.toString());
    }
    static ensureNumber(context: Context, value: ElemValueType, isInteger = false) {
        if (BlockValue.is(value)) {
            return value.ensureNumber(context);
        } else {
            // return convertValue(context, this, ValueType.NUMBER, isInteger);
            return context.helpers
                .use(isInteger ? 'int_safe' : 'float_safe')
                .call(value);
        }
    }

    static isEqual(value1: ElemValueType, value2: ElemValueType): boolean {
        if (BlockValue.is(value1) && BlockValue.is(value2)) {
            return (
                value1.value === value2.value &&
                JSON.stringify(value1.options) === JSON.stringify(value2.options)
            );
        }
        return value1 === value2;
    }
}

type NumEvalType =
    | ElemValueType
    | [ElemValueType]
    | [string, NumEvalType]
    | [NumEvalType, string, NumEvalType];

export function num_eval(
    context: Context,
    values: NumEvalType,
    isInteger?: boolean,
): BlockValue | undefined;
export function num_eval(
    context: Context,
    values: ElemValueType,
    isInteger?: boolean,
): BlockValue | undefined;
export function num_eval(
    context: Context,
    values: ElemValueType | NumEvalType,
    isInteger = false,
): BlockValue | undefined {
    const [a, b, c] = Array.isArray(values) ? values : [values, undefined, undefined];

    // one operand
    if (b === undefined) {
        if (Array.isArray(a)) {
            return num_eval(context, a, isInteger);
        }

        if (!BlockValue.is_dynamic(a)) {
            const roundedValue =
                Math.round(parseFloat(a?.toString() ?? '') * 1000) / 1000;
            return new BlockValue(roundedValue, { type: ValueType.NUMBER });
        }

        if (!BlockValue.is(a) || a.options?.type !== ValueType.NUMBER) {
            return BlockValue.ensureNumber(context, a, isInteger);
        }

        return new BlockValue(a, {
            type: ValueType.NUMBER,
            is_dynamic: a.options.is_dynamic,
            precedence: a.options.precedence,
        });
    }
    // two operands
    else if (c === undefined) {
        const b1 = num_eval(context, b, isInteger);
        const allow_local = !BlockValue.is_dynamic(b1);
        if (a === '-' || a === '+') {
            if (allow_local) {
                const multiplier = a === '-' ? -1 : 1;
                const result = multiplier * BlockValue.toFloat(b1);
                return new BlockValue(result, { type: ValueType.NUMBER });
            } else {
                const expression = `${a}${BlockValue.raw(b1)}`;
                return new BlockValue(expression, {
                    is_dynamic: true,
                    type: ValueType.NUMBER,
                    precedence: OperatorPrecedence.UNARY,
                });
            }
        } else if (a === 'abs') {
            if (allow_local) {
                return new BlockValue(Math.abs(BlockValue.toFloat(b1)), {
                    type: ValueType.NUMBER,
                });
            } else {
                context.imports.use('umath', null);
                return new BlockValue(`umath.fabs(${BlockValue.raw(b1)})`, {
                    is_dynamic: true,
                    type: ValueType.NUMBER,
                    precedence: OperatorPrecedence.SIMPLE,
                });
            }
        }
    }
    // three operands
    else {
        const a1 = num_eval(context, a, isInteger);
        const c1 = num_eval(context, c, isInteger);
        let a1v = BlockValue.raw(a1);
        let c1v = BlockValue.raw(c1);
        const allow_local = !BlockValue.is_dynamic(a1) && !BlockValue.is_dynamic(c1);

        if (allow_local) {
            const fnCalc = (a1: number, operator: string, c1: number) => {
                switch (operator) {
                    case '+':
                        return a1 + c1;
                    case '-':
                        return a1 - c1;
                    case '*':
                        return a1 * c1;
                    case '/':
                        return a1 / c1;
                    case '%':
                        return a1 % c1;
                }
            };

            if (a1v === undefined || b === undefined || c1v === undefined) {
                return undefined;
            }
            const value = num_eval(
                context,
                fnCalc(BlockValue.toFloat(a1v), b?.toString(), BlockValue.toFloat(c1v)),
            );

            return new BlockValue(value, {
                type: ValueType.NUMBER,
                precedence: OperatorPrecedence.SIMPLE,
            });
        } else {
            const a1 = num_eval(context, a, isInteger);
            const c1 = num_eval(context, c, isInteger);

            // optimizations, simplifications
            const operator = b?.toString();
            const isZero = (value?: BlockValue) => value?.toString() === '0';
            const isOne = (value?: BlockValue) => value?.toString() === '1';
            const isNegativeOne = (value?: BlockValue) => value?.toString() === '-1';
            if (operator === '*') {
                if (isZero(a1) || isOne(c1)) return a1; // 0 * n = 0, n * 1 = n
                if (isZero(c1) || isOne(a1)) return c1; // n * 0 = 0, 1 * n = n
                if (isNegativeOne(a1)) return num_eval(context, ['-', c1]); // -1 * n = -n
                if (isNegativeOne(c1)) return num_eval(context, ['-', a1]); // n * -1 = -n
            } else if (operator === '/') {
                if (isZero(a1) || isOne(c1)) return a1; // 0 / n = 0, n / 1 = n
                if (isNegativeOne(c1)) return num_eval(context, ['-', a1]); // n / -1 = -n
            } else if (operator === '+') {
                if (isZero(a1)) return c1; // n + 0 = n
                if (isZero(c1)) return a1; // 0 + n = n
            } else if (operator === '-') {
                if (isZero(a1)) return num_eval(context, ['-', c1]); // 0 - n = -n
                if (isZero(c1)) return a1; // n - 0 = n
            }

            const precedence = (() => {
                switch (b.toString()) {
                    case '-':
                    case '+':
                        return OperatorPrecedence.BINARY_ADD;
                    case '*':
                    case '/':
                    case '%':
                        return OperatorPrecedence.BINARYOP_MUL;
                    default:
                        return OperatorPrecedence.WEAKEST;
                }
            })();
            // undefined prededence means the highest

            if (precedence < (a1?.options?.precedence ?? 0)) {
                a1v = `(${a1v})`;
            }
            if (precedence < (c1?.options?.precedence ?? 0)) {
                c1v = `(${c1v})`;
            }

            return new BlockValue(`${a1v} ${b} ${c1v}`, {
                is_dynamic: true,
                type: ValueType.NUMBER,
                precedence,
            });
        }
    }
}

export function convertValue(
    context: Context,
    valuein: BlockValue,
    targetType: ValueType,
    targetNumberInteger: boolean = false,
) {
    const sourceType = valuein?.options?.type;
    if (targetType !== sourceType) {
        switch (targetType) {
            case ValueType.NUMBER:
                valuein = targetNumberInteger
                    ? context.helpers.use('int_safe').call(valuein)
                    : context.helpers.use('float_safe').call(valuein);
                break;
            case ValueType.STRING:
                valuein = context.helpers.use('str').call(valuein);
                break;
            case ValueType.NUMBERARRAY:
                switch (sourceType) {
                    case ValueType.NUMBER:
                        valuein = new BlockValue(`[${valuein.raw}]`, {
                            is_dynamic: true,
                            is_variable: true,
                            type: ValueType.NUMBERARRAY,
                        });
                        break;
                }
                break;
            case ValueType.BOOLEANARRAY:
                switch (sourceType) {
                    case ValueType.BOOLEAN:
                        valuein = new BlockValue(`[${valuein.raw}]`, {
                            is_dynamic: true,
                            is_variable: true,
                            type: ValueType.BOOLEANARRAY,
                        });
                        break;
                }
                break;
            default:
                break;
        }
        // console.log(
        //     '>>',
        //     block.id,
        //     `conversion needed ${sourceType}->${valuetype}`,
        //     `${valueino.raw} -> ${valuein?.raw}`,
        // );
    }
    return valuein;
}
