import { Context } from '../context/context';
import { DeviceBase } from './devicebase';
import { DeviceMotor } from './motor';

const DEFAULT_ROTATION_DISTANCE = 175;
const DEFAULT_WHEEL_DIAMETER = 55.7;
const DEFAULT_AXLE_TRACK = 117;

export class DeviceDriveBase extends DeviceBase {
    private _ports?: string[];
    private _wheel_diameter?: number;
    private _axle_track?: number;
    private _default_then?: string;
    private _rotation_distance?: number;
    motor_left?: DeviceMotor;
    motor_right?: DeviceMotor;
    isExplicitlyUsed: boolean;

    constructor(
        context: Context,
        ports?: string[],
        wheel_diameter?: number,
        axle_track?: number,
        isExplicitlyUsed = true,
    ) {
        super(context);
        this._ports = ports;
        this._wheel_diameter = wheel_diameter;
        this._axle_track = axle_track;
        this.isExplicitlyUsed = isExplicitlyUsed; // DeviceDriveBase might be "preregistered" to default ports if not explicitly specified - we only add it if it really used later
    }

    static readonly DEVICENAME = 'drivebase';

    static instance(
        context: Context,
        ports?: string[],
        wheel_diameter?: number,
        axle_track?: number,
        isExplicitlyUsed = true,
    ): DeviceDriveBase {
        let elem = context.devicesRegistry.get(
            DeviceDriveBase.DEVICENAME,
        ) as DeviceDriveBase;
        if (!elem) {
            elem = new DeviceDriveBase(
                context,
                ports,
                wheel_diameter,
                axle_track,
                isExplicitlyUsed,
            );
            context.devicesRegistry.set(DeviceDriveBase.DEVICENAME, elem);
        } else {
            elem.ports = ports ?? elem._ports;
            elem.wheel_diameter = wheel_diameter ?? elem._wheel_diameter;
            elem.axle_track = axle_track ?? elem._axle_track;
            if (!elem.isExplicitlyUsed && isExplicitlyUsed) {
                // after preregistration we need to update the isExplicitlyUsed flag
                elem.isExplicitlyUsed = true;
                elem.ensureDependencies();
            }
        }
        return elem;
    }

    get_then(): string | undefined {
        if (this._default_then) this.context.imports.use('pybricks.parameters', 'Stop');
        return this._default_then;
    }

    get default_speed_variable(): string {
        // default_speeds is anyhow added with motors
        return `default_speeds[${this.devicename}]`;
    }

    set default_then(value: string | undefined) {
        this._default_then = value;
    }

    get wheel_diameter(): number {
        return this._wheel_diameter ?? DEFAULT_WHEEL_DIAMETER;
    }

    set wheel_diameter(value: number | undefined) {
        this._wheel_diameter = value;
    }

    get rotation_distance(): number {
        return this._rotation_distance ?? DEFAULT_ROTATION_DISTANCE;
    }

    set rotation_distance(value: number) {
        this._rotation_distance = value;
    }

    get rotation_distance_variable(): string {
        return `${this.devicename}_rotation_distance`;
    }

    get axle_track(): number {
        return this._axle_track ?? DEFAULT_AXLE_TRACK;
    }

    set axle_track(value: number | undefined) {
        this._axle_track = value;
    }

    override get devicename(): string {
        return DeviceDriveBase.DEVICENAME;
    }

    override get dependencies(): DeviceBase[] {
        this.ensureDependencies();
        return [this.motor_left, this.motor_right].filter(
            (elem): elem is DeviceMotor => !!elem,
        );
    }

    get ports(): string[] {
        return this._ports || ['A', 'B'];
    }

    set ports(value: string[] | undefined) {
        this._ports = value;
    }

    override setupCode(): string[] {
        if (!this.isExplicitlyUsed) {
            return [];
        }

        const setup_code = super.setupCode();
        setup_code.push(
            `${this.devicename} = DriveBase(${this.motor_left?.devicename}, ${this.motor_right?.devicename}, ${this.wheel_diameter}, ${this.axle_track})`,
        );
        this.context.deviceDefaultSpeeds.set(
            this.devicename,
            this.context.helpers.use('convert_speed')?.call(50).toString(),
        );
        return setup_code;
    }

    override ensureDependencies(): void {
        if (!this.isExplicitlyUsed) {
            return;
        }

        const genMotor = (port: string, cw?: boolean): DeviceMotor | undefined => {
            const dev = DeviceMotor.instance(this.context, port);
            if (dev && cw !== undefined) {
                dev.direction_cw = cw;
                dev.ensureDependencies();
            }
            return dev;
        };

        this.context.imports.use('pybricks.robotics', 'DriveBase');
        if (!this.ports || !Array.isArray(this.ports)) {
            throw new Error('Invalid ports');
        }

        this.motor_left = genMotor(this.ports[0], false);
        this.motor_right = genMotor(this.ports[1], true);
    }
}
