import { RegistryManager, RegistryPayloadWithId } from './registrymanager';
import { _debug, sanitize } from '../utils/utils';

import { Context } from './context';
import { SB3Block } from '../project.sb3/block';

export type ProcedureArg = { name: string; id: string; type: string };
export class ProcedureRegistryPayload implements RegistryPayloadWithId {
    id: string;
    name: string;
    blockid: string;
    args: Map<string, ProcedureArg>;

    constructor(
        id: string,
        name: string,
        blockid: string,
        args: Map<string, ProcedureArg>,
    ) {
        this.id = id; // "proccode" field
        this.blockid = blockid;
        this.name = name;
        this.args = args;
    }

    getPyName(functionPrefix?: string) {
        return `${functionPrefix ?? ''}${this.name}`;
    }

    getPyDefinition(functionPrefix?: string) {
        const signature_params = [...this.args.values()].map(
            (elem) => `${elem.name}: ${elem.type}`,
        );

        return `${this.getPyName(functionPrefix)}(${signature_params.join(', ')})`;
    }

    // static getArgDefByArgBlockId(id: string) {
    //   for (const proc of proceduresRegistry.values()) {
    //     for (const arg of proc.payload.args.values()) {
    //       if (arg.id === id) return arg;
    //     }
    //   }
    // }

    static create(
        block: SB3Block,
        useCache = false,
    ): ProcedureRegistryPayload | undefined {
        const block2 = block.getBlock('custom_block') as SB3Block; // this is a procedures_prototype
        const proccode = block2._block.mutation?.proccode ?? '';
        if (useCache) {
            return block.converter.context.procedures.get(proccode);
        }

        const blockname = sanitize(/^(.*?)(?= %[sb])|^.*/.exec(proccode)?.[0] ?? '');
        const argumenttypes: string[] = [];
        const argumentids: string[] = [];
        const argumentnames: string[] = [];
        Object.entries(block2._block.inputs).forEach(([key, v3]) => {
            const blockId3 = v3[1];
            if (typeof blockId3 !== 'string') {
                return;
            }
            const block3 = block2.getPeerById(blockId3);
            if (!block3) {
                return;
            }
            const argtype = block3.opcode.replace('argument_reporter_', '');
            const argname = sanitize(block3.get('VALUE')?.toString());

            // do not check duplicates, scrach cannot handle this anyhow
            if (argumentnames.indexOf(argname) >= 0) {
                _debug(`duplicate argname at ${proccode} - ${argname}/${key}`);
            }

            argumentnames.push(argname);
            argumentids.push(key);
            argumenttypes.push(argtype === 'string_number' ? 'string' : 'boolean');
        });

        const argsMap = argumentnames.reduce((aggr, val, idx) => {
            aggr.set(val, {
                name: val,
                id: argumentids[idx],
                type: argumenttypes[idx],
            });
            return aggr;
        }, new Map<string, ProcedureArg>());

        return new ProcedureRegistryPayload(
            proccode, // will act as id
            blockname,
            block._id,
            argsMap,
        );
    }

    static createRegistry(context: Context) {
        return new RegistryManager<ProcedureRegistryPayload>(context);
    }
}
