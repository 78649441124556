import { BlockValue, num_eval } from '../utils/blockvalue';
import { CONST_AUTO_PORT, indent_code, sanitize } from '../utils/utils';
import { CodeBlock, CodeConverter } from '../utils/types';
import {
    EV3GSTATUSCOLOR_MAP,
    EV3STATUSCOLOR,
    EV3STATUSCOLOR_MAP,
} from '../utils/converters';

import { DeviceOnPort } from '../device/deviceportbase';
import { SB3Block } from '../project.sb3/block';
import { ValueType } from '../utils/enums';

function flipperdisplay_ledSetBrightness(this: CodeConverter, block: CodeBlock) {
    const brightness = block.get('BRIGHTNESS');
    const myvar = this.context.variables.use('pixel_brightness', 100);

    return [myvar.py_setValue(block, BlockValue.raw(brightness))];
}

function flipperdisplay_ledOn(this: CodeConverter, block: CodeBlock) {
    const x = block.get('X').ensureNumber(this.context, true);
    const y = block.get('Y').ensureNumber(this.context, true);
    const brightness = block.get('BRIGHTNESS').ensureNumber(this.context);
    const x1 = num_eval(this.context, [x, '-', 1], true);
    const y1 = num_eval(this.context, [y, '-', 1], true);
    return [`hub.display.pixel(${x1?.raw}, ${y1?.raw}, ${brightness.raw})`];
}

function flipperdisplay_ledText(this: CodeConverter, block: CodeBlock, field: string) {
    const text = block.get(field);
    const expr = text?.ensureString(this.context).raw;
    return [`${this.context.awaitPrefix}hub.display.text(${expr})`];
}

function flipperdisplay_ledImage(this: CodeConverter, block: CodeBlock) {
    const matrix =
        block.get('MATRIX') ??
        new BlockValue('?'.repeat(25), { type: ValueType.STRING }); // '?' means random

    const myvar = this.context.variables.use('pixel_brightness', 100);
    const retval = [
        `hub.display.icon(${this.context.helpers
            .use('convert_icon_matrix')
            .call(matrix, myvar.py)})`,
    ];

    if (block.get('VALUE')) {
        this.context.imports.use('pybricks.tools', 'wait');
        const value = this.context.helpers.use('convert_time').call(block.get('VALUE'));
        retval.push(
            ...[`${this.context.awaitPrefix}wait(${value.raw})`, 'hub.display.off()'],
        );
    }

    return retval;
}

function flipperdisplay_centerButtonLight(this: CodeConverter, block: CodeBlock) {
    const color = this.context.helpers.use('convert_color')?.call(block.get('COLOR'));
    return [`hub.light.on(${color.value})`];
}

function ev3display_setStatusLight(this: CodeConverter, block: CodeBlock) {
    const colorValue = block.get('OPTION')?.toInt();
    if (colorValue === EV3STATUSCOLOR.OFF) {
        return ['hub.light.off()'];
    }
    const color_enum = EV3STATUSCOLOR_MAP.get(colorValue);
    if (color_enum?.endsWith('_PULSE')) {
        return [
            `hub.light.blink(${color_enum.replace(
                '_PULSE',
                '',
            )}, [100, 100, 100, 1000])`,
        ];
    }
    return [`hub.light.on(${color_enum})`];
}

function ev3g_LedOnOff(this: CodeConverter, block: CodeBlock) {
    const colorValue = block.get('COLOR')?.toInt();
    const hasPulse = block.get('PULSE')?.toBool();
    const color_enum = EV3GSTATUSCOLOR_MAP.get(colorValue);
    if (color_enum !== undefined) {
        return !hasPulse
            ? [`hub.light.on(${color_enum})`]
            : [`hub.light.blink(${color_enum}, [100, 100, 100, 1000])`];
    } else {
        return [`hub.light.off()`];
    }
}

function flipperdisplay_displayOff(this: CodeConverter, block: CodeBlock) {
    return ['hub.display.off()'];
}

function flipperdisplay_ultrasonicLightUp(this: CodeConverter, block: CodeBlock) {
    const port = block.get('PORT')?.toString();
    const value = block.get('VALUE');

    const device = DeviceOnPort.instance(this.context, port, 'UltrasonicSensor');
    const d = device?.devicename;
    //TODO: handle variable
    return [`${d}.lights.on([${value.toString().split(' ').join(', ')}])`];
}

function displaymonitor_displayWrite(this: CodeConverter, block: CodeBlock) {
    const text = block.get('TEXT');
    return [`print(${text.raw})`];
}

function flipperlight_lightColorMatrixImageOn(this: CodeConverter, block: CodeBlock) {
    const port = block.get('PORT')?.toString() ?? CONST_AUTO_PORT;
    const matrix =
        block.get('MATRIX') ??
        new BlockValue('?'.repeat(9), { type: ValueType.STRING }); // "72345689a"
    // const value = block.get('VALUE'); // 2 => seconds

    const matrix_values = this.context.helpers.use('convert_color_matrix').call(matrix);

    const device = DeviceOnPort.instance(this.context, port, 'ColorLightMatrix');
    const d = device?.devicename;
    return [`${this.context.awaitPrefix}${d}.on(${matrix_values})`];
}

function flipperlight_lightColorMatrixOff(this: CodeConverter, block: CodeBlock) {
    const port = block.get('PORT')?.toString() ?? CONST_AUTO_PORT;
    const device = DeviceOnPort.instance(this.context, port, 'ColorLightMatrix');
    const d = device?.devicename;
    return [`${this.context.awaitPrefix}${d}.off()`];
}

function flipperdisplay_ledRotateOrientation(this: CodeConverter, block: CodeBlock) {
    const orientation = block.get('ORIENTATION'); // 1-4
    this.context.imports.use('pybricks.parameters', 'Side');
    return [
        `hub.display.orientation(${this.context.helpers
            .use('convert_display_orientation')
            .call(orientation)})`,
    ];
}

function flipperdisplay_ledAnimation(this: CodeConverter, block: CodeBlock) {
    const matrix = JSON.parse(block.get('MATRIX').toString());
    // flipperdisplay_ledAnimation(matrix: "{"animationName":"Play","fps":8,"loop":false,"frames":[{"pixels":[1,0.8888888888888888,0.7777777777777778,0.6666666666666667,0.5555555555555556,0.4444444444444444,0.33333333333333337,0.2222222222222222,0.11111111111111116,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]},{"pixels":[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1,1,1,1,1]}, ...
    const name = 'animation_' + sanitize(matrix.animationName);

    let myvar = this.context.variables.get(name);
    if (!myvar) {
        const fps = parseInt(matrix.fps); //1-10
        // const transition = matrix.transition;
        const frames = matrix.frames.map(
            (elem: { pixels: number[] }) =>
                `${this.context.helpers.use('convert_icon_matrix').call(
                    new BlockValue(
                        elem.pixels.map((pix) => Math.round(pix * 9)).join(''),
                        {
                            type: ValueType.STRING,
                        },
                    ),
                )}`,
        );
        const loop = matrix.loop ? 'True' : 'False';
        const animvalue = `\
{
    "frames": [
${indent_code(frames.join(',\n'), 2).join('\n')}
    ],
    "fps": ${fps},
    "loop": ${loop}
}`;
        myvar = this.context.variables.use(name, animvalue);
    }

    // TODO: if waitForCompletion==false, do a multitask somehow...

    this.context.imports.use('pybricks.tools', 'wait');
    return [
        `${this.context.awaitPrefix}${this.context.helpers
            .use('play_animation')
            .call(myvar.py)}`,
    ];
}

function handleBlock(this: CodeConverter, block: CodeBlock): string[] | undefined {
    switch (block.opcode) {
        case 'flipperdisplay_displayOff':
        case 'flipperlight_lightDisplayOff':
        case 'ev3display_displayClear':
            return flipperdisplay_displayOff.call(this, block);
        case 'flipperdisplay_centerButtonLight':
        case 'flipperlight_centerButtonLight':
            return flipperdisplay_centerButtonLight.call(this, block);
        case 'ev3display_setStatusLight':
            return ev3display_setStatusLight.call(this, block);
        case 'LedOn':
        case 'LedOff':
            return ev3g_LedOnOff.call(this, block);
        case 'flipperdisplay_ledImage': // old version using "x0x0x0x0x0x0x0x0x0x0x0x0x"
        case 'flipperlight_lightDisplayImageOn':
        case 'horizontaldisplay_ledImage': // ignore adding the 0.2 sec delay
        case 'flipperdisplay_ledImageFor':
        case 'flipperdisplay_ledMatrixFor':
        case 'flipperlight_lightDisplayImageOnForTime':
        case 'horizontaldisplay_ledRandom':
            return flipperdisplay_ledImage.call(this, block);
        case 'flipperdisplay_ledText':
        case 'flipperlight_lightDisplayText':
        case 'ev3display_displayText':
        case 'ev3display_displayTextWithFont':
        case 'DisplayString':
        case 'DisplayStringGrid':
            return flipperdisplay_ledText.call(this, block, 'TEXT');
        case 'DisplayFile':
            return flipperdisplay_ledText.call(this, block, 'FILENAME');
        case 'flipperdisplay_ledOn':
        case 'flipperlight_lightDisplaySetPixel':
            return flipperdisplay_ledOn.call(this, block);
        case 'flipperdisplay_ledSetBrightness':
        case 'flipperlight_lightDisplaySetBrightness':
            return flipperdisplay_ledSetBrightness.call(this, block);
        case 'flipperdisplay_ultrasonicLightUp':
        case 'flipperlight_ultrasonicLightUp':
            return flipperdisplay_ultrasonicLightUp.call(this, block);
        case 'displaymonitor_displayWrite':
        case 'displaymonitor_displayWriteForTime':
        case 'horizontaldisplaymonitor_displayWriteWithBuiltInDelay':
            // NOTE: ignore delay for now...
            return displaymonitor_displayWrite.call(this, block);
        case 'flipperlight_lightColorMatrixImageOn':
        case 'flipperlight_lightColorMatrixImageOnForTime':
        case 'horizontaldisplay_ledMatrix':
        case 'horizontaldisplay_ledMatrixRandom':
            // NOTE: ignore delay (value) for now
            return flipperlight_lightColorMatrixImageOn.call(this, block);
        case 'flipperlight_lightColorMatrixOff':
            return flipperlight_lightColorMatrixOff.call(this, block);
        case 'flipperlight_lightDisplaySetOrientation':
        case 'flipperdisplay_ledRotateOrientation':
            return flipperdisplay_ledRotateOrientation.call(this, block);
        case 'flipperdisplay_ledAnimationUntilDone':
        case 'flipperdisplay_ledAnimation':
            // NOTE: ignore waitForCompletion for now
            return flipperdisplay_ledAnimation.call(this, block);
        // TODO: implement flipperdisplay_ledRotateDirection(direction: "clockwise")
    }
}

// function handleOperator(_block: CodeBlock): BlockValue {
//   // Assuming there are no operator handlers based on the original code
//   return null;
// }

const handlers = {
    block: handleBlock,
    operator: undefined,
};
export default handlers;
